import { Button, InputBase } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/styles";
import React from "react";
import { useActions } from "actions";
import { useTranslation } from "react-i18next";
import { routes } from "./../../router/allRoutes";
import { Redirect } from "react-router-dom";

const RegisterGuest = () => {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [loginSuccess, setLoginSuccess] = React.useState(false);
  const { classes } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { GuestLogin } = useActions();

  const handleGuestRegistration = async (e) => {
    e.preventDefault();
    const { error } = await GuestLogin(firstName.trim(), lastName.trim());
    if (error) console.log(error);
    if (!error) setLoginSuccess(true);
  };

  const handleKeyDown = (e) => {
    // e.preventDefault();
    if (e.key === "Enter") {
      console.log("enter in guest registration pressed!");
    }
  };

  return (
    <>
      <div className={classes.inputRegisterFirst}>
        <InputBase
          placeholder="First Name *"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          style={{
            fontSize: "13px",
            color: "#000",
          }}
        />
      </div>
      <div className={classes.inputRegisterLast}>
        <InputBase
          placeholder="Last Name *"
          value={lastName}
          inputProps={{ "aria-label": "search chat" }}
          onChange={(e) => setLastName(e.target.value)}
          style={{
            fontSize: "13px",
            color: "#000",
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className={classes.buttonRegister}>
        <Button
          style={{
            textTransform: "none",
            fontFamily: theme.typography.h1.fontFamily,
            background:
              !firstName || !lastName ? "#aaa" : theme.palette.primary.main,
            color: "#FFF",
            boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
            padding: "4px 27px 4px 27px",
            fontSize: 15,
          }}
          onClick={handleGuestRegistration}
          disabled={!firstName || !lastName ? true : false}
        >
          {t("login.register")}
        </Button>
      </div>
      {loginSuccess && <Redirect to={routes[1].path} />}
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  inputRegisterFirst: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 350,
    height: 30,
    margin: "10px 20px",
    padding: "8px 5px 5px 20px",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  inputRegisterLast: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 350,
    height: 30,
    margin: "5px 20px",
    padding: "8px 5px 5px 20px",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  buttonRegister: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
    marginBottom: 20,
    width: "100%",
    maxWidth: 360,
  },
}));
export default RegisterGuest;
