import React from "react";
import { makeStyles } from "@mui/styles";
import { Paper, IconButton } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import HostIcon from "@mui/icons-material/HowToReg";

const GuestList = ({
  focus,
  party,
  guests,
  onlineUsers,
  session,
  expired,
  removeDialog,
  hostDialog,
  windowHeight,
}) => {
  const classes = useStyles({ windowHeight });
  const { t } = useTranslation();

  const showRemoveDialog = (guestId, name) => {
    removeDialog.current.open(guestId, name);
  };
  const showHostDialog = (guestId, name) => {
    hostDialog.current.open(guestId, name);
  };
  const renderGuest = (item) => {
    let type = item.isManager
      ? t("party.manager")
      : item.isHost
      ? t("party.host")
      : t("party.guest");
    const isOnline =
      Array.isArray(onlineUsers[item.id]) && onlineUsers[item.id].length > 0;
    let fullName;
    if (item.isManager && party.managerName) {
      fullName = party.managerName;
    } else if (!item.firstName && item.lastName) {
      fullName = `${item.lastName}`;
    } else if (!item.firstName && !item.lastName) {
      fullName = item.name;
    } else {
      fullName = `${item.firstName} ${item.lastName}`;
    }
    return (
      <div
        className={clsx(classes.guest, {
          [classes.managerGuest]: item.isManager,
        })}
        key={item.id}
      >
        <span
          className={clsx(classes.onlineStatus, {
            [classes.onlineStatusOnline]: isOnline,
            [classes.onlineStatusOffline]: !isOnline,
          })}
        />

        <span>
          {item.myName || fullName} ({type})
        </span>

        <div className={classes.grow} />
        {!!session &&
          !!session.userId &&
          session.userId === party.userId &&
          !item.isManager && (
            <IconButton
              onClick={() => showRemoveDialog(item.id, item.myName || fullName)}
              className={classes.hostButton}
              disabled={item.isHost || expired}
              size="large"
            >
              <RemoveCircleIcon />
            </IconButton>
          )}
        {!!session &&
          !!session.userId &&
          session.userId === party.userId &&
          !item.isManager && (
            <IconButton
              onClick={() => showHostDialog(item.id, item.myName || fullName)}
              className={classes.hostButton}
              disabled={item.isHost || expired}
              size="large"
            >
              <HostIcon />
            </IconButton>
          )}
      </div>
    );
  };

  return (
    <Paper
      className={clsx(classes.guests, {
        [classes.guestsFocus]: focus === "guests",
      })}
    >
      <div className={classes.cardTitle}>
        {t("party.guests")} ({guests.length + 1})
      </div>
      <div className={classes.cardContent}>
        {renderGuest({
          id: party.userId,
          isManager: true,
          name: party.managerName,
        })}
        {guests.map(renderGuest)}
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    height: 50,
    paddingLeft: 20,
    fontWeight: "bold",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "1px solid #D8D8D8",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  cardContent: {
    flex: 1,
    overflow: "auto",
  },
  guest: {
    height: 36,
    borderWidth: 0,
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 15,
    fontFamily: "Roboto, sans-serif",
  },
  managerGuest: {
    fontWeight: "bold",
    marginTop: 15,
  },
  guests: {
    display: "none",
    paddingTop: 0,
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      borderRadius: 4,
      flex: 1,
      margin: 10,
      marginTop: 130,
      maxHeight: (props) => props.windowHeight / 2 - 85,
    },
  },
  guestsFocus: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      height: (props) => props.windowHeight - 153,
    },
  },
  onlineStatus: {
    "&::before": {
      content: '""',
      display: "block",
      width: 10,
      height: 10,
      borderRadius: 5,
      marginRight: 10,
    },
  },
  onlineStatusOffline: {
    "&::before": {
      backgroundColor: "#DADADA",
    },
  },
  onlineStatusOnline: {
    "&::before": {
      backgroundColor: "#4DB85C",
    },
  },
  grow: {
    flexGrow: 1,
    minWidth: 10,
  },
}));

export default GuestList;
