export const scrollbar = {
  scrollbarWidth: "thin",
  scrollbarColor: "rgba(0,0,0,0.2) rgba(255,255,255,0.1)",
  "&::-webkit-scrollbar": {
    width: "6px !important",
    height: "6px !important",
  },
  "&::-webkit-scrollbar-track": {
    background: "rgba(255,255,255,0.1)",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "rgba(0,0,0,0.2)",
  },
};
