import React from "react";
import {
  Card,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FolderIcon from "@mui/icons-material/Folder";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const FolderCard = ({
  title,
  mediaId,
  isTrash,
  onView,
  onEdit,
  onRemove,
  onDrop,
  onDrag,
  onDragEnd,
  onMoveTo,
  onRestore,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState();
  const handleMenuOpen = (event, message) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };
  const handleMenuClose = () => {
    setAnchorEl();
    setMenuOpen(false);
  };
  const handleEdit = (e) => {
    setMenuOpen(false);
    onEdit(e);
  };
  const handleDelete = (e) => {
    setMenuOpen(false);
    onRemove(e);
  };
  const handleRestore = (e) => {
    setMenuOpen(false);
    onRestore(e);
  };

  const handleMoveTo = () => {
    setMenuOpen(false);
    onMoveTo();
  };

  const [hovered, setHovered] = React.useState(false);
  const handleDrop = (e) => {
    setHovered(false);
    onDrop(e);
  };

  return (
    <>
      <Card
        id={mediaId}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={() => setHovered(true)}
        onDragLeave={() => setHovered(false)}
        draggable={isTrash ? "false" : "true"}
        onDragStart={onDrag}
        onDragEnd={onDragEnd}
        className={clsx(classes.card, { [classes.activeCard]: hovered })}
        onClick={isTrash ? undefined : onView}
      >
        <FolderIcon
          className={clsx(classes.folderIcon, {
            [classes.folderIconActive]: hovered,
          })}
        />
        <Typography
          variant="h5"
          component="span"
          className={clsx(classes.title, { [classes.activeTitle]: hovered })}
        >
          {title}
        </Typography>
        <IconButton
          size="small"
          onClick={(event) => handleMenuOpen(event)}
          style={{ pointerEvents: "auto" }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Card>
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {!isTrash && onEdit && (
          <MenuItem onClick={handleEdit}>{t("media.editLabel")}</MenuItem>
        )}
        {!isTrash && onMoveTo && (
          <MenuItem onClick={handleMoveTo}>{t("media.moveToLabel")}</MenuItem>
        )}
        {!isTrash && onRemove && (
          <MenuItem onClick={handleDelete}>{t("media.deleteLabel")}</MenuItem>
        )}
        {isTrash && (
          <MenuItem onClick={handleRestore}>{t("templates.restore")}</MenuItem>
        )}
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 50,
    width: 250,
    cursor: "pointer",
    marginLeft: 10,
    marginTop: 10,
    padding: 10,
    "& *": {
      pointerEvents: "none",
    },
    maxWidth: "calc(100% - 20px)",
  },
  activeCard: {
    backgroundColor: "#ddd",
  },
  folderIcon: {
    color: "#666",
  },
  folderIconActive: {
    color: "#999",
  },
  title: {
    flex: 1,
    fontSize: 17,
    textJustify: "baseline",
    paddingLeft: 5,
    marginBottom: -3,
    color: "#444",
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  activeTitle: {
    color: "#666",
  },
}));

export default FolderCard;
