import React from "react";
import { useActions } from "actions";
import { useSelector } from "react-redux";
import { PartyCard } from "components";
import moment from "moment";

const PartyList = ({
  active,
  parties: partiesParam,
  profiles = [],
  onEdit,
  onRemove,
}) => {
  const { ChatTimestamp } = useActions();
  const lastSeenTimestamp = useSelector((state) => state.enteredTimestamp);

  const viewParty = (p) => {
    ChatTimestamp(p.id, moment().toISOString());
    window.open(`/${p.shortId}`, "_blank");
  };

  const handleMissedMessages = (item) => {
    if (!item.latestMessage) return false;
    if (item.latestMessage.sender === item.userId) return false;
    if (!item.seen) return true;
    if (item.seen < item.latestMessage.timestamp) return true;
    return false;
  };

  const [parties, setParties] = React.useState([]);
  React.useEffect(() => {
    setParties(
      partiesParam.map((p) => ({
        ...p,
        manager:
          profiles.length > 1 && p.manager
            ? profiles.find((profile) => profile.id === p.manager)
            : null,
      }))
    );
  }, [partiesParam]);

  if (!active) return null;

  return (
    <React.Fragment>
      {parties.map((p, index) => (
        <PartyCard
          title={p.title}
          host={p.host}
          partyDate={p.start}
          expiration={p.expiration}
          manager={p.manager}
          unseenMessage={handleMissedMessages(p)}
          onView={() => viewParty(partiesParam[index])}
          onEdit={(e) => onEdit(e, partiesParam[index])}
          onRemove={(e) => onRemove(e, partiesParam[index])}
        />
      ))}
    </React.Fragment>
  );
};

export default PartyList;
