import {
  GET_USER,
  GET_PROFILES,
  UPDATE_PROFILE,
  ADD_PROFILE,
  LOGOUT,
  DELETE_PROFILE,
} from "../types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER: {
      const user = action.payload;
      return {
        ...state,
        user,
      };
    }

    case GET_PROFILES: {
      const profiles = action.payload;
      return {
        ...state,
        profiles,
      };
    }

    case UPDATE_PROFILE: {
      const profile = action.payload;
      const { profiles = [] } = state;
      const profileIndex = profiles.findIndex((p) => p.id === profile.id);
      if (!profileIndex || profileIndex === -1) {
        return state;
      }
      profiles[profileIndex] = profile;
      return {
        ...state,
        profiles,
      };
    }

    case ADD_PROFILE: {
      const profile = action.payload;
      const { profiles = [] } = state;
      return {
        ...state,
        profiles: [...profiles, profile],
      };
    }

    case DELETE_PROFILE: {
      const { profileId } = action.payload;
      const { profiles = [] } = state;
      const profileIndex = profiles.findIndex((p) => p.id === profileId);
      if (profileIndex === -1) {
        return state;
      }
      return {
        ...state,
        profiles: [
          ...profiles.slice(0, profileIndex),
          ...profiles.slice(profileIndex + 1),
        ],
      };
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
