import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import ShareIcon from "@mui/icons-material/Share";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

const TemplateTable = ({
  templates = [],
  searchValue = "",
  onShare = () => {},
  onMoveTo,
  removeFolder,
  editFolder,
  openFolder,
  openTrash,
  isTrash,
  restoreTemplate,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const headCells = [
    { id: "title", label: t("templates.name"), trash: true, noTrash: true },
    {
      id: "lastApplied",
      label: t("templates.lastUsed"),
      trash: false,
      noTrash: true,
    },
    {
      id: "lastEdited",
      label: t("templates.lastEdited"),
      trash: false,
      noTrash: true,
    },
    {
      id: "created",
      label: t("templates.created"),
      trash: false,
      noTrash: true,
    },
    {
      id: "trashedAt",
      label: t("templates.deletedAt"),
      trash: true,
      noTrash: false,
    },
    {
      id: "permanentlyTrashedAt",
      label: t("templates.willBeDeletedAt"),
      trash: true,
      noTrash: false,
    },
    {
      id: "sharing",
      label: t("templates.share"),
      sortDisabled: true,
      trash: false,
      noTrash: true,
    },
    {
      id: "context",
      label: t(""),
      sortDisabled: true,
      trash: true,
      noTrash: true,
    },
  ];
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("title");
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState();
  const [selected, setSelected] = React.useState();

  const createSortHandler = (headCellId) => () => {
    if (orderBy === headCellId) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(headCellId);
      setOrder("asc");
    }
  };

  const applySearch = (row) => {
    if (isTrash) return true;
    const searchVal = searchValue.trim().toLowerCase();
    if (!searchVal) return true;
    const terms = searchVal.split(" ");
    let result = true;
    for (const term of terms) {
      const searchTerm = new RegExp("^(.*)" + term + "(.*)$");
      result = result && searchTerm.test(row.title.toLowerCase());
    }
    return result;
  };

  const history = useHistory();
  const handleClick = (row) => {
    if (isTrash) return;
    if (row.isFolder) openFolder(row);
    else history.push(`/templates/${row.id}`);
  };

  const handleShare = (e, templateId) => {
    e.preventDefault();
    e.stopPropagation();
    onShare(templateId);
  };

  const handleMenuOpen = (event, row) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setSelected(row);
    setMenuOpen(true);
  };
  const handleMenuClose = () => {
    setAnchorEl();
    setSelected();
    setMenuOpen(false);
  };
  const handleMoveTo = () => {
    setMenuOpen(false);
    onMoveTo(selected.id);
  };
  const handleEdit = () => {
    setMenuOpen(false);
    editFolder(selected);
  };
  const handleRemove = () => {
    setMenuOpen(false);
    removeFolder(selected);
  };
  const handleRestore = () => {
    setMenuOpen(false);
    restoreTemplate(selected);
  };

  const renderRow = (row) => (
    <TableRow key={row.id} style={{ cursor: "pointer" }}>
      <TableCell onClick={() => handleClick(row)}>
        {row.isFolder && (
          <FolderOutlinedIcon
            fontSize="medium"
            style={{ verticalAlign: "bottom" }}
          />
        )}
        {row.isFolder && " "}
        {row.title}
        {row.sharedBy && (
          <span style={{ opacity: 0.6 }}>{` - ${row.sharedBy}`}</span>
        )}
      </TableCell>
      {isTrash ? (
        <>
          <TableCell onClick={() => handleClick(row)}>
            {row.trashedAt
              ? moment(row.trashedAt).format(t("general.dateFormat"))
              : ""}
          </TableCell>
          <TableCell onClick={() => handleClick(row)}>
            {row.trashedAt
              ? moment(row.trashedAt)
                  .add(30, "days")
                  .format(t("general.dateFormat"))
              : ""}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell onClick={() => handleClick(row)}>
            {!row.sharedBy && row.lastApplied
              ? moment(row.lastApplied).format(t("general.dateFormat"))
              : ""}
          </TableCell>
          <TableCell onClick={() => handleClick(row)}>
            {row.lastEdited
              ? moment(row.lastEdited).format(t("general.dateFormat"))
              : ""}
          </TableCell>
          <TableCell onClick={() => handleClick(row)}>
            {row.created
              ? moment(row.created).format(t("general.dateFormat"))
              : ""}
          </TableCell>
          <TableCell>
            {!isTrash && !row.sharedBy && !row.isFolder && (
              <IconButton onClick={(e) => handleShare(e, row)} size="large">
                <ShareIcon fontSize="small" />
              </IconButton>
            )}
            {!isTrash && (!!row.sharedWith || row.sharedWith === 0) && (
              <span
                style={{ opacity: 0.6 }}
              >{`geteilt mit ${row.sharedWith}`}</span>
            )}
          </TableCell>
        </>
      )}
      <TableCell>
        <IconButton
          className={classes.cardButton}
          onClick={(e) => handleMenuOpen(e, row)}
          size="large">
          <MoreHorizIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headCells
              .filter(
                (cell) => (isTrash && cell.trash) || (!isTrash && cell.noTrash)
              )
              .map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sortDisabled ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {openTrash && (
            <TableRow style={{ cursor: "pointer" }}>
              <TableCell onClick={openTrash}>
                <DeleteIcon
                  fontSize="medium"
                  style={{ verticalAlign: "bottom" }}
                />{" "}
                {t("templates.trash")}
              </TableCell>
              <TableCell onClick={openTrash}></TableCell>
              <TableCell onClick={openTrash}></TableCell>
              <TableCell onClick={openTrash}></TableCell>
              <TableCell onClick={openTrash}></TableCell>
              <TableCell onClick={openTrash}></TableCell>
            </TableRow>
          )}
          {_.orderBy(templates, ["isFolder", orderBy], ["asc", order])
            .filter(applySearch)
            .map((row) => renderRow(row))}
        </TableBody>
      </Table>
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {isTrash ? (
          <MenuItem onClick={handleRestore}>{t("templates.restore")}</MenuItem>
        ) : (
          <>
            <MenuItem onClick={handleEdit}>{t("media.editLabel")}</MenuItem>
            <MenuItem onClick={handleMoveTo}>{t("media.moveToLabel")}</MenuItem>
            <MenuItem onClick={handleRemove}>{t("media.deleteLabel")}</MenuItem>
          </>
        )}
      </Menu>
    </TableContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

export default TemplateTable;
