import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Container,
  CssBaseline,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useActions } from "actions";
import { ActionBar, AppBar, Menu, PartyLoading } from "components";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/nl";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";

const Profile = (props) => {
  const { profileId } = useParams();
  const session = useSelector((state) => state.session);
  const settings = useSelector((state) => state.settings);
  const profileState = useSelector((state) => state.profile);
  const [profile, setProfile] = React.useState();
  const [loading, setLoading] = React.useState(true);
  // const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };

  React.useEffect(() => {
    setProfile(
      profileId === "new"
        ? {}
        : profileState?.profiles?.find((p) => p.id === profileId)
    );
  }, [profileState]);

  React.useEffect(() => {
    if (!profile) return;
    setLoading(false);
    setFirstName(profile.firstName || "");
    setLastName(profile.lastName || "");
    setBirthday(profile.birthday ? new Date(profile.birthday) : null);
    setPhoneNo(profile.phoneNo || "");
    setEmail(profile.email || "");
    setWebsite(profile.website || "");
    setBiography(profile.biography || "");
    setShopLink(profile.shopLink || "");
    setProfilePic();
    setSelectedFile();
  }, [profile]);

  const history = useHistory();
  const cancel = () => {
    history.push("/profiles");
  };

  const { UpdateProfile, AddProfile, SwitchCompanyMode } = useActions();

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const shortLang = i18n.language.split("-")[0];
    moment.locale(shortLang === "en" ? "en-gb" : shortLang);
  }, [i18n.language]);

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [birthday, setBirthday] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [biography, setBiography] = React.useState("");
  const [profilePic, setProfilePic] = React.useState();
  const [shopLink, setShopLink] = React.useState();
  const [selectedFile, setSelectedFile] = React.useState();
  const [deleteProfilePic, setDeleteProfilePic] = React.useState(false);
  const [hasChanged, setHasChanged] = React.useState(false);

  React.useEffect(() => {
    if (!profile || loading) {
      setHasChanged(false);
      return;
    }
    setHasChanged(
      firstName != (profile.firstName || "") ||
        lastName != (profile.lastName || "") ||
        (birthday ? birthday.toISOString() : "") != (profile.birthday || "") ||
        phoneNo != (profile.phoneNo || "") ||
        email != (profile.email || "") ||
        website != (profile.website || "") ||
        biography != (profile.biography || "") ||
        shopLink != (profile.shopLink || "") ||
        profilePic ||
        deleteProfilePic
    );
  }, [
    profile,
    firstName,
    lastName,
    birthday,
    phoneNo,
    email,
    website,
    biography,
    profilePic,
    shopLink,
    deleteProfilePic,
  ]);

  React.useEffect(() => {
    document.title = `${firstName} ${lastName} - proWIN Messenger`;
  }, [firstName, lastName]);

  /* selecting files from hard drive */
  React.useEffect(() => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = false;
    input.accept = ["image/*"];
    input.onchange = ({ target }) => {
      const { files } = target;
      setSelectedFile(files[0]);
    };
    input.id = "fileSelector";
    input.style.display = "none";
    document.body.append(input);
    return () => {
      document.body.removeChild(input);
    };
  }, []);
  const selectFiles = () => {
    document.getElementById("fileSelector").click();
  };
  React.useEffect(() => {
    if (selectedFile) {
      setProfilePic(URL.createObjectURL(selectedFile));
      setDeleteProfilePic(false);
    }
  }, [selectedFile]);

  const handleDeleteProfilePic = () => {
    setProfilePic();
    if (profile.profilePic) setDeleteProfilePic(true);
  };

  const handleSave = async () => {
    if (profileId === "new") {
      let { error } = await AddProfile(
        {
          firstName,
          lastName,
          birthday,
          phoneNo,
          email,
          website,
          biography,
          shopLink,
          newProfilePicType: profilePic ? selectedFile.type : undefined,
          deleteProfilePic,
        },
        profilePic ? selectedFile : null
      );
      if (error) {
        return;
      }
    } else {
      let { error } = await UpdateProfile(
        profileId,
        {
          firstName,
          lastName,
          birthday,
          phoneNo,
          email,
          website,
          biography,
          shopLink,
          newProfilePicType: profilePic ? selectedFile.type : undefined,
          deleteProfilePic,
        },
        profilePic ? selectedFile : null
      );
      if (error) {
        return;
      }
    }
    history.push("/profiles");
  };

  const handleCompanySwitch = (e) => {
    SwitchCompanyMode(e.target.checked);
    if (e.target.checked && !hasChanged) history.push("/profiles");
  };

  const clearBirthday = (e) => {
    e.stopPropagation();
    setBirthday(null);
  };

  if (loading) return <PartyLoading />;

  if (!profile) return <Redirect to="/profiles" />;

  return (
    <>
      <Container className={classes.screen}>
        <CssBaseline />
        <AppBar open={menuOpen} onOpen={() => setMenuOpen(true)} />
        <ActionBar
          title={`${firstName} ${lastName}`}
          open={menuOpen}
          actions={[
            {
              id: "reset",
              label: t("general.cancel"),
              action: cancel,
              style: "inherit",
            },
            {
              id: "save",
              label:
                profileId === "new" ? t("general.create") : t("general.save"),
              action: handleSave,
              disabled: !hasChanged,
            },
          ]}
        />
        <Menu
          items={props.menuItems}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          selected="profiles"
        />
        <div className={classes.profileWrapper}>
          {profileId !== "new" && profileState?.profiles?.length === 1 && (
            <FormControlLabel
              control={
                <Switch
                  checked={settings[session.userId].companyMode}
                  onChange={handleCompanySwitch}
                />
              }
              label={t("profiles.multipleProfiles")}
              className={classes.companySwitch}
            />
          )}
          <div className={classes.picWrapper}>
            <Avatar
              onClick={selectFiles}
              src={
                profilePic ||
                (deleteProfilePic || !profile.profilePic
                  ? null
                  : `${process.env.REACT_APP_STATIC_URL}/${session.userId}/${profile.profilePic}`)
              }
              className={classes.avatar}
            />
            <div className={classes.actions}>
              <IconButton onClick={selectFiles} size="large">
                <EditIcon />
              </IconButton>
              <IconButton onClick={handleDeleteProfilePic} size="large">
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
          <div className={classes.fieldGroup}>
            <TextField
              variant="outlined"
              label={t("profiles.firstName")}
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              style={{ marginRight: 10 }}
              className={classes.field}
              required
              fullWidth
            />
            <TextField
              variant="outlined"
              label={t("profiles.lastName")}
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={classes.field}
              required
              fullWidth
            />
          </div>
          <DesktopDatePicker
            label="Date desktop"
            inputFormat={t("general.datePickerFormat")}
            value={birthday}
            onChange={setBirthday}
            renderInput={(params) => (
              <>
                <TextField className={classes.field} {...params} />
                <IconButton onClick={clearBirthday} size="small">
                  <ClearIcon />
                </IconButton>
              </>
            )}
          />

          <TextField
            variant="outlined"
            label={t("profiles.phoneNo")}
            type="tel"
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
            className={classes.field}
            fullWidth
          />
          <TextField
            variant="outlined"
            label={t("profiles.email")}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={classes.field}
            fullWidth
          />
          <TextField
            variant="outlined"
            label={t("profiles.website")}
            placeholder={t("profiles.websitePlaceholder")}
            type="url"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            className={classes.field}
            fullWidth
          />
          <TextField
            variant="outlined"
            label={t("profiles.shopLink")}
            placeholder={t("profiles.shopLinkPlaceholder")}
            type="url"
            value={shopLink}
            onChange={(e) => setShopLink(e.target.value)}
            className={classes.field}
            fullWidth
          />
          <TextField
            variant="outlined"
            label={
              biography.length > 0
                ? `${t("profiles.biography")} (${biography.length}/300)`
                : t("profiles.biography")
            }
            placeholder={t("profiles.biographyPlaceholder")}
            type="text"
            value={biography}
            onChange={(e) => setBiography(e.target.value)}
            multiline
            rows={3}
            inputProps={{
              maxLength: 300,
            }}
            className={classes.field}
            fullWidth
          />
        </div>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    maxWidth: "100%",
  },
  profileWrapper: {
    marginTop: 130,
    [theme.breakpoints.down("sm")]: {
      marginTop: 120,
    },
    maxWidth: 400,
  },
  companySwitch: {
    marginBottom: 20,
  },
  picWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  profilesList: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  profileThumbnail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  selected: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: "#00BDF2",
  },
  title: {
    marginBottom: 0,
  },
  fieldGroup: {
    display: "flex",
    flexDirection: "row",
  },
  field: {
    marginTop: 10,
  },
}));

export default Profile;
