import arrayMove from "array-move";
import {
  GET_TEMPLATE,
  ADD_TEMPLATE_MESSAGE,
  EDIT_TEMPLATE_MESSAGE,
  DELETE_TEMPLATE_MESSAGE,
  DELETE_TEMPLATE_MESSAGES,
  MOVE_TEMPLATE_MESSAGE,
  LOGOUT,
} from "../types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATE: {
      const template = action.payload;
      return {
        ...state,
        [template.id]: template.messages,
      };
    }

    case ADD_TEMPLATE_MESSAGE: {
      const { templateId, message, beforeMessageId } = action.payload;
      if (!beforeMessageId || !state[templateId])
        return {
          ...state,
          [templateId]: [...(state[templateId] || []), message],
        };
      const beforeMessageIndex = state[templateId].findIndex(
        (m) => m.id === beforeMessageId
      );
      const newState = {
        ...state,
        [templateId]: [
          ...state[templateId].slice(0, beforeMessageIndex),
          message,
          ...state[templateId].slice(beforeMessageIndex),
        ],
      };
      return newState;
    }

    case EDIT_TEMPLATE_MESSAGE: {
      const { templateId, message } = action.payload;
      const messages = state[templateId] || [];
      const index = messages.findIndex((m) => m.id === message.id);
      if (index === -1) return state;
      messages[index] = message;
      return {
        ...state,
        [templateId]: messages,
      };
    }

    case DELETE_TEMPLATE_MESSAGE: {
      const { templateId, messageId } = action.payload;
      return {
        ...state,
        [templateId]:
          state[templateId]?.filter((m) => m.id !== messageId) || [],
      };
    }

    case DELETE_TEMPLATE_MESSAGES: {
      const { templateId, messageIds } = action.payload;
      for (const msg of messageIds) {
        state[templateId] =
          state[templateId]?.filter((m) => m.id !== msg) || [];
      }
      return state;
    }

    case MOVE_TEMPLATE_MESSAGE: {
      const { templateId, messageId, newNextId } = action.payload;
      const messages = state[templateId] || [];
      const oldIndex = messages.findIndex((m) => m.id === messageId);
      const afterIndex =
        newNextId === "last"
          ? messages.length - 1
          : messages.findIndex((m) => m.id === newNextId) - 1;
      if (oldIndex === -1) return state;
      return {
        ...state,
        [templateId]: arrayMove(
          messages,
          oldIndex,
          oldIndex > afterIndex ? afterIndex + 1 : afterIndex
        ),
      };
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
