import React from "react";
import { Button, InputBase, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useActions } from "actions";
import { makeStyles } from "tss-react/mui";

const ClaimAccount = (props) => {
  const { emailSubmitted } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [email, setEmail] = React.useState("");
  const { t, i18n } = useTranslation();
  const { ClaimAccount } = useActions();
  const lang = i18n.language.substring(0, 2);

  const handleAccountClaim = async (e) => {
    e.preventDefault();
    const { error } = await ClaimAccount(email, lang);
    if (error) console.log(error);
    if (!error) {
      emailSubmitted();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("enter in Claim Account");
    }
  };
  return (
    <div className={classes.wrapper}>
      {!matches && <div className={classes.img}> </div>}
      <div className={classes.loginContent}>
        <p className={classes.title}>{t("login.claimTitle")}</p>
        <div className={classes.inputSendCode}>
          <InputBase
            placeholder="E-mail *"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            style={{
              width: 350,
              fontSize: "13px",
              color: "#000",
              marginTop: 3,
              marginLeft: 5,
            }}
          />
        </div>
        <div className={classes.buttonSendCode}>
          <div className={classes.undelineButtons}>
            <Link to="/parties" style={{ color: theme.palette.primary.main }}>
              <Button
                disableRipple
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 13,
                  width: 10,
                  height: 10,
                  textTransform: "none",
                  color: theme.palette.primary.main,
                }}
                // onClick={}
              >
                {t("login.back")}
              </Button>
            </Link>
          </div>
          <Button
            style={{
              textTransform: "none",
              background: theme.palette.primary.main,
              color: "#FFF",
              boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
              padding: "3px 20px 0 20px",
              fontSize: 17,
              fontFamily: "Roboto",
            }}
            onClick={handleAccountClaim}
          >
            {t("login.submit")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "flex",
    height: "100vh",
    backgroundColor: theme.palette.lightBlue.main,
    flex: 1,
    overflow: "hidden",
  },
  loginContent: {
    flex: 1,
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  img: {
    height: "100%",
    flex: 1,
    backgroundImage: "url(prowinImg.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  inputSendCode: {
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    height: 30,
    margin: "10px 20px",
    padding: 5,
  },
  buttonSendCode: {
    marginTop: 10,
    width: "100%",
    maxWidth: 350,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textButtonSkip: {
    fontSize: 10,
    display: "flex",
  },
  undelineButtons: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: theme.palette.darkGrey.main,
    margin: 0,
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: 350,
  },
}));

export default ClaimAccount;
