import React from "react";
import {
  Container,
  CssBaseline,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  AppBar,
  ActionBar,
  Menu,
  PartyLoading,
  DeleteDialog,
} from "components";

import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import ProfileTable from "./table";
import { useHistory, Redirect } from "react-router-dom";

const Profiles = (props) => {
  const session = useSelector((state) => state.session);
  const settings = useSelector((state) => state.settings);
  const profileState = useSelector((state) => state.profile);
  const [profiles, setProfiles] = React.useState();

  React.useEffect(() => {
    setProfiles(profileState.profiles || []);
  }, [profileState]);
  const {
    GetProfiles,
    DeleteProfile,
    SelectProfile,
    SwitchCompanyMode,
    GetGuest,
  } = useActions();

  const { t } = useTranslation();

  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);

  // const init = async () => {
  //   if (!session.secretKey) {
  //     await GetProfiles();
  //   } else {
  //     const { response } = await GetGuest(session.id);
  //   }
  // };

  const init = async () => {
    await GetProfiles();
  };

  React.useEffect(() => {
    init();
    document.title = `${t("profiles.title")} - proWIN Messenger`;
  }, []);

  const history = useHistory();
  const handleAdd = () => {
    history.push("/profiles/new");
  };
  const handleEdit = (profileId) => {
    history.push(`/profiles/${profileId}`);
  };
  const handleSelect = (profileId) => {
    SelectProfile(profileId);
  };

  const handleCompanySwitch = (e) => {
    SwitchCompanyMode(e.target.checked);
  };

  const [search, setSearch] = React.useState("");

  const [deleteProfileId, setDeleteProfileId] = React.useState();
  const deleteDialog = React.useRef();
  const handleDelete = (profileId) => {
    setDeleteProfileId(profileId);
    deleteDialog.current.open();
  };
  const submitDeleteDialog = async () => {
    await DeleteProfile(deleteProfileId);
  };

  if (!profiles) return <PartyLoading />;

  if (profiles.length === 1 && !settings[session.userId].companyMode)
    return <Redirect to={`/profiles/${profiles[0].id}`} />;

  return (
    <Container className={classes.screen}>
      <CssBaseline />
      <AppBar
        open={menuOpen}
        onOpen={() => setMenuOpen(true)}
        searchChange={setSearch}
      />
      <ActionBar
        title={t("profiles.title")}
        open={menuOpen}
        actions={[
          {
            id: "newProfile",
            label: t("profiles.newProfile"),
            icon: <AddIcon />,
            action: handleAdd,
          },
        ]}
      />
      <Menu
        items={props.menuItems}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        selected="profiles"
      />
      <div className={classes.wrapper}>
        {profileState?.profiles?.length === 1 && (
          <FormControlLabel
            control={
              <Switch
                checked={settings[session.userId].companyMode}
                onChange={handleCompanySwitch}
              />
            }
            label={t("profiles.multipleProfiles")}
            className={classes.companySwitch}
          />
        )}
        <ProfileTable
          profiles={profiles}
          selectedProfile={settings?.[session.userId]?.selectedProfile}
          searchValue={search}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onSelect={handleSelect}
        />
      </div>

      <DeleteDialog
        ref={deleteDialog}
        type="profile"
        onSubmit={submitDeleteDialog}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    maxWidth: "100%",
    marginTop: 130,
    [theme.breakpoints.down("sm")]: {
      marginTop: 120,
    },
  },
  wrapper: {
    maxWidth: `calc(100% - ${theme.spacing(7)})`,
  },
  companySwitch: {
    marginBottom: 20,
  },
}));

export default Profiles;
