import React from "react";
import { Backdrop, Modal, Fade, Fab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-hls-quality-selector";
import contribQualityLevels from "videojs-contrib-quality-levels";
videojs.registerPlugin("qualityLevels", contribQualityLevels);

const Lightbox = ({ open, userId, media, onClose }) => {
  const [size, setSize] = React.useState({
    width: window.innerWidth - 50,
    height: window.innerHeight - 50,
  });
  React.useEffect(() => {
    const handleResize = () =>
      setSize({
        width: window.innerWidth - 50,
        height: window.innerHeight - 50,
      });
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const photo = React.useRef();
  const [aspectRatio, setAspectRatio] = React.useState(16 / 9);

  const videoWidth = Math.max(
    aspectRatio > size.width / size.height
      ? size.width
      : size.height * aspectRatio,
    285
  );
  const videoHeight =
    aspectRatio > size.width / size.height
      ? size.width / aspectRatio
      : size.height;
  const classes = useStyles({ videoWidth, videoHeight });

  const [ready, setReady] = React.useState(false);
  React.useEffect(() => {
    if (!open) setReady(false);
  }, [open]);
  const [player, setPlayer] = React.useState();
  const videoPlayer = React.useRef();
  React.useEffect(() => {
    if (!open) return;
    if (media.mediaType === "image") {
      setTimeout(() => {
        setAspectRatio(
          photo.current.naturalWidth / photo.current.naturalHeight
        );
        setReady(true);
      }, 200);
    }
    if (media.mediaType !== "video") return;
    setTimeout(() => {
      if (!videoPlayer.current) return;
      let p = videojs(videoPlayer.current, {
        autoplay: true,
        controls: true,
        fluid: true,
        sources: [
          {
            src: `${process.env.REACT_APP_STATIC_URL}/${
              media.owner || userId
            }/${media.id}.m3u8`,
            withCredentials: false,
          },
        ],
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          hls: {
            overrideNative: true,
            withCredentials: false,
          },
        },
      });
      p.hlsQualitySelector({
        displayCurrentQuality: false,
      });
      setPlayer(p);
    }, 100);
  }, [open]);

  React.useEffect(() => {
    if (player)
      setTimeout(() => {
        setAspectRatio(player.videoWidth() / player.videoHeight());
        setReady(true);
      }, 200);
  }, [player]);

  const handleClose = () => {
    if (player)
      setTimeout(() => {
        player.dispose();
        setPlayer();
      }, 500);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: classes.backdrop,
      }}
      className={classes.modal}
    >
      <Fade in={ready}>
        <div className={classes.contentWrapper}>
          {media && media.mediaType === "video" && (
            <div
              data-vjs-player
              style={{
                paddingTop: videoHeight,
                backgroundColor: "transparent",
              }}
            >
              <video ref={videoPlayer} className="video-js"></video>
            </div>
          )}
          {media && media.mediaType === "image" && (
            <img
              ref={photo}
              src={`${process.env.REACT_APP_STATIC_URL}/${
                media.owner || userId
              }/${media.id}`}
              className={classes.photo}
            />
          )}
          <Fab
            onClick={handleClose}
            className={classes.closeButton}
            size="small"
          >
            <CloseIcon />
          </Fab>
        </div>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    position: "relative",
    width: 36,
    height: 36,
    left: (props) => props.videoWidth - 18,
    top: (props) => -props.videoHeight - 17,
  },
  contentWrapper: {
    outline: "none",
    width: (props) => props.videoWidth,
    height: (props) => props.videoHeight,
    zIndex: 1400,
  },
  photo: {
    width: (props) => props.videoWidth,
    height: (props) => props.videoHeight,
  },
}));

export default Lightbox;
