import { MEDIA_TRASH, RESTORE_MEDIA, LOGOUT } from "../types";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case MEDIA_TRASH: {
      return action.payload;
    }

    case RESTORE_MEDIA: {
      const { mediaId } = action.payload;
      return state.filter((t) => t.id !== mediaId);
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
