import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const PartyExpired = (props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.screen}>
      <Typography variant="h5" color="primary">
        {t("party.partyExpiredTitle")}
      </Typography>
      <div style={{ height: 20 }} />
      <Typography variant="body1">{t("party.partyExpired")}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default PartyExpired;
