import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const TemplateDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = React.useState("create");
  const [templateName, setTemplateName] = React.useState("");

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: (name) => {
      setOpen(true);
      setMode(name ? "edit" : "create");
      setTemplateName(name || "");
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit(templateName);
  };

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {mode === "create"
            ? t("templates.createTemplateDialogTitle")
            : t("templates.templateDialogTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {mode === "create"
              ? t("templates.createTemplateDialog")
              : t("templates.templateDialog")}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={t("templates.templateName")}
            type="text"
            fullWidth
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            className={classes.field}
            required
          />
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("general.cancel")}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {mode === "create" ? t("general.create") : t("general.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
  spacer: {
    height: theme.spacing(2),
  },
}));

export default React.forwardRef(TemplateDialog);
