import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DropzoneArea } from "react-mui-dropzone";
import { useTranslation } from "react-i18next";

const UploadDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
      setFiles();
    },
  }));

  const handleUpload = (files) => {
    setFiles(files);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit(files);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("media.uploadTitle")}</DialogTitle>
        <DialogContent>
          <DropzoneArea
            dropzoneText={`\xa0\xa0\xa0${t("media.dropzoneText")}\xa0\xa0\xa0`}
            filesLimit={20}
            maxFileSize={50000000000}
            showPreviewsInDropzone={true}
            onChange={handleUpload}
            className={classes.dropzone}
            acceptedFiles={["image/*", "video/*", "application/pdf", "audio/*"]}
          />
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("general.cancel")}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {t("general.upload")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
}));

export default React.forwardRef(UploadDialog);
