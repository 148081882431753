import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Ellipsis from "react-lines-ellipsis";
import { useTranslation } from "react-i18next";

const MoveToChatDialog = (props, ref) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { onSubmit, pathHomeLabel = t("media.homeTitle") } = props;
  const [open, setOpen] = React.useState(false);
  const [parties, setParties] = React.useState([]);
  const [partyId, setPartyId] = React.useState();
  const [path, setPath] = React.useState([]);
  const [selectedFolder, setSelectedFolder] = React.useState("parties");
  const [visiblePath, setVisiblePath] = React.useState([]);

  const sortABC = (a, b) => {
    if (!a.title || !b.title) return 0;
    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
    return 0;
  };

  const getPartyInPath = () => {
    let activeParties = [];
    for (const party of parties) {
      if (party.expiration === null) {
        activeParties.push(party);
      }
    }
    if (!path || path.length === 0)
      setVisiblePath(activeParties.filter((m) => !m.path));
    else setVisiblePath(activeParties.filter((m) => m.path === path.join("/")));
  };

  React.useEffect(() => {
    getPartyInPath();
  }, [path]);

  const handleSetPath = (folderId) => {
    setSelectedFolder(folderId);
    if (folderId === "parties") {
      setPath([]);
      return;
    }
    const folder = parties.find((m) => m.id === folderId);
    setPath([...(folder.path ? folder.path.split("/") : []), folder.shortId]);
  };

  React.useImperativeHandle(ref, () => ({
    open: (parties, partyId) => {
      setOpen(true);
      let tidyPartyList = [];
      const result = Object.keys(parties).map((key) => [parties[key]]);
      for (const singleParty of result) {
        tidyPartyList.push(singleParty[0]);
      }
      setParties(tidyPartyList.filter((m) => m.id !== partyId));
      setPartyId(partyId);
      setSelectedFolder("parties");
      setPath([]);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit(partyId, path, selectedFolder);
  };

  const renderFolder = (folder) => {
    return (
      <div
        className={classes.folder}
        key={folder.id}
        onClick={() => handleSetPath(folder.id)}
      >
        <div className={classes.folderName}>
          <Ellipsis lines={1} text={folder.title} basedOn="letters" />
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("media.moveToDialogTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("media.moveToDialog")}</DialogContentText>
          <div className={classes.breadcrumbs}>
            <Button
              size="small"
              classes={{
                root: classes.pathButton,
                label: classes.pathButtonLabel,
              }}
              onClick={() => handleSetPath("parties")}
            >
              {pathHomeLabel}
            </Button>
            {path.length > 0 && (
              <ChevronRight
                fontSize="small"
                className={classes.pathSeparator}
              />
            )}
            {path.length > 2 && (
              <>
                <Button
                  size="small"
                  classes={{
                    root: classes.pathButton,
                    label: classes.pathButtonLabel,
                  }}
                  disabled
                >
                  ...
                </Button>
                <ChevronRight
                  fontSize="small"
                  className={classes.pathSeparator}
                />
              </>
            )}
            {path.slice(-2).map((p, index) => (
              <React.Fragment key={p}>
                <Button
                  size="small"
                  classes={{
                    root: classes.pathButton,
                    label: classes.pathButtonLabel,
                  }}
                  onClick={() => handleSetPath(p)}
                >
                  {parties.find((m) => m.id === p)?.title}
                </Button>
                {index < path.slice(-2).length - 1 && (
                  <ChevronRight
                    fontSize="small"
                    className={classes.pathSeparator}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
          <div className={classes.mediaArea}>
            {visiblePath.sort(sortABC).map(renderFolder)}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("general.cancel")}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {t("media.moveToDialogSubmit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: theme.spacing(1),
    maxHeight: `calc(100% - ${theme.spacing(2)}px)`,
  },
  breadcrumbs: {
    paddingBottom: 10,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderColor: "rgba(160, 160, 160, 0.6)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  pathButton: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "0.9rem",
    color: "#333",
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
    minWidth: 30,
    "& span": {
      pointerEvents: "none",
    },
    justifyContent: "flex-start",
  },
  pathButtonLabel: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  pathSeparator: {
    color: "#999",
    marginLeft: -5,
    marginRight: -5,
  },
  mediaArea: {
    maxHeight: "calc(100vh - 205px)",
    overflowY: "auto",
    width: 400,
    maxWidth: (props) => props.windowWidth - theme.spacing(4),
    marginLeft: -theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
    },
  },
  folder: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    width: 400,
    maxWidth: (props) => props.windowWidth - theme.spacing(4),
    backgroundColor: "#f8f8f8",
    borderRadius: 5,
    marginBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  folderIcon: {
    color: "#666",
  },
  folderName: {
    flex: 1,
    fontSize: 17,
    textJustify: "baseline",
    paddingLeft: 5,
    marginBottom: -3,
    color: "#444",
  },
}));

export default React.forwardRef(MoveToChatDialog);
