import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const HeadingDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const [messageType, setMessageType] = React.useState("heading");
  const [heading, setHeading] = React.useState("");

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
      setHeading("");
      setMessageType("heading");
    },
  }));

  const handleClose = () => {
    setOpen(false);
    setHeading("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    setHeading("");
    setMessageType("heading");
    onSubmit(heading, messageType);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("party.sendHeadingTitle")}:</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("party.sendHeading")}</DialogContentText>
          <FormControl>
            <Select
              value={messageType}
              onChange={(e) => setMessageType(e.target.value)}
            >
              <MenuItem value="heading">{t("party.heading")} 1</MenuItem>
              <MenuItem value="heading2">{t("party.heading")} 2</MenuItem>
              <MenuItem value="heading3">{t("party.heading")} 3</MenuItem>
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            label={t("party.heading")}
            type="text"
            fullWidth
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
            className={classes.field}
            required
          />
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("general.cancel")}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {t("general.send")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
  spacer: {
    height: theme.spacing(2),
  },
}));

export default React.forwardRef(HeadingDialog);
