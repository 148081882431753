import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const FolderDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const [folderName, setFolderName] = React.useState("");

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setFolderName("");
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit(folderName);
  };

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("media.folderDialogTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("media.folderDialog")}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={t("media.fileName")}
            type="text"
            fullWidth
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            className={classes.field}
            required
          />
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("general.cancel")}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {t("general.confirm")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
  spacer: {
    height: theme.spacing(2),
  },
}));

export default React.forwardRef(FolderDialog);
