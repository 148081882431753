import {
  Add,
  ArrowBack,
  ArrowDownward,
  ArrowUpward,
  Edit,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  ActionBar,
  AppBar,
  BannerDialog,
  CreateBannerGroupDialog,
  DiscardBannerChangesDialog,
  Menu,
} from "components";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import DeleteDialog from "src/components/deleteDialog";
import { useActions } from "src/redux/actions";
import { scrollbar } from "src/utils/scrollbars";

const Banners = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [menuOpen, setMenuOpen] = useState(false);

  const { CreateBannerGroup, UpdateBannerGroup } = useActions();

  const { groups } = useSelector((state) => state.banners);
  const customLanguageOrder = {
    de: 1,
    nl: 2,
    fr: 3,
    "fr-be": 4,
    "fr-lu": 5,
    it: 6,
    ch: 7,
  };

  const sortedGroups = groups.sort((a, b) => {
    const languageOrderA = customLanguageOrder[a.language];
    const languageOrderB = customLanguageOrder[b.language];
    return languageOrderA - languageOrderB;
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [selectedGroup, setSelectedGroup] = useState();
  const [banners, setBanners] = useState();
  const discardDialog = useRef();
  const handleSelectGroup = (group, skipDiscard) => {
    if (hasChanges && !skipDiscard) {
      discardDialog.current.open(group || null);
      return;
    }
    if (!group) {
      setSelectedGroup();
      setBanners();
      setHasChanges(false);
      return;
    }
    setSelectedGroup(`${group.language};${group.type}`);
    setBanners(group.banners);
    setHasChanges(false);
  };
  const createDialog = useRef();
  const handleAddLanguage = () => {
    createDialog.current.open();
  };
  const handleSubmitAddLanguage = (lang) => {
    CreateBannerGroup(lang, "banner");
    CreateBannerGroup(lang, "magazine");
  };

  const [bannerIndex, setBannerIndex] = useState(-1);
  const [banner, setBanner] = useState();
  const [hasChanges, setHasChanges] = useState(false);
  const handleMoveUp = (index) => {
    setBanners((banners) => {
      if (index === 0) return banners;
      return [
        ...banners.slice(0, index - 1),
        banners[index],
        banners[index - 1],
        ...banners.slice(index + 1),
      ];
    });
    setHasChanges(true);
  };
  const handleMoveDown = (index) => {
    setBanners((banners) => {
      if (index === banners.length - 1) return banners;
      return [
        ...banners.slice(0, index),
        banners[index + 1],
        banners[index],
        ...banners.slice(index + 2),
      ];
    });
    setHasChanges(true);
  };
  const handleAdd = () => {
    setBannerIndex(-1);
    setBanner({
      title: "",
    });
  };
  const handleEdit = (banner) => {
    setBannerIndex(banners.indexOf(banner));
    setBanner(banner);
  };
  const deleteDialog = useRef();
  const handleDelete = () => {
    setBanner();
    deleteDialog.current.open();
  };
  const handleConfirmDelete = () => {
    setBanners((banners) => {
      return [
        ...banners.slice(0, bannerIndex),
        ...banners.slice(bannerIndex + 1),
      ];
    });
    handleDialogClose();
    setHasChanges(true);
  };
  const handleDialogClose = () => {
    setBanner();
    setBannerIndex(-1);
  };
  const handleSubmitEdit = (bannerIndex, banner) => {
    if (bannerIndex === -1) {
      setBanners((banners) => [...banners, banner]);
      handleDialogClose();
    } else {
      const newBanners = [...banners];
      newBanners[bannerIndex] = banner;
      setBanners(newBanners);
      handleDialogClose();
    }
    setHasChanges(true);
  };

  const [saving, setSaving] = useState(false);
  const handleSave = async () => {
    setSaving(true);
    const [lang, type] = selectedGroup.split(";");
    const { error } = await UpdateBannerGroup(lang, type, banners);
    if (!error) setHasChanges(false);
    setSaving(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Container className={classes.screen}>
        <CssBaseline />
        <AppBar
          open={menuOpen}
          onOpen={() => setMenuOpen(true)}
          title="App-Banner"
        />
        <ActionBar title="App-Banner" open={menuOpen} />
        <Menu
          items={props.menuItems}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          selected="banners"
        />
        {(!selectedGroup || !isMobile) && (
          <Paper
            className={classes.list}
            style={{ maxWidth: !isMobile ? 400 : undefined }}
          >
            <List
              style={{
                flex: 1,
                overflowY: "auto",
                ...scrollbar,
              }}
            >
              {sortedGroups.map((group) => (
                <ListItemButton
                  selected={selectedGroup === `${group.language};${group.type}`}
                  onClick={() => handleSelectGroup(group)}
                >
                  <ListItem>
                    <ListItemText
                      primary={
                        <span>
                          <strong>Sprache/Region: </strong>
                          {group.language}
                        </span>
                      }
                      secondary={
                        <span>
                          <strong>Banner-Art: </strong>
                          {group.type === "magazine"
                            ? "Magazin"
                            : "Werbe-Banner"}
                        </span>
                      }
                    />
                  </ListItem>
                </ListItemButton>
              ))}
              <ListItemButton
                onClick={handleAddLanguage}
                style={{ display: "none" }}
              >
                <ListItem>
                  <ListItemIcon>
                    <Add />
                  </ListItemIcon>
                  <ListItemText primary="Sprache/Region hinzufügen" />
                </ListItem>
              </ListItemButton>
            </List>
          </Paper>
        )}
        {!!selectedGroup && (
          <Paper className={classes.detail}>
            {isMobile && (
              <Box sx={{ display: "flex", padding: "10px" }}>
                <Button
                  onClick={() => handleSelectGroup()}
                  startIcon={<ArrowBack />}
                  color="inherit"
                >
                  Zurück zur Liste
                </Button>
              </Box>
            )}
            <List
              style={{
                flex: 1,
                overflowY: "auto",
                ...scrollbar,
              }}
            >
              {banners.map((banner, index) => (
                <ListItemButton onClick={() => handleEdit(banner)}>
                  <ListItemAvatar>
                    <Avatar
                      src={banner.image}
                      variant="rounded"
                      sx={{ width: 159, height: 95.5 }}
                    />
                  </ListItemAvatar>
                  <ListItem>
                    <ListItemText
                      primary={banner.title}
                      secondary={
                        <span>
                          <strong>Start: </strong>
                          {!banner.startTime
                            ? "–"
                            : moment(banner.startTime).format(
                                "DD.MM.YYYY HH:mm"
                              )}
                          <br />
                          <strong>Ende: </strong>
                          {!banner.endTime
                            ? "–"
                            : moment(banner.endTime).format("DD.MM.YYYY HH:mm")}
                        </span>
                      }
                    />
                  </ListItem>
                  <ListItemIcon>
                    <IconButton>
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMoveUp(index);
                      }}
                    >
                      <ArrowUpward />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMoveDown(index);
                      }}
                    >
                      <ArrowDownward />
                    </IconButton>
                  </ListItemIcon>
                </ListItemButton>
              ))}
              <ListItemButton onClick={handleAdd}>
                <ListItem>
                  <ListItemIcon>
                    <Add />
                  </ListItemIcon>
                  <ListItemText primary="Neuen Banner hinzufügen" />
                </ListItem>
              </ListItemButton>
            </List>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={!hasChanges || saving}
                onClick={handleSave}
              >
                {saving
                  ? "Änderungen werden gespeichert..."
                  : hasChanges
                  ? "Änderungen speichern"
                  : "Alle Änderungen gespeichert"}
              </Button>
            </Box>
          </Paper>
        )}
        <BannerDialog
          bannerIndex={bannerIndex}
          banner={banner}
          onSubmit={handleSubmitEdit}
          onClose={handleDialogClose}
          onDelete={handleDelete}
        />
        <DeleteDialog
          ref={deleteDialog}
          type="banner"
          onSubmit={handleConfirmDelete}
        />
        <DiscardBannerChangesDialog
          ref={discardDialog}
          onSubmit={handleSelectGroup}
        />
        <CreateBannerGroupDialog
          ref={createDialog}
          onSubmit={handleSubmitAddLanguage}
        />
      </Container>
    </LocalizationProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    position: "absolute",
    top: 0,
    bottom: 0,
    display: "flex",
    maxWidth: "100%",
    backgroundColor: "#f3f9fc",
    paddingTop: 130,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 110,
      paddingRight: 20,
    },
  },
  list: {
    flex: 1,
    marginRight: 20,
    borderRadius: 12,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  detail: {
    flex: 1,
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
  },
}));

export default Banners;
