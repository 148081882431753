import React from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { useActions } from "actions";
import { useTranslation } from "react-i18next";
import {
  Container,
  Paper,
  Typography,
  Grid,
  CssBaseline,
  Button,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import short from "short-uuid";
import applede from "assets/appstore_de.svg";
import appleen from "assets/appstore_en.svg";
import applenl from "assets/appstore_nl.svg";
import applefr from "assets/appstore_fr.svg";
import googlede from "assets/google_de.png";
import googleen from "assets/google_en.png";
import googlenl from "assets/google_nl.png";
import googlefr from "assets/google_fr.png";
import huaweien from "assets/huawei_en.png";
import huaweifr from "assets/huawei_fr.png";
import logo from "assets/logo-prowin-messenger.svg";

const AddVIP = () => {
  const translator = short();
  const classes = useStyles();
  const { id } = useParams();
  const profileId = translator.toUUID(id);
  const { GetDirectProfile } = useActions();
  const { t, i18n } = useTranslation();
  const lang = i18n.language.substring(0, 2).toLowerCase();
  const langButton = React.useRef();
  const [langOpen, setLangOpen] = React.useState(false);
  const [profile, setProfile] = React.useState();

  const appStore = {
    de: applede,
    en: appleen,
    fr: applefr,
    nl: applenl,
  };
  const playStore = {
    de: googlede,
    en: googleen,
    fr: googlefr,
    nl: googlenl,
  };
  const appGallery = {
    de: huaweien,
    en: huaweien,
    fr: huaweifr,
    nl: huaweien,
  };

  const selectLang = async (lang) => {
    await i18n.changeLanguage(lang);
    setLangOpen(false);
  };

  const loadProfile = async () => {
    let { response } = await GetDirectProfile(profileId);
    setProfile(response.data);
  };

  React.useEffect(() => {
    loadProfile();
  }, []);

  return (
    <div>
      <Container component="main" maxWidth="md">
        <CssBaseline />

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.header}
        >
          <Grid item xs={3}>
            <Link to="/">
              <img src={logo} alt="Prowin Messenger" />
            </Link>
          </Grid>
          <Grid item xs={3}>
            <Button
              color="inherit"
              onClick={() => setLangOpen(!langOpen)}
              ref={langButton}
              className={classes.langButton}
            >
              {lang === "en"
                ? "English 🇬🇧"
                : lang === "nl"
                ? "Nederlands 🇳🇱"
                : lang === "fr"
                ? "Français 🇫🇷"
                : "Deutsch 🇩🇪"}
            </Button>
            <Menu
              anchorEl={langButton.current}
              keepMounted={true}
              open={langOpen}
              onClose={() => setLangOpen(false)}
            >
              <MenuItem onClick={() => selectLang("de")}>Deutsch 🇩🇪</MenuItem>
              <MenuItem onClick={() => selectLang("en")}>English 🇬🇧</MenuItem>
              <MenuItem onClick={() => selectLang("nl")}>
                Nederlands 🇳🇱
              </MenuItem>
              <MenuItem onClick={() => selectLang("fr")}>Français 🇫🇷</MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <Container maxWidth="sm">
          <Typography align="center" variant="h4" className={classes.title}>
            {t("addVIP.title")}
          </Typography>
          <Paper className={classes.profileCard} justifyContent="center">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Avatar
                  src={`${process.env.REACT_APP_STATIC_URL}/${profile?.userId}/${profile?.profilePic}`}
                  className={classes.avatar}
                />
              </Grid>
              <Grid item>
                <Typography align="center" variant="h5">
                  {profile?.firstName.toUpperCase()}{" "}
                  {profile?.lastName.toUpperCase()}
                </Typography>
              </Grid>
              {!!profile?.phoneNo && (
                <Grid item xs={8}>
                  <Typography align="center" variant="h6">
                    <b>{t("profiles.phoneNo")}:</b>{" "}
                    <a href={`tel:${profile.phoneNo}`}>{profile.phoneNo}</a>
                  </Typography>
                </Grid>
              )}
              {!!profile?.email && (
                <Grid item>
                  <Typography align="center" variant="h6">
                    <b>{t("profiles.email")}:</b>{" "}
                    <a href={`mailto:${profile.email}`}>{profile.email}</a>
                  </Typography>
                </Grid>
              )}
              {!!profile?.email && (
                <Grid item>
                  <Typography align="center" variant="h6">
                    <b>{t("profiles.website")}:</b>{" "}
                    <a href={profile.website}>{profile.website}</a>
                  </Typography>
                </Grid>
              )}
              {!!profile?.biography && (
                <>
                  <Grid item>
                    <Typography align="center" variant="h6">
                      <b>{t("profiles.biography")}:</b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      align="center"
                      className={classes.cardContent}
                    >
                      {profile.biography}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>

          <Typography variant="h6" align="justify">
            {t("addVIP.directMessagesInApp")}
          </Typography>

          <Grid container alignItems="center">
            <Grid item xs={4}>
              <a href="https://apps.apple.com/bs/app/prowin-messenger/id1553868389">
                <img
                  src={appStore[lang]}
                  alt={t("landingpage.appStore")}
                  style={{ width: "80%" }}
                />
              </a>
            </Grid>
            <Grid item xs={4}>
              <a href="https://play.google.com/store/apps/details?id=net.prowin.messenger">
                <img
                  src={playStore[lang]}
                  alt={t("landingpage.playStore")}
                  style={{ margin: "-6%", width: "100%" }}
                />
              </a>
            </Grid>
            <Grid item xs={4}>
              <a href="https://appgallery.huawei.com/#/app/C104422751">
                <img
                  src={appGallery[lang]}
                  alt={t("landingpage.playStore")}
                  style={{ margin: "0%", width: "100%" }}
                />
              </a>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  langButton: {
    textTransform: "none",
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: theme.palette.primary.main,
  },
  profileCard: {
    marginBottom: theme.spacing(4),
  },
  cardContent: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
}));

export default AddVIP;
