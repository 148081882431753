import React from "react";
import { Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const Impress = (props) => {
  const styles = useStyles();

  const { t, i18n } = useTranslation();

  return (
    <Container className={styles.container}>
      <Typography variant="h4" component="h1">
        {t("impress.title")}
      </Typography>
      <Typography variant="h6" component="h3" className={styles.h3}>
        {t("impress.subtitle")}
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        proWIN Winter Gesellschaft mit beschränkter Haftung (GmbH)
        <br />
        Zeppelinstr. 8<br />
        66557 Illingen
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        {t("impress.directors")}:<br />
        Stefan Schäfer, Sascha Winter, Michael Winter
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        {t("impress.phone")}: +49 (0) 68 25 - 92 01-0
        <br />
        {t("impress.email")}: info@prowin.net
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        {t("impress.vatNo")}:<br />
        DE176610889
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        {t("impress.registerCourt")}:<br />
        Amtsgericht Saarbrücken
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        {t("impress.registerNo")}:<br />
        HRB 43900
      </Typography>
      {(i18n.language.indexOf("de") !== -1 ||
        i18n.language.indexOf("fr") !== -1) && (
        <>
          <Typography variant="h6" component="h3" className={styles.h3}>
            {t("impress.contentProvider")}:
          </Typography>
          <Typography variant="body1" component="p" className={styles.p}>
            Jonatan Mosner
          </Typography>
        </>
      )}
      <Typography variant="h6" component="h3" className={styles.h3}>
        {t("impress.disputeResolutionTitle")}:
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        {t("impress.disputeResolution")}{" "}
        <a href="http://ec.europa.eu/consumers/odr/">
          http://ec.europa.eu/consumers/odr/
        </a>
      </Typography>
      <Typography variant="body1" component="p" className={styles.p}>
        {t("impress.disputeResolutionEmail")}{" "}
        <a href="mailto:info@prowin.net">info@prowin.net</a>
      </Typography>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  h2: {
    marginTop: 20,
  },
  h3: {
    marginTop: 10,
    paddingLeft: 20,
  },
  p: {
    fontSize: "0.9em",
    paddingLeft: 20,
    marginBottom: 15,
  },
}));

export default Impress;
