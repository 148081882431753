import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const HostDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit } = props;
  const [guestId, setGuestId] = React.useState();
  const [name, setName] = React.useState();
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: (guestId, name) => {
      setGuestId(guestId);
      setName(name);
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSubmit(guestId);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("party.makeHostTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("party.makeHost", { name })}</DialogContentText>
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("general.cancel")}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {t("general.confirm")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
}));

export default React.forwardRef(HostDialog);
