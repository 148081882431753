import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Avatar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";

const SendProfileDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit, userId, profile } = props;
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("party.sendProfileTitle")}:</DialogTitle>
        <DialogContent>
          <div className={classes.picWrapper}>
            <Avatar
              src={`${process.env.REACT_APP_STATIC_URL}/${userId}/${profile.profilePic}`}
              className={classes.avatar}
            />
            <p className={classes.name}>
              {profile.firstName} {profile.lastName}
            </p>
          </div>
          <DialogContentText>
            {!!profile.birthday && (
              <>
                <b>{t("profiles.birthday")}: </b>
                {moment(profile.birthday).format(t("general.dateFormat"))}
                <br />
              </>
            )}
            {!!profile.phoneNo && (
              <>
                <b>{t("profiles.phoneNo")}: </b>
                <a href={`tel:${profile.phoneNo}`}>{profile.phoneNo}</a>
                <br />
              </>
            )}
            {!!profile.email && (
              <>
                <b>{t("profiles.email")}: </b>
                <a href={`mailto:${profile.email}`}>{profile.email}</a>
                <br />
              </>
            )}
            {!!profile.website && (
              <>
                <b>{t("profiles.website")}: </b>
                <a href={profile.website}>{profile.website}</a>
                <br />
              </>
            )}
            {!!profile.shopLink && (
              <>
                <b>{t("profiles.shopLink")}: </b>
                <a href={profile.shopLink}>{profile.shopLink}</a>
                <br />
              </>
            )}
            {!!profile.biography && (
              <>
                <b>{t("profiles.biography")}:</b>
                <br />
                {profile.biography}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("general.cancel")}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {t("general.send")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
  picWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  name: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: "bold",
  },
}));

export default React.forwardRef(SendProfileDialog);
