import { LOGIN, LOGOUT, INFORM, DISMISS_BANNER } from "../types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return action.payload;
    }

    case LOGOUT: {
      return initialState;
    }

    case INFORM: {
      if (!state.informed) {
        state.informed = true;
      }
      return JSON.parse(JSON.stringify(state));
    }

    default:
      return state;
  }
};
