import React from "react";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  InputBase,
  FormControl,
  Select,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ArrowIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import { alpha } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { useActions } from "actions";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ImportDialog } from "components";

const AppBarWrapper = (props) => {
  const classes = useStyles();
  const { open } = props;
  const { searchPlaceholder, onOpen } = props;
  const {
    applicationSpecific,
    applications,
    application,
    onApplicationChange,
    searchChange,
    search,
    banner,
  } = props;

  const { Logout } = useActions();

  const { t, i18n } = useTranslation();

  const langButton = React.useRef();
  const [langOpen, setLangOpen] = React.useState(false);
  const lang = i18n.language.substring(0, 2);
  const selectLang = async (lang) => {
    await i18n.changeLanguage(lang);
    setLangOpen(false);
  };

  const isMobile = !useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const button = React.useRef();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [name, setName] = React.useState("");

  const session = useSelector((state) => state.session);
  const profile = useSelector((state) => state.profile);
  const settings = useSelector((state) => state.settings);

  React.useEffect(() => {
    if (session.secretKey) {
      setName(session?.firstName + " " + session?.lastName);
      return;
    }
    if (!settings?.[session.userId]?.selectedProfile || !profile.profiles) {
      setName("");
      return;
    }
    const selectedProfile =
      profile.profiles.find(
        (p) => p.id === settings[session.userId].selectedProfile
      ) || {};
    setName(selectedProfile.firstName || selectedProfile.lastName || "");
  }, [profile, settings]);

  const handleLogout = () => {
    setMenuOpen(false);
    Logout();
    iFrameLogOut();
  };

  // iframe to actually logout users sessions
  const iFrameLogOut = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            "<iframe name='proWinLogOut' src='https://accounts.prowin.net/logout' height='0' width='0' />",
        }}
      />
    );
  };

  const handleViewProfile = () => {
    setMenuOpen(false);
    if (!session.secretKey) {
      window.location.href = `/profiles/${
        settings[session.userId].selectedProfile
      }`;
    } else {
      window.location.href = `/guest-profile/${session.id}`;
    }
  };

  const importDialog = React.useRef();
  const showImportDialog = () => {
    setMenuOpen(false);
    importDialog.current.open();
  };

  React.useEffect(() => {
    if (!search) {
      setSearchValue("");
    }
  }, [search]);

  const [searchValue, setSearchValue] = React.useState("");
  const onSearchChange = (val) => {
    setSearchValue(val);
    searchChange(val);
  };

  return <>
    <AppBar
      elevation={0}
      className={clsx(banner ? classes.appBarBanner : classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          edge="start"
          className={clsx({
            [classes.show]: !open && onOpen,
            [classes.hide]: open || !onOpen,
          })}
          color="inherit"
          aria-label="menu"
          onClick={onOpen}
          size="large">
          <MenuIcon />
        </IconButton>
        <img
          src={require("assets/logo-prowin-messenger-white.svg")}
          className={clsx(classes.logo, {
            [classes.show]: !open,
            [classes.hide]: open,
          })}
        />
        {!!searchChange && (
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={searchPlaceholder}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              value={searchValue}
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </div>
        )}
        {!applicationSpecific ? null : (
          <FormControl className={classes.selectWrapper}>
            <Select
              id="applicationselect"
              value={application.applicationId || ""}
              onChange={(e) => onApplicationChange(e.target.value)}
              required
              className={classes.select}
              inputProps={{
                classes: {
                  icon: classes.selectIcon,
                },
              }}
            >
              {!applications
                ? null
                : applications.map((application) => (
                    <MenuItem
                      key={application.applicationId}
                      value={application.applicationId}
                    >
                      {application.displayName}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        )}
        <div className={classes.grow} />
        <IconButton
          color="inherit"
          onClick={() => setLangOpen(!langOpen)}
          ref={langButton}
          className={classes.langButton}
          size="large">
          {lang === "en"
            ? "🇬🇧"
            : lang === "nl"
            ? "🇳🇱"
            : lang === "fr"
            ? "🇫🇷"
            : lang === "it"
            ? "🇮🇹"
            : "🇩🇪"}
        </IconButton>
        {isMobile && (
          <IconButton
            color="inherit"
            onClick={() => setMenuOpen(!menuOpen)}
            ref={button}
            className={classes.buttonMobile}
            size="large">
            <PersonIcon />
          </IconButton>
        )}
        {!isMobile && (
          <Button
            color="inherit"
            onClick={() => setMenuOpen(!menuOpen)}
            ref={button}
            endIcon={<ArrowIcon />}
            classes={{
              root: classes.button,
              endIcon: classes.buttonEndIcon,
            }}
          >
            {name}
          </Button>
        )}
      </Toolbar>
    </AppBar>
    <Menu
      anchorEl={langButton.current}
      keepMounted={true}
      open={langOpen}
      onClose={() => setLangOpen(false)}
    >
      <MenuItem onClick={() => selectLang("de")}>Deutsch 🇩🇪</MenuItem>
      <MenuItem onClick={() => selectLang("en")}>English 🇬🇧</MenuItem>
      <MenuItem onClick={() => selectLang("nl")}>Nederlands 🇳🇱</MenuItem>
      <MenuItem onClick={() => selectLang("fr")}>Français 🇫🇷</MenuItem>
      <MenuItem onClick={() => selectLang("it")}>Italiano 🇮🇹</MenuItem>
    </Menu>
    <Menu
      anchorEl={button.current}
      keepMounted={true}
      open={menuOpen}
      onClose={() => setMenuOpen(false)}
    >
      <MenuItem onClick={handleViewProfile} disabled={!name}>
        {t("general.viewProfile")}
      </MenuItem>
      <MenuItem onClick={showImportDialog}>{t("import.menuLabel")}</MenuItem>
      <MenuItem onClick={handleLogout} target="proWinLogOutFrame">
        {t("general.logout")}
      </MenuItem>
    </Menu>
    <ImportDialog ref={importDialog} />
  </>;
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarBanner: {
    marginTop: 20,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  show: {
    opacity: 1.0,
    marginRight: 0,
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(4),
    },
    transition: theme.transitions.create(["opacity", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hide: {
    opacity: 0.0,
    marginRight: theme.spacing(-5),
    transition: theme.transitions.create(["opacity", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      width: "8em",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  searchIcon: {
    height: "100%",
    width: theme.spacing(5),
    pointerEvents: "none",
    display: "flex",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    width: "100%",
  },
  selectWrapper: {
    minWidth: 160,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  select: {
    color: "white",
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderColor: "white",
    },
  },
  selectIcon: {
    fill: "white",
  },
  grow: {
    width: 0,
    flexGrow: 1,
  },
  langButton: {
    paddingTop: 11,
    fontSize: "1.5rem",
    lineHeight: "1rem",
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
  },
  buttonEndIcon: {
    marginLeft: 0,
  },
  buttonMobile: {
    marginLeft: -theme.spacing(1),
    marginRight: -theme.spacing(1),
  },
}));

export default AppBarWrapper;
