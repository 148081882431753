import React from "react";
import PhotoLightbox from "react-image-lightbox";

const ImageCarousel = ({
  open,
  photos,
  currentIndex,
  party,
  setLightboxOpen,
  setPhotoIndex,
}) => {
  const getPhotoSource = (currentIndex) => {
    let p = photos[currentIndex];
    if (p?.messageType === "photo")
      return `${process.env.REACT_APP_STATIC_URL}/${party.id}/${p.message}`;
    return `${process.env.REACT_APP_STATIC_URL}/${
      p.mediaAttachmentOwner || party.userId
    }/${p.mediaAttachment}`;
  };

  if (!open) return null;
  return (
    <PhotoLightbox
      mainSrc={getPhotoSource(currentIndex)}
      prevSrc={getPhotoSource((currentIndex + 1) % photos.length)}
      nextSrc={getPhotoSource(
        (currentIndex + photos.length - 1) % photos.length
      )}
      onCloseRequest={() => setLightboxOpen(false)}
      onMoveNextRequest={() =>
        setPhotoIndex((currentIndex + photos.length - 1) % photos.length)
      }
      onMovePrevRequest={() =>
        setPhotoIndex((currentIndex + 1) % photos.length)
      }
    />
  );
};

export default ImageCarousel;
