import React from "react";
import { Snackbar, LinearProgress, Typography } from "@mui/material";
import { Alert } from '@mui/material';
import { makeStyles } from "@mui/styles";

const UploadProgress = ({ showUpload, progress, description }) => {
  const classes = useStyles();

  let severity = "info";
  if (progress === 100) severity = "success";
  if (progress === -1) severity = "error";

  return (
    <Snackbar
      open={showUpload}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Alert severity={severity}>
        {progress > 0 && (
          <LinearProgress
            variant="determinate"
            value={progress}
            color="primary"
            className={classes.uploadProgress}
          />
        )}
        <Typography>{description}</Typography>
      </Alert>
    </Snackbar>
  );
};

const useStyles = makeStyles(() => ({
  uploadProgress: {
    width: 300,
    height: 20,
    borderRadius: 5,
  },
}));

export default UploadProgress;
