import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Ellipsis from "react-lines-ellipsis";

const ReadReceiptDialog = (props, ref) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const classes = useStyles({ windowWidth });

  const [open, setOpen] = React.useState(false);
  const [guests, setGuests] = React.useState([]);

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: (guests) => {
      setOpen(true);
      setGuests(guests);
    },
  }));

  const handleClose = () => {
    setOpen(false);
    setGuests([]);
  };

  const renderGuest = (guest) => {
    let fullName;
    if (!guest.firstName && guest.lastName) {
      fullName = `${guest.lastName}`;
    } else if (!guest.firstName && !guest.lastName) {
      fullName = guest.name;
    } else {
      fullName = `${guest.firstName} ${guest.lastName}`;
    }
    return (
      <div className={classes.guest} key={guest.id}>
        <div className={classes.guestName}>
          <Ellipsis lines={1} text={fullName} basedOn="letters" />
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      <form onSubmit={handleClose}>
        <DialogTitle>{t("party.readReceiptDialogTitle")}</DialogTitle>
        <DialogContent>
          <div className={classes.list}>{guests.map(renderGuest)}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("general.close")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: theme.spacing(1),
    maxHeight: `calc(100% - ${theme.spacing(2)})`,
  },
  list: {
    maxHeight: "calc(100vh - 205px)",
    overflowY: "auto",
    width: 400,
    maxWidth: (props) => props.windowWidth - theme.spacing(4),
    marginLeft: -theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
    },
  },
  guest: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    width: 400,
    maxWidth: (props) => props.windowWidth - theme.spacing(4),
    marginBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  guestName: {
    flex: 1,
    fontSize: 16,
  },
}));

export default React.forwardRef(ReadReceiptDialog);
