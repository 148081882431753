import React from "react";
import {
  Container,
  CssBaseline,
  Avatar,
  InputBase,
  Button,
} from "@mui/material";
import { AppBar, ActionBar, Menu, PartyLoading } from "components";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useActions } from "actions";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useHistory, useParams } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/nl";
import "moment/locale/fr";

const GuestProfile = (props) => {
  const { profileId } = useParams();
  const session = useSelector((state) => state.session);
  const profile = useSelector((state) => state.profile);
  const guestId = useSelector((state) => state.session.id);
  const [loading, setLoading] = React.useState(true);

  const { UpdateGuestProfile, GetGuest } = useActions();

  React.useEffect(async () => {
    const { error, response } = await GetGuest(guestId);
    console.log(response);
    console.log(guestId);
    setLoading(false);
    setFirstName(response?.data.firstName || "");
    setLastName(response?.data.lastName || "");
    setBirthday(response?.data.birthday ? new Date(profile.birthday) : null);
    setPhoneNo(response?.data.phoneNo || "");
    setEmail(response?.data.email || "");
    setWebsite(response?.data.website || "");
    setBiography(response?.data.biography || "");
    // setShopLink(proresponse?.shopLink || "");
    setProfilePic();
    setSelectedFile();
  }, []);

  const history = useHistory();
  const cancel = () => {
    history.push("/parties");
  };

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const shortLang = i18n.language.split("-")[0];
    moment.locale(shortLang === "en" ? "en-gb" : shortLang);
  }, [i18n.language]);

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [birthday, setBirthday] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [biography, setBiography] = React.useState("");
  const [profilePic, setProfilePic] = React.useState();
  const [shopLink, setShopLink] = React.useState();
  const [selectedFile, setSelectedFile] = React.useState();
  const [deleteProfilePic, setDeleteProfilePic] = React.useState(false);
  const [hasChanged, setHasChanged] = React.useState(false);

  React.useEffect(() => {
    if (!profile || loading) {
      setHasChanged(false);
      return;
    }
    setHasChanged(
      firstName != (profile.firstName || "") ||
        lastName != (profile.lastName || "") ||
        (birthday ? birthday.toISOString() : "") != (profile.birthday || "") ||
        phoneNo != (profile.phoneNo || "") ||
        email != (profile.email || "") ||
        website != (profile.website || "") ||
        biography != (profile.biography || "") ||
        shopLink != (profile.shopLink || "") ||
        profilePic ||
        deleteProfilePic
    );
  }, [
    profile,
    firstName,
    lastName,
    birthday,
    phoneNo,
    email,
    website,
    biography,
    profilePic,
    shopLink,
    deleteProfilePic,
  ]);

  React.useEffect(() => {
    document.title = `${firstName} ${lastName} - proWIN Messenger`;
  }, [firstName, lastName]);

  /* selecting files from hard drive */
  React.useEffect(() => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = false;
    input.accept = ["image/*"];
    input.onchange = ({ target }) => {
      const { files } = target;
      setSelectedFile(files[0]);
    };
    input.id = "fileSelector";
    input.style.display = "none";
    document.body.append(input);
    return () => {
      document.body.removeChild(input);
    };
  }, []);
  const selectFiles = () => {
    document.getElementById("fileSelector").click();
  };
  React.useEffect(() => {
    if (selectedFile) {
      setProfilePic(URL.createObjectURL(selectedFile));
      setDeleteProfilePic(false);
    }
  }, [selectedFile]);

  // const handleDeleteProfilePic = () => {
  //   setProfilePic();
  //   if (profile.profilePic) setDeleteProfilePic(true);
  // };

  const handleSave = async () => {
    let { error } = await UpdateGuestProfile(
      {
        firstName,
        lastName,
        birthday,
        phoneNo,
        email,
        website,
        biography,
        newProfilePicType: profilePic ? selectedFile.type : undefined,
        deleteProfilePic,
      },
      profilePic ? selectedFile : null
    );
    if (error) {
      return;
    }
    history.push("/parties");
  };

  const clearBirthday = (e) => {
    e.stopPropagation();
    setBirthday(null);
  };

  if (loading) return <PartyLoading />;

  return (
    <>
      <Container className={classes.screen}>
        <CssBaseline />
        <AppBar open={menuOpen} onOpen={() => setMenuOpen(true)} />
        <Menu
          items={props.menuItems}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          selected="profiles"
        />
        <div className={classes.profileWrapper}>
          <div className={classes.picWrapper}>
            <Avatar
              onClick={selectFiles}
              src={
                profilePic ||
                (deleteProfilePic || !profile.profilePic
                  ? null
                  : `${process.env.REACT_APP_STATIC_URL}/${session.userId}/${profile.profilePic}`)
              }
              className={classes.avatar}
            />
            <div className={classes.actions}>
              <CameraAltIcon
                onClick={selectFiles}
                sx={{ color: "#F3F9FC", fontSize: 15, margin: "auto" }}
              />
            </div>
          </div>
          <div className={classes.fieldGroup}>
            <InputBase
              placeholder={t("profiles.firstName")}
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={classes.field}
              style={{ marginRight: "20px" }}
              required
            />
            <InputBase
              placeholder={t("profiles.lastName")}
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={classes.field}
              required
            />
          </div>
          <InputBase
            placeholder={t("profiles.phoneNo")}
            type="tel"
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
            className={classes.field}
            style={{ marginRight: "20px" }}
          />
          <InputBase
            placeholder={t("profiles.email")}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={classes.field}
          />
          <InputBase
            placeholder={t("profiles.website")}
            type="url"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            className={classes.field}
            style={{ marginRight: "20px" }}
          />
          <InputBase
            placeholder={t("profiles.shopLink")}
            type="url"
            value={shopLink}
            onChange={(e) => setShopLink(e.target.value)}
            className={classes.field}
          />
          <InputBase
            placeholder={
              biography.length > 0
                ? `${t("profiles.biography")} (${biography.length}/300)`
                : t("profiles.biography")
            }
            type="text"
            value={biography}
            onChange={(e) => setBiography(e.target.value)}
            multiline
            rows={3}
            inputProps={{
              maxLength: 300,
            }}
            className={classes.bioField}
          />
          <div className={classes.buttonRegister}>
            <Button
              onClick={cancel}
              style={{
                textTransform: "none",
                fontFamily: "Roboto",
                background: "#f8f8f8",
                color: "#6F7E8C",
                boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
                padding: "3px 30px",
                fontSize: 14,
                margin: 10,
              }}
              startIcon={<HighlightOffIcon />}
            >
              Cancel
            </Button>

            <Button
              style={{
                textTransform: "none",
                fontFamily: "Roboto",
                background: "rgba(30, 146, 205, 100)",
                color: "#FFF",
                boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
                padding: "3px 30px",
                fontSize: 14,
                margin: 10,
              }}
              startIcon={<CheckIcon />}
              onClick={handleSave}
            >
              Save
            </Button>
            {/* Do we need this button for guests? */}
            <Button
              style={{
                textTransform: "none",
                fontFamily: "Roboto",
                background: "#FFF",
                color: "#F74F0B",
                boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
                padding: "3px 60px",
                margin: 10,
                fontSize: 14,
              }}
              startIcon={<DeleteOutlineIcon />}
            >
              Delete this profile
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    maxWidth: "100%",
    height: "100vh",
    backgroundColor: "#F3F9FC",
  },
  profileWrapper: {
    margin: "130px auto 0",
    [theme.breakpoints.down("xs")]: {
      marginTop: 120,
    },
    maxWidth: 420,
  },
  companySwitch: {
    marginBottom: 20,
  },
  picWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  actions: {
    display: "flex",
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: "rgba(51,62,72,0.7)",
    borderRadius: "50%",
    cursor: "pointer",
    color: "#fff",
    marginLeft: "4rem",
    marginTop: "-1.5rem",
    zIndex: "2",
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    border: "solid 2px #F7920B",
    zIndex: "1",
  },
  profilesList: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  profileThumbnail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  selected: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: "#00BDF2",
  },
  title: {
    marginBottom: 0,
  },
  fieldGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "2rem",
  },
  field: {
    fontSize: "13px",
    color: "#000",
    width: 200,
    marginBottom: 20,
    paddingLeft: 10,
    height: 40,
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  bioField: {
    width: "100%",
    fontSize: "13px",
    color: "#000",
    marginBottom: 20,
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
  },
  buttonRegister: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    // maxWidth: 393,
  },
}));

export default GuestProfile;
