import React from "react";
import { Container, CssBaseline } from "@mui/material";
import { AppBar, ActionBar, Menu } from "components";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import de from "./de.json";
import en from "./en.json";
import nl from "./nl.json";
import fr from "./fr.json";
import it from "./it.json";

const getLanguageData = (lang) => {
  const langShort = lang.split("-")[0];
  switch (langShort) {
    case "en":
      return en;
    case "nl":
      return nl;
    case "fr":
      return fr;
    case "it":
      return it;
    default:
      return de;
  }
};

const ReleaseNotes = (props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const { i18n } = useTranslation();
  const releaseNotesData = getLanguageData(i18n.language);

  const releaseNotes = useSelector((state) => state.releaseNotes);
  const { HandleViewReleaseNotes } = useActions();

  React.useEffect(() => {
    document.title = `Release Notes - proWIN Messenger`;
    if (releaseNotes.lastViewedVersion >= process.env.REACT_APP_VERSION) return;
    HandleViewReleaseNotes(process.env.REACT_APP_VERSION);
  }, []);

  const renderSection = (index, title, description) => {
    return (
      <React.Fragment key={index}>
        <h2 className={classes.subHeading}>{title}</h2>
        <p className={classes.paragraph}>{description}</p>
      </React.Fragment>
    );
  };

  return (
    <>
      <Container className={classes.screen}>
        <CssBaseline />
        <AppBar open={menuOpen} onOpen={() => setMenuOpen(true)} />
        <ActionBar title={"Release Notes"} open={menuOpen} />
        <Menu
          items={props.menuItems}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          selected="releaseNotes"
        />
        <main className={classes.main}>
          {releaseNotesData.map((version) => (
            <>
              <h1 className={classes.title}>
                {version.version} - {version.releaseDate}
              </h1>
              {!!version.description && (
                <p className={classes.paragraph}>{version.description}</p>
              )}
              {version.features.map(({ title, description }, index) =>
                renderSection(index, title, description)
              )}
              {!!version.bugfixTitle && (
                <>
                  <h2 className={classes.subHeading}>{version.bugfixTitle}</h2>
                  <ul>
                    {version.bugfixes.map((bugfix, index) => (
                      <li key={index}>{bugfix}</li>
                    ))}
                  </ul>
                </>
              )}
            </>
          ))}
        </main>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    maxWidth: "100%",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    maxWidth: "100%",
    marginTop: 115,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
    },
  },
  title: {
    marginBottom: 0,
  },
  subHeading: {
    marginBottom: 0,
  },
  paragraph: {
    marginTop: 0,
    marginBottom: 0,
    whiteSpace: "pre-wrap",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default ReleaseNotes;
