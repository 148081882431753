import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-hls-quality-selector";
import contribQualityLevels from "videojs-contrib-quality-levels";
import { useTranslation } from "react-i18next";

const MediaPlayer = (props, ref) => {
  const classes = useStyles();

  const [player, setPlayer] = React.useState();
  const videoPlayer = React.useRef();

  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [mediaId, setMediaId] = React.useState("");
  const [mediaType, setMediaType] = React.useState("photo");

  const { t } = useTranslation();

  React.useEffect(() => {
    videojs.registerPlugin("qualityLevels", contribQualityLevels);
  }, []);

  React.useEffect(() => {
    if (!open || mediaType !== "video") return;
    setTimeout(() => {
      if (!videoPlayer.current) return;
      let p = videojs(videoPlayer.current, {
        autoplay: true,
        controls: true,
        fluid: true,
        sources: [
          {
            src: `${process.env.REACT_APP_STATIC_URL}/${userId}/${mediaId}.m3u8`,
            withCredentials: false,
          },
        ],
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          hls: {
            overrideNative: true,
            withCredentials: false,
          },
        },
      });
      p.hlsQualitySelector({
        displayCurrentQuality: false,
      });
      setPlayer(p);
    }, 100);
  }, [open]);

  React.useImperativeHandle(ref, () => ({
    open: (title, userId, mediaId, mediaType) => {
      setOpen(true);
      setTitle(title || "");
      setUserId(userId);
      setMediaId(mediaId);
      setMediaType(mediaType || "photo");
    },
  }));

  const handleClose = () => {
    if (player && mediaType === "video") player.dispose();
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (player && mediaType === "video") player.dispose();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {mediaType === "image" && (
            <img
              src={`${process.env.REACT_APP_STATIC_URL}/${userId}/${mediaId}`}
              className={classes.photo}
              alt={title}
            />
          )}
          {mediaType === "video" && (
            <div data-vjs-player>
              <video ref={videoPlayer} className="video-js"></video>
            </div>
          )}
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button type="submit" color="primary" variant="contained">
            {t("general.close")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
  dialogContent: {
    width: 600,
    height: 400,
    maxWidth: "90vw",
    maxHeight: "80vh",
    display: "flex",
    justifyContent: "center",
  },
  photo: {
    minHeight: 300,
    maxHeight: "100%",
    maxWidth: "100%",
  },
  video: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

export default React.forwardRef(MediaPlayer);
