import {
  PARTY_INFO,
  PARTY_GUESTS,
  LATEST_CHAT,
  HANDLE_MESSAGE,
  HANDLE_GUESTS,
  HANDLE_GUEST_UPDATED,
  HANDLE_GUEST_REMOVED,
  REMOVE_GUEST,
  HANDLE_UPDATED_MESSAGE,
  HANDLE_UPDATED_SEEN,
  HANDLE_NEW_HOST,
  LEAVE_CHAT,
  GET_PROFILE,
  TRY_SEND_MESSAGE,
  SEND_MESSAGE,
  REMOVE_TEMP_MESSAGE,
} from "../types";
import moment from "moment";

export const sortMessages = (a, b) => {
  if (a && b) {
    if (moment(a.timestamp).isBefore(b.timestamp)) return -1;
    if (moment(b.timestamp).isBefore(a.timestamp)) return 1;
    return 0;
  }
};

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case PARTY_INFO: {
      let info = action.payload;
      if (!state[info.shortId]) state[info.shortId] = {};
      state[info.shortId].info = info;
      return state;
    }

    case HANDLE_GUESTS:
    case PARTY_GUESTS: {
      let { partyId, guests } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      state[partyId].guests = guests;
      return state;
    }

    case LATEST_CHAT: {
      let { partyId, from, messages } = action.payload;
      if (!state[partyId]) state[partyId] = {};

      if (!from) {
        state[partyId].messages = messages.sort(sortMessages);
      } else {
        if (!state[partyId].messages) state[partyId].messages = [];
        let newMessages = messages.filter(
          (m) => !state[partyId].messages.find((o) => o.id === m.id)
        );
        state[partyId].messages = [
          ...state[partyId].messages,
          ...newMessages,
        ].sort(sortMessages);
      }
      return state;
    }

    case HANDLE_MESSAGE: {
      let { partyId, message } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      if (!state[partyId].messages) state[partyId].messages = [];
      if (!state[partyId].messages.find((m) => m.id === message.id))
        state[partyId].messages = [...state[partyId].messages, message].sort(
          sortMessages
        );
      return state;
    }

    case TRY_SEND_MESSAGE: {
      let { partyId, message, tempId } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      if (!state[partyId].messages) state[partyId].messages = [];
      let msg = state[partyId].messages.find((m) => m.tempId === tempId);
      if (msg) {
        delete msg.failed;
        state[partyId].messages = [...state[partyId].messages].sort(
          sortMessages
        );
      } else {
        state[partyId].messages = [...state[partyId].messages, message].sort(
          sortMessages
        );
      }
      return state;
    }

    case SEND_MESSAGE: {
      let { partyId, message, tempId } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      if (!state[partyId].messages) state[partyId].messages = [];
      state[partyId].messages = [
        ...state[partyId].messages.filter((m) => m.tempId !== tempId),
        message,
      ].sort(sortMessages);
      return state;
    }

    case REMOVE_TEMP_MESSAGE: {
      let { partyId, tempId } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      if (!state[partyId].messages) state[partyId].messages = [];
      let msg = state[partyId].messages.find((m) => m.tempId === tempId);
      if (msg) msg.failed = true;
      state[partyId].messages = [...state[partyId].messages].sort(sortMessages);
      return state;
    }

    case HANDLE_UPDATED_MESSAGE: {
      let { partyId, message } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      if (!state[partyId].messages) state[partyId].messages = [];
      const existingMessageIndex = state[partyId].messages.findIndex(
        (m) => m.id === message.id
      );
      if (existingMessageIndex !== -1)
        state[partyId].messages[existingMessageIndex] = message;
      return JSON.parse(JSON.stringify(state));
    }

    case HANDLE_UPDATED_SEEN: {
      let { partyId, guestId, timestamp } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      if (!state[partyId].guests) state[partyId].guests = [];
      const guest = state[partyId].guests.find((g) => g.id === guestId);
      if (!guest) return state;
      guest.lastSeen = timestamp;
      state[partyId].guests = [...state[partyId].guests];
      return state;
    }

    case HANDLE_GUEST_UPDATED: {
      let { partyId, guest } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      if (!state[partyId].guests) state[partyId].guests = [];
      const oldIndex = state[partyId].guests.findIndex(
        (g) => g.id === guest.id
      );
      if (oldIndex !== -1)
        state[partyId].guests[oldIndex] = {
          ...state[partyId].guests[oldIndex],
          ...guest,
        };
      else state[partyId].guests.push(guest);
      state[partyId].guests = [...state[partyId].guests];
      return state;
    }

    case HANDLE_GUEST_REMOVED: {
      let { partyId, guestId } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      if (!state[partyId].guests) state[partyId].guests = [];
      state[partyId].guests = state[partyId].guests.filter(
        (g) => g.id !== guestId
      );
      return state;
    }

    case REMOVE_GUEST: {
      let { partyId, guestId } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      if (!state[partyId].guests) state[partyId].guests = [];
      state[partyId].guests = state[partyId].guests.filter(
        (g) => g.id !== guestId
      );
      return state;
    }

    case HANDLE_NEW_HOST: {
      let { partyId, guestId } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      if (!state[partyId].guests) state[partyId].guests = [];
      let oldHost = state[partyId].guests.find((g) => g.isHost);
      if (oldHost) oldHost.isHost = false;
      let newHost = state[partyId].guests.find((g) => g.id === guestId);
      if (newHost) newHost.isHost = true;
      state[partyId].guests = [...state[partyId].guests];
      return state;
    }

    case LEAVE_CHAT: {
      let { partyId } = action.payload;
      state[partyId] = undefined;
      return state;
    }

    case GET_PROFILE: {
      let { partyId, profile } = action.payload;
      if (!state[partyId]) state[partyId] = {};
      state[partyId].profile = profile;
      return state;
    }

    default:
      return state;
  }
};
