import ClearIcon from "@mui/icons-material/Clear";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/nl";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Photo } from "@mui/icons-material";
import axios from "axios";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { FilePond, registerPlugin } from "react-filepond";
import { useActions } from "src/redux/actions";

registerPlugin(FilePondPluginFileValidateType, FilePondPluginImagePreview);

const BannerDialog = ({ bannerIndex, banner, onSubmit, onClose, onDelete }) => {
  const theme = useTheme();

  const { i18n } = useTranslation();

  const { GenerateUploadUrl } = useActions();

  useEffect(() => {
    const shortLang = i18n.language.split("-")[0];
    moment.locale(shortLang === "en" ? "en-gb" : shortLang);
  }, [i18n.language]);

  const [title, setTitle] = useState();
  const [image, setImage] = useState();
  const [url, setUrl] = useState();
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    setTitle(banner?.title);
    setImage(banner?.image);
    setUrl(banner?.url);
    setStartTime(banner?.startTime ? moment(banner.startTime) : null);
    setEndTime(banner?.endTime ? moment(banner.endTime) : null);
  }, [banner]);

  const filePond = useRef(null);
  const filePond2 = useRef(null);
  const handleGenerateUploadUrl = useCallback(
    async (contentType) => {
      const { response } = await GenerateUploadUrl(contentType);
      if (!response?.data) throw new Error("Failed generating upload url.");
      return response.data;
    },
    [GenerateUploadUrl]
  );
  const processUpload = useCallback(
    async (fieldName, file, metadata, load, error, progress, abort) => {
      const contentType = file.type;
      const controller = new AbortController();

      handleGenerateUploadUrl(contentType)
        .then(({ uploadUrl, mediaUrl }) => {
          axios
            .put(uploadUrl, file, {
              headers: {
                "content-type": contentType,
              },
              onUploadProgress: (progressEvent) => {
                progress(
                  !!progressEvent.total,
                  progressEvent.loaded,
                  progressEvent.total || 0
                );
              },
              signal: controller.signal,
            })
            .then(() => {
              load(mediaUrl);
              setImage(mediaUrl);
              setTimeout(() => {
                const fileId = filePond.current
                  ?.getFiles()
                  .find((file) => file.serverId === mediaUrl)?.id;
                if (filePond.current) filePond.current.removeFile(fileId);
              }, 1500);
            })
            .catch(() => {
              error("Upload fehlgeschlagen");
            });
        })
        .catch(() => {
          error("Upload fehlgeschlagen");
        });

      return {
        abort: () => {
          controller.abort();
          abort();
        },
      };
    },
    [handleGenerateUploadUrl]
  );
  const processUploadFile = useCallback(
    async (fieldName, file, metadata, load, error, progress, abort) => {
      const contentType = file.type;
      const controller = new AbortController();

      handleGenerateUploadUrl(contentType)
        .then(({ uploadUrl, mediaUrl }) => {
          axios
            .put(uploadUrl, file, {
              headers: {
                "content-type": contentType,
              },
              onUploadProgress: (progressEvent) => {
                progress(
                  !!progressEvent.total,
                  progressEvent.loaded,
                  progressEvent.total || 0
                );
              },
              signal: controller.signal,
            })
            .then(() => {
              load(mediaUrl);
              setUrl(mediaUrl);
              setTimeout(() => {
                const fileId = filePond2.current
                  ?.getFiles()
                  .find((file) => file.serverId === mediaUrl)?.id;
                if (filePond2.current) filePond2.current.removeFile(fileId);
              }, 1500);
            })
            .catch(() => {
              error("Upload fehlgeschlagen");
            });
        })
        .catch(() => {
          error("Upload fehlgeschlagen");
        });

      return {
        abort: () => {
          controller.abort();
          abort();
        },
      };
    },
    [handleGenerateUploadUrl]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!title?.trim() || !image || !url) return;
    onSubmit(bannerIndex, {
      title,
      image,
      url,
      startTime: startTime?.toISOString() || undefined,
      endTime: endTime?.toISOString() || undefined,
    });
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog open={!!banner} onClose={onClose} fullScreen={isMobile}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {bannerIndex === -1 ? "Banner erstellen" : "Banner bearbeiten"}
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: 10,
          }}
        >
          <TextField
            fullWidth
            autoFocus
            label="Titel"
            style={{ marginBottom: 16 }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: 1,
              mb: 2,
              ["& .filepond--root"]: {
                mb: 0,
              },
            }}
          >
            <Avatar
              variant="rounded"
              sx={{ width: 159, height: 95.5 }}
              src={image}
            >
              <Photo sx={{ width: 50, height: 50 }} />
            </Avatar>
            <Box sx={{ flex: 1 }}>
              <FilePond
                ref={filePond}
                credits={false}
                labelIdle="Bild hierher ziehen oder <span class='filepond--label-action'> auswählen </span>"
                labelFileProcessing="Hochladen..."
                labelTapToCancel="zum Abbrechen klicken"
                labelFileProcessingAborted="Upload abgebrochen"
                labelTapToRetry=""
                labelFileProcessingComplete="Hochgeladen!"
                labelTapToUndo="Mitteilung ausblenden"
                labelFileProcessingError="Upload fehlgeschlagen"
                allowMultiple={false}
                dropValidation={true}
                acceptedFileTypes={["image/jpg", "image/jpeg", "image/png"]}
                imagePreviewHeight={65}
                maxFiles={1}
                server={{
                  process: processUpload,
                  fetch: null,
                  revert: null,
                }}
              />
            </Box>
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!image && image === url}
                onChange={(_e, checked) => setUrl(checked ? image : "")}
              />
            }
            label="Banner-Grafik als angezeigte Datei verwenden?"
            style={{ marginBottom: 16 }}
          />
          <TextField
            fullWidth
            label="Angezeigte Datei"
            style={{ marginBottom: 8 }}
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <Box
            sx={{
              mb: "6px",
            }}
          >
            <FilePond
              ref={filePond2}
              credits={false}
              labelIdle="Angezeigte Datei hierher ziehen oder <span class='filepond--label-action'> auswählen </span>"
              labelFileProcessing="Hochladen..."
              labelTapToCancel="zum Abbrechen klicken"
              labelFileProcessingAborted="Upload abgebrochen"
              labelTapToRetry=""
              labelFileProcessingComplete="Hochgeladen!"
              labelTapToUndo="Mitteilung ausblenden"
              labelFileProcessingError="Upload fehlgeschlagen"
              allowMultiple={false}
              dropValidation={true}
              acceptedFileTypes={[
                "image/jpg",
                "image/jpeg",
                "image/png",
                "application/pdf",
              ]}
              allowImagePreview={false}
              maxFiles={1}
              server={{
                process: processUploadFile,
                fetch: null,
                revert: null,
              }}
            />
          </Box>
          <DateTimePicker
            minutesStep={15}
            label="Start-Zeitpunkt"
            value={startTime}
            onChange={setStartTime}
            renderInput={(params) => (
              <Box style={{ marginBottom: 16 }}>
                <TextField {...params} />
                <IconButton
                  onClick={() => setStartTime(null)}
                  size="small"
                  style={{ marginTop: 10 }}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            )}
            ampm={false}
          />
          <DateTimePicker
            minutesStep={15}
            label="End-Zeitpunkt"
            value={endTime}
            onChange={setEndTime}
            renderInput={(params) => (
              <Box style={{ marginBottom: 16 }}>
                <TextField {...params} />
                <IconButton
                  onClick={() => setEndTime(null)}
                  size="small"
                  style={{ marginTop: 10 }}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            )}
            ampm={false}
          />
        </DialogContent>
        <DialogActions>
          {bannerIndex !== -1 && (
            <div style={{ flex: 1 }}>
              <Button variant="contained" color="error" onClick={onDelete}>
                Banner löschen
              </Button>
            </div>
          )}
          <Button color="inherit" onClick={() => onClose()}>
            Abbrechen
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!title?.trim() || !image || !url}
          >
            Speichern
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BannerDialog;
