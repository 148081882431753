import { JOIN_PARTY, CHANGE_NAME, REMOVE_GUEST } from "../types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case JOIN_PARTY: {
      let { id, partyId, firstName, lastName, email } = action.payload;
      return {
        ...state,
        [partyId]: {
          guestId: id,
          firstName,
          lastName,
          email,
        },
      };
    }

    case CHANGE_NAME: {
      let { partyId, firstName, lastName } = action.payload;
      return {
        ...state,
        [partyId]: {
          ...(state[partyId] || {}),
          firstName,
          lastName,
        },
      };
    }

    case REMOVE_GUEST: {
      let { partyId, guestId } = action.payload;
      console.log(partyId, guestId, state[partyId], state);
      if (state[partyId].guestId !== guestId) return state;
      return {
        ...state,
        [partyId]: null,
      };
    }

    default:
      return state;
  }
};
