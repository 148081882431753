import React from "react";
import {
  Container,
  CssBaseline,
  Menu as UIMenu,
  MenuItem,
  IconButton,
  Card,
  useMediaQuery,
  Snackbar,
  LinearProgress,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Alert } from "@mui/material";
import {
  AppBar,
  ActionBar,
  TemplateDialog,
  DeleteDialog,
  HeadingDialog,
  VoiceDialog,
  PhotoDialog,
  MediaDialog,
  RemoveMessageDialog,
  MoveToDialog,
  MoveToTemplateDialog,
  MoveToChatDialog,
  ForwardMessagesDialog,
  TemplateImportDialog,
  Lightbox,
  ChatBar,
  MediaPreview,
  preventSimultaneousPlayback,
} from "components";
import PhotoLightbox from "react-image-lightbox";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { useActions } from "actions";
import { useTranslation } from "react-i18next";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PlayIcon from "@mui/icons-material/PlayCircleFilled";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import DragHandle from "@mui/icons-material/DragIndicator";
import "react-image-lightbox/style.css";
import EmojiPicker from "emoji-picker-react";
import "draft-js/dist/Draft.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDropzone } from "react-dropzone";
import UploadIcon from "@mui/icons-material/CloudUpload";
import clsx from "clsx";
import MediaInfo from "mediainfo.js";
import idx from "idx";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ClearIcon from "@mui/icons-material/Clear";
import { insertFormatting } from "src/utils/insertFormatting";
import Linkify from "react-linkify";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

let socket;

const Templates = (props) => {
  const { templateId } = useParams();

  const templates = useSelector((state) => state.templates);
  const template = useSelector(
    (state) => state.templates?.find((t) => t.id === templateId) || {}
  );
  const messages = useSelector(
    (state) => state.templateMessages[templateId] || []
  );
  const headings = useSelector(
    (state) =>
      state.templateMessages[templateId]?.filter((m) =>
        m?.messageType.startsWith("heading")
      ) || []
  );
  const me = useSelector((state) => state.me[templateId] || {});

  const parties = useSelector((state) => state.parties);

  const session = useSelector((state) => state.session);
  const {
    GetTemplate,
    GetTemplates,
    EditTemplate,
    RemoveTemplate,
    DuplicateFile,
    RemoveSharedTemplate,
    AddMedia,
    ConvertMedia,
    MoveTemplateMessage,
    SendMessage,
    DeleteTemplateMessage,
    DeleteTemplateMessages,
    EditTemplateMessage,
    AddTemplateMessage,
    CopyToFolder,
    MoveTemplateMessages,
  } = useActions();

  preventSimultaneousPlayback(); //For Audio files

  const [anchorEl, setAnchorEl] = React.useState();
  const [editing, setEditing] = React.useState(false);

  const { t } = useTranslation();
  const { acceptedFiles, getRootProps, getInputProps, isDragReject } =
    useDropzone({
      noDragEventsBubbling: true,
      accept: ["image/*", "video/*", "audio/*", "application/pdf"],
      maxFiles: 10,
    });

  const media = useSelector((state) => state.media) || [];

  const isMobile = !useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [selectedFiles, setSelectedFiles] = React.useState();
  const [hovered, setHovered] = React.useState(false);
  const [moving, setMoving] = React.useState(false);

  /* bulk menu */
  const [globalAnchorEl, setGlobalAnchorEl] = React.useState();
  const [globalChatMenuOpen, setGlobalChatMenuOpen] = React.useState(false);
  const [bulkDelete, setBulkDelete] = React.useState(false);
  const [bulkForward, setBulkForward] = React.useState(false);
  const [bulkMove, setBulkMove] = React.useState(false);
  const [selectMode, setSelectMode] = React.useState(false);
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [selectedMessages, setSelectedMessages] = React.useState([]);
  const [forwardStatus, setForwardStatus] = React.useState("");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [mediaSelected, setMediaSelected] = React.useState(false);

  /* bulk select opartions*/

  const handleGlobalChatMenuOpen = (event) => {
    setGlobalAnchorEl(event.currentTarget);
    setGlobalChatMenuOpen(true);
  };
  const handleGlobalChatMenuClose = () => {
    setGlobalAnchorEl();
    setGlobalChatMenuOpen(false);
  };

  const handleBulkDelete = () => {
    setBulkDelete(true);
    setSelectMode(true);
    setGlobalAnchorEl();
    setGlobalChatMenuOpen(false);
  };
  const handleBulkForward = () => {
    setBulkForward(true);
    setSelectMode(true);
    setGlobalAnchorEl();
    setGlobalChatMenuOpen(false);
  };
  const handleBulkMove = () => {
    setBulkMove(true);
    setSelectMode(true);
    setMoving(true);
    setGlobalAnchorEl();
    setGlobalChatMenuOpen(false);
  };

  const onMessageSelect = (item) => {
    if (selectedIds.includes(item.id))
      setSelectedIds((selectedIds) =>
        selectedIds.filter((id) => id !== item.id)
      );
    else setSelectedIds((selectedIds) => [...selectedIds, item.id]);

    if (selectedMessages.includes(item)) {
      setSelectedMessages((selectedMessages) =>
        selectedMessages.filter((selectedItem) => selectedItem.id !== item.id)
      );
    } else {
      setSelectedMessages((selectedMessages) => [...selectedMessages, item]);
    }
  };

  /* selecting files from hard drive */
  React.useEffect(() => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = ["image/*", "video/*", "audio/*", "application/pdf"];
    input.onchange = ({ target }) => {
      const { files } = target;
      let filesResult = Object.keys(files).map((key) => files[key]);
      setSelectedFiles(filesResult);
    };
    input.id = "fileSelector";
    input.style.display = "none";
    document.body.append(input);
    return () => {
      document.body.removeChild(input);
    };
  }, []);
  const selectFiles = () => {
    document.getElementById("fileSelector").click();
  };
  const cancelPhotoPreview = () => {
    document.getElementById("fileSelector").value = "";
    setSelectedFiles();
  };

  React.useEffect(() => {
    setHovered(false);
    if (acceptedFiles.length > 0) setSelectedFiles(acceptedFiles);
  }, [acceptedFiles]);

  React.useEffect(() => {
    if (selectedFiles) {
      showPhotoDialog();
    }
  }, [selectedFiles]);

  React.useEffect(() => {
    document.body.style.height = windowHeight + "px";
  }, [windowHeight]);
  React.useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    document.body.style.overflow = "hidden";
    return () => {
      window.removeEventListener("resize", handleResize);
      document.body.style.overflow = null;
      document.body.style.height = null;
    };
  }, []);
  const classes = useStyles({
    windowHeight,
    windowWidth,
    isShared: !!template.sharedBy,
  });

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedMessage, setSelectedMessage] = React.useState({});
  const [messageToMove, setMessageToMove] = React.useState();

  const init = async (siteActive) => {
    await GetTemplate(templateId);
    if (siteActive.value) setTimeout(scrollToBottom, 200);
  };
  React.useEffect(() => {
    const siteActive = { value: true };
    init(siteActive);
    return () => {
      siteActive.value = false;
    };
  }, []);

  const templateDialog = React.useRef();
  const handleEdit = () => {
    templateDialog.current.open(template.title);
  };
  const submitTemplateDialog = async (title) => {
    await EditTemplate(templateId, title);
    await GetTemplate(templateId);
  };

  const history = useHistory();
  const deleteDialog = React.useRef();
  const handleDelete = () => {
    deleteDialog.current.open();
  };
  const submitDeleteDialog = async () => {
    if (template.sharedBy) await RemoveSharedTemplate(template.shareToken);
    else await RemoveTemplate(templateId);
    await GetTemplates();
    history.push("/templates");
  };

  const handleMenuOpen = (event, message) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
    setSelectedMessage(message);
  };
  const handleMenuClose = () => {
    setAnchorEl();
    setMenuOpen(false);
    setSelectedMessage({});
  };

  const startEditMessage = () => {
    setEditing(true);
    setMenuOpen(false);
    setTimeout(() => {
      inputRef.current.focus();
      if (
        selectedMessage?.messageType === "photo" ||
        selectedMessage?.mediaAttachmentType === "application" ||
        selectedMessage?.mediaAttachmentType === "video" ||
        selectedMessage?.mediaAttachmentType === "image"
      )
        inputRef.current.setMessage(selectedMessage?.description);
      else inputRef.current.setMessage(selectedMessage?.message);
    }, 100);
  };
  const cancelEditing = () => {
    if (sending) return;
    setEditing(false);
    inputRef.current.setMessage("");
    handleMenuClose();
    setShowEmojis(false);
  };
  const finishEditing = async (message) => {
    if (sending) return;
    setSending(true);

    const editingDescription =
      selectedMessage?.messageType === "photo" ||
      selectedMessage?.mediaAttachmentType === "application" ||
      selectedMessage?.mediaAttachmentType === "video" ||
      selectedMessage?.mediaAttachmentType === "image";
    const newMessage = selectedMessage;
    if (editingDescription) newMessage.description = message;
    else newMessage.message = message;

    await EditTemplateMessage(template.id, newMessage);
    setEditing(false);
    inputRef.current.setMessage("");
    setSending(false);
    handleMenuClose();
    setShowEmojis(false);
    inputRef.current.focus();
  };

  /* video player */
  const [videoOpen, setVideoOpen] = React.useState(false);
  const [selectedVideo, selectVideo] = React.useState();
  const viewMedia = (mediaId, mediaType, owner) => {
    if (mediaType !== "image" && mediaType !== "video") {
      window.open(
        `${process.env.REACT_APP_STATIC_URL}/${owner}/${mediaId}`,
        "_blank"
      );
      return;
    }
    selectVideo({ id: mediaId, mediaType, owner });
    setVideoOpen(true);
  };
  const handleVideoClose = () => {
    setVideoOpen(false);
  };

  const sanitize = (string) => {
    const map = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#x27;",
      "/": "&#x2F;",
    };
    const reg = /[&<>"'/]/gi;
    return string.replace(reg, (match) => map[match]);
  };

  /* photo lightbox */
  const [photos, setPhotos] = React.useState([]);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [lightboxOpen, setLightboxOpen] = React.useState(false);

  React.useEffect(() => {
    if (!messages) return;
    setPhotos(
      messages.filter(
        (m) => m.messageType === "photo" || m.mediaAttachmentType === "image"
      )
    );
  }, [templates]);
  const getPhotoSource = (index) => {
    let p = photos[index];
    if (p.messageType === "photo")
      return `${process.env.REACT_APP_STATIC_URL}/${template.userId}/${p.message}`;
    return `${process.env.REACT_APP_STATIC_URL}/${template.userId}/${p.mediaAttachment}`;
  };

  const [showEmojis, setShowEmojis] = React.useState(false);
  const showEmojiKeyboard = () => {
    setShowEmojis(!showEmojis);
  };
  const hideEmojiKeyboard = () => {
    setShowEmojis(false);
  };
  const onEmojiClick = (event, { emoji }) => {
    let {
      selectionStart,
      selectionEnd,
      value: oldMessage,
    } = document.getElementById("chatInput");
    var newMessage =
      oldMessage.slice(0, selectionStart) +
      emoji +
      oldMessage.slice(selectionEnd);
    inputRef.current.setMessage(newMessage);
    setTimeout(() => {
      inputRef.current.setSelectionRange(
        selectionStart + emoji.length,
        selectionStart + emoji.length
      );
    }, 100);
  };

  const inputRef = React.useRef();

  const [sending, setSending] = React.useState(false);
  const sendTextMessage = async (message) => {
    if (sending || !message) return;
    setSending(true);
    let newMessage = {
      templateId: template.id,
      sender: session.userId,
      messageType: "text",
      message: message,
      beforeMessageId: addBefore,
    };
    await AddTemplateMessage(template.id, newMessage);
    setSending(false);
    inputRef.current.setMessage("");
    setShowEmojis(false);
    scrollToBottom();
  };

  const messageList = React.useRef();
  const scrollToBottom = () => {
    if (addBefore) {
      setTimeout(
        () =>
          document
            .getElementById(addBefore)
            .scrollIntoView(false, { behaviour: "smooth" }),
        500
      );
      return;
    }
    messageList.current.scrollTo({
      top: messageList.current.scrollHeight,
      behaviour: "smooth",
    });
  };

  const theme = useTheme();
  const isMobileForSending = useMediaQuery(theme.breakpoints.down("md"));
  const handleMessageSubmit = (e, message) => {
    if (e.which == 13 && !e.shiftKey && !isMobileForSending && !editing) {
      e.preventDefault();
      sendTextMessage(message);
    } else if (e.which == 13 && !e.shiftKey && !isMobileForSending && editing) {
      e.preventDefault();
      finishEditing(message);
    }
  };

  const [importLoading, setImportLoading] = React.useState(false);
  const templateImportDialog = React.useRef();
  const showTemplateImportDialog = () => {
    templateImportDialog.current.open();
  };

  const voiceDialog = React.useRef();
  const showVoiceDialog = () => {
    voiceDialog.current.open();
  };
  const submitVoiceDialog = async (audioBlob) => {
    if (sending) return;
    setSending(true);
    let newMessage = {
      templateId: template.id,
      sender: session.userId,
      messageType: "voice",
      message: audioBlob,
      beforeMessageId: addBefore,
    };
    await AddTemplateMessage(template.id, newMessage, audioBlob.type);
    setSending(false);
    scrollToBottom();
  };

  const headingDialog = React.useRef();
  const showHeadingDialog = () => {
    headingDialog.current.open();
  };
  const submitHeadingDialog = async (heading, messageType) => {
    if (sending) return;
    setSending(true);
    let newMessage = {
      templateId: template.id,
      sender: session.userId,
      messageType,
      message: heading,
      beforeMessageId: addBefore,
    };
    await AddTemplateMessage(template.id, newMessage);
    setSending(false);
    scrollToBottom();
  };

  const photoDialog = React.useRef();
  const showPhotoDialog = () => {
    photoDialog.current.open();
  };
  const submitPhotoDialogChoice = (submission) => {
    if (submission.saveToLibrary) submitUploadDialog(submission.files);
    else submitPhotoDialog(submission.files);
  };
  const submitPhotoDialog = async (files) => {
    if (sending) return;
    setSending(true);

    for (const file of files) {
      let newMessage = {
        templateId: template.id,
        sender: session.userId,
        messageType: file.type.startsWith("audio") ? "voice" : "photo",
        message: file,
        mediaAttachment: file.type,
        timestamp: moment().toISOString(),
        beforeMessageId: addBefore,
      };
      let { error } = await AddTemplateMessage(
        template.id,
        newMessage,
        file.type
      );
      if (error) {
        console.log("ERROR ADDING TEMPLATE", error?.response?.data);
        setSending(false);
        return;
      }
    }
    setSending(false);
    scrollToBottom();
  };

  const [showUpload, setShowUpload] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploadDescription, setUploadDescription] = React.useState("");

  const submitUploadDialog = async (files) => {
    let len = files.length;
    let mediaFiles = [];
    setShowUpload(true);
    for (var i = 0; i < len; i++) {
      let mediaType = files[i].type.split("/")[0];
      let mediaName = files[i].name.split(".").slice(0, -1).join(".");
      let mediaExtension = /(?:\.([^.]+))?$/.exec(files[i].name)[1];
      setUploadProgress(0);
      setUploadDescription(`${mediaName} wird hochgeladen...`);
      let isPortrait = false;
      let noAudio;
      let videoLength = 0;
      if (mediaType === "video" || mediaType === "image")
        mediaExtension = undefined;
      if (mediaType === "video") {
        let mediaInfo = await MediaInfo();
        const getSize = () => files[i].size;
        const readChunk = (chunkSize, offset) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
              if (event.target.error) reject(event.target.error);
              resolve(new Uint8Array(event.target.result));
            };
            reader.readAsArrayBuffer(
              files[i].slice(offset, offset + chunkSize)
            );
          });
        let info = await mediaInfo.analyzeData(getSize, readChunk);
        noAudio =
          (idx(info, (_) => _.media.track) || []).filter(
            (t) => t["@type"] === "Audio"
          ).length < 1;
        let general = (idx(info, (_) => _.media.track) || []).filter(
          (t) => t["@type"] === "General"
        )[0] || { Duration: "0" };
        videoLength = parseInt(general.Duration);
        let videoTrack = (idx(info, (_) => _.media.track) || []).filter(
          (t) => t["@type"] === "Video"
        )[0] || { Width: "0", Height: "0" };
        isPortrait = parseInt(videoTrack.Height) > parseInt(videoTrack.Width);
        if (
          parseInt(videoTrack.Rotation) === 90 ||
          parseInt(videoTrack.Rotation) === 270
        )
          isPortrait = !isPortrait;
      }
      try {
        let mediaId = await new Promise((resolve, reject) => {
          let fileReader = new FileReader();
          fileReader.onload = async (e) => {
            const { response, error } = await AddMedia(
              files[i].type,
              e.target.result,
              mediaName,
              null,
              mediaType,
              mediaExtension,
              (e) => setUploadProgress((100 / e.total) * e.loaded)
            );
            error ? reject(error) : resolve(response);
          };
          fileReader.readAsArrayBuffer(files[i]);
        });
        if (mediaType === "video" || mediaType === "application") {
          setUploadDescription(`${mediaName} wird konvertiert...`);
          await ConvertMedia(mediaId, isPortrait, noAudio);
          await new Promise((resolve) =>
            setTimeout(resolve, videoLength > 0 ? videoLength * 500 : 1000)
          );
        }
        setUploadDescription(`${mediaName} erfolgreich hochgeladen!`);
        if (i === len - 1)
          setTimeout(() => {
            setShowUpload(false);
            setUploadProgress(0);
            setUploadDescription("");
          }, 500);
        let m = {
          id: mediaId,
          title: mediaName,
          mediaType: mediaType,
          mediaExtension: mediaExtension,
        };

        mediaFiles = [...(mediaFiles || []), m];
      } catch (e) {
        setUploadDescription(`${mediaName}: Hochladen fehlgeschlagen.`);
        if (i === len - 1)
          setTimeout(() => {
            setShowUpload(false);
            setUploadProgress(0);
            setUploadDescription("");
          }, 500);
      }
    }
    handleMediaDescriptions(mediaFiles);
  };

  const [sendingMedia, setSendingMedia] = React.useState();

  const allAudio = (mediaArray) => {
    for (let i = 0; i < mediaArray.length; i++) {
      if (mediaArray[i].mediaType !== "audio") return false;
    }
    return true;
  };

  const handleMediaDescriptions = async (mediaArray) => {
    if (allAudio(mediaArray)) await submitMediaDialog(mediaArray);
    else setSendingMedia(mediaArray);
  };

  /* media message dialog */
  const mediaDialog = React.useRef();
  const showMediaDialog = () => {
    mediaDialog.current.open();
  };
  const submitMediaDialog = async (mediaArray) => {
    if (sending) return;
    setSending(true);

    for (let m of mediaArray) {
      let newMessage = {
        templateId: template.id,
        sender: session.userId,
        messageType: "text",
        message: m.title,
        mediaId: m.id,
        mediaAttachmentType: m.mediaType,
        mediaExtension: m.mediaExtension,
        description: m.description,
        beforeMessageId: addBefore,
      };
      await AddTemplateMessage(template.id, newMessage, m.type);
    }
    setSending(false);
    scrollToBottom();
  };

  /* forward messages */
  const forwardMessagesDialog = React.useRef();
  const showForwardMessagesDialog = () => {
    let nonMediaMessages = [];
    for (const message of selectedMessages) {
      if (!message.mediaAttachmentType) {
        nonMediaMessages.push(message);
      }
    }
    if (nonMediaMessages.length > 0) {
      setMediaSelected(false);
    } else {
      setMediaSelected(true);
    }
    setMenuOpen(false);
    forwardMessagesDialog.current.open();
  };

  const submitMoveToLibrary = async () => {
    showCopyToDialog(selectedMessages);
  };
  const submitMoveToTemplate = async () => {
    showCopyToTemplateDialog(selectedMessages);
  };
  const submitMoveToChat = async () => {
    showCopyToChatDialog(selectedMessages);
  };

  // Dialog to select a CHAT where a message should be copied
  const copyToChatDialog = React.useRef();
  const showCopyToChatDialog = (mediaItems) => {
    copyToChatDialog.current.open(parties, mediaItems);
  };
  const submitCopyToChatDialog = async (mediaItems, folderId, longFolderId) => {
    let user = session?.userId === template?.userId ? null : me.guestId;
    let tidyPartyList = [];

    // transform the parties object into an array to be used later to find the new path
    const result = Object.keys(parties).map((key) => [parties[key]]);
    for (const singleParty of result) {
      tidyPartyList.push(singleParty[0]);
    }

    for (const msg of mediaItems) {
      if (
        msg.messageType === "photo" ||
        msg.messageType === "voice" ||
        msg.mediaAttachmentType
      ) {
        const mediaAttachmentType =
          msg.messageType === "photo"
            ? "image"
            : msg.messageType === "voice"
            ? "audio"
            : msg.mediaAttachmentType;
        let { response } = await DuplicateFile(
          msg.mediaAttachment
            ? `${msg.mediaAttachmentOwner || msg.sender}/${msg.mediaAttachment}`
            : `${msg.sender}/${msg.message}`,
          mediaAttachmentType,
          false
        );

        await SendMessage(
          user,
          folderId,
          false,
          null,
          session.userId,
          "text",
          msg.message,
          response.data.id,
          response.data.mediaType,
          undefined,
          undefined,
          undefined,
          msg.description
        );
      } else {
        await SendMessage(
          user,
          folderId,
          false,
          null,
          session.userId,
          msg.messageType,
          msg.message
        );
      }
    }

    setSelectedMessages([]);
    setSelectedIds([]);
    setBulkForward(false);
    setSelectMode(false);
  };

  // Dialog to select a TEMPLATE where a message should be copied
  const copyToTemplateDialog = React.useRef();
  const showCopyToTemplateDialog = (mediaItems) => {
    copyToTemplateDialog.current.open(templates, mediaItems);
  };
  const submitCopyToTemplateDialog = async (mediaItems, folderId) => {
    for (const msg of mediaItems) {
      if (
        msg.messageType === "photo" ||
        msg.messageType === "voice" ||
        msg.mediaAttachmentType
      ) {
        const mediaAttachmentType =
          msg.messageType === "photo"
            ? "image"
            : msg.messageType === "voice"
            ? "audio"
            : msg.mediaAttachmentType;
        let { response } = await DuplicateFile(
          msg.mediaAttachment
            ? `${msg.mediaAttachmentOwner || msg.sender}/${msg.mediaAttachment}`
            : `${msg.sender}/${msg.message}`,
          mediaAttachmentType,
          false
        );

        let newMessage = {
          templateId: folderId,
          sender: session.userId,
          messageType: "text",
          message: msg.message,
          description: msg.description,
          mediaId: response.data.id,
          mediaAttachmentType: response.data.mediaType,
          beforeMessageId: addBefore,
        };
        await AddTemplateMessage(folderId, newMessage);
      } else {
        let newMessage = {
          templateId: folderId,
          sender: session.userId,
          messageType: msg.messageType,
          message: msg.message,
          beforeMessageId: addBefore,
        };
        await AddTemplateMessage(folderId, newMessage);
      }
    }
    setSelectedMessages([]);
    setSelectedIds([]);
    setBulkForward(false);
    setSelectMode(false);
  };

  // Dialog to selected a MEDIA FOLDER where an attachment should be copied
  const copyToDialog = React.useRef();
  const showCopyToDialog = (mediaItems) => {
    copyToDialog.current.open(media, mediaItems);
  };
  const submitCopyToDialog = async (mediaItems, folderId) => {
    let newPath = [];
    const folder = media.find((m) => m.id === folderId);
    for (let i = 0; i < mediaItems.length; i++) {
      newPath = [...(folder.path ? folder.path.split("/") : []), folderId];
      await copyFiles(mediaItems[i], newPath.join("/"));
    }
    setSelectedMessages([]);
    setSelectedIds([]);
    setBulkForward(false);
  };

  const copyFiles = async (mediaItem, newPath) => {
    let { response, error } = await CopyToFolder(
      `${session.userId}/${mediaItem.mediaAttachment}`,
      mediaItem.mediaAttachmentType,
      newPath,
      undefined
    );
    if (!error) {
      setForwardStatus("Success!");
      setSnackBarOpen(true);
    }
  };

  /* delete message */
  const removeMessageDialog = React.useRef();
  const showRemoveMessageDialog = () => {
    setMenuOpen(false);
    removeMessageDialog.current.open();
  };
  const submitRemoveMessageDialog = async () => {
    if (bulkDelete) {
      let { response, error } = await DeleteTemplateMessages(
        templateId,
        selectedIds
      );
      if (!error) {
        for (const msg of response.data) {
          socket.emit("updateMessage", { templateId, message: msg });
        }
      }
      setSelectedIds([]);
      setBulkDelete(false);
      setSelectMode(false);
    } else {
      await DeleteTemplateMessage(templateId, selectedMessage.id);
    }
    setMenuOpen(false);
  };

  /* start moving message */
  const startMoveMessage = () => {
    if (!template.usesRank) {
      setMoving(true);
      setMessageToMove(selectedMessage.id);
    } else {
      setBulkMove(true);
      setSelectMode(true);
      setMoving(true);
      setSelectedIds([selectedMessage.id]);
      setSelectedMessage();
    }
    handleMenuClose();
  };

  /* finish moving message */
  const finishMoveMessage = () => {
    setMessageToMove();
    setMoving(false);
    setBulkDelete(false);
    setBulkForward(false);
    setBulkMove(false);
    setSelectMode(false);
    setSelectedIds([]);
  };

  /* move message */
  const moveMessage = async (clickedPosition, last) => {
    const newNextId = last ? "last" : clickedPosition.id;
    if (bulkMove) {
      if (selectedIds.length <= 0) return;
      setImportLoading(true);
      await MoveTemplateMessages(templateId, selectedIds, newNextId);
      setImportLoading(false);
      finishMoveMessage();
    } else {
      await MoveTemplateMessage(templateId, messageToMove, newNextId);
      finishMoveMessage();
    }
  };

  /* handle reordering */
  const handleDragEnd = async (result) => {
    if (!result.destination || result.destination.index === result.source.index)
      return;
    const newNextIndex =
      result.destination.index < result.source.index
        ? result.destination.index
        : result.destination.index + 1;
    const newNextId =
      newNextIndex < messages.length ? messages[newNextIndex].id : "last";
    await MoveTemplateMessage(templateId, result.draggableId, newNextId);
  };

  const [addBefore, setAddBefore] = React.useState("");

  const renderMessage = (item, provided, snapshot) => {
    const deleted = item.message === "8b3c857c-b40f-471b-bc66-a134f5924a83";
    if (!item || deleted) return;
    if (item.messageType.startsWith("heading"))
      return (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={clsx([classes.headingMessage, classes[item.messageType]])}
          id={item.id}
        >
          {selectMode && (
            <div className={classes.messageCheckbox}>
              <Checkbox
                checked={selectedIds.includes(item.id)}
                onChange={() => onMessageSelect(item)}
              />
            </div>
          )}
          {!template.sharedBy && (
            <IconButton size="small" {...provided.dragHandleProps}>
              <DragHandle />
            </IconButton>
          )}
          <span
            style={{
              flex: 1,
              textAlign: "center",
            }}
          >
            {item.message}
          </span>
          {!template.sharedBy && (
            <IconButton
              size="small"
              onClick={(event) => handleMenuOpen(event, item)}
            >
              <MoreHorizIcon />
            </IconButton>
          )}
        </div>
      );
    let messageLines;
    if (
      item.mediaAttachment &&
      item.mediaAttachmentType !== "image" &&
      item.mediaAttachmentType !== "video" &&
      item.mediaAttachmentType !== "audio"
    ) {
      const viewFile = () =>
        viewMedia(
          item.mediaAttachment,
          item.mediaAttachmentType,
          template.userId
        );
      messageLines = (
        <div>
          <div className={classes.pdfMessageWrapper}>
            <img
              className={classes.photoMessage}
              onClick={viewFile}
              src={`${process.env.REACT_APP_STATIC_URL}/${template.userId}/${item.mediaAttachment}.jpg`}
            />
            <div className={classes.pdfTitleWrapper}>
              <Typography className={classes.pdfTitle}>
                <PdfIcon style={{ marginBottom: -6 }} />
                &nbsp;{item.message}
              </Typography>
            </div>
          </div>
          <Linkify
            componentDecorator={(href, text, key) => (
              <a href={href} key={key} target="_blank">
                {text}
              </a>
            )}
          >
            <div className={classes.mediaDescription} style={{ marginTop: 10 }}>
              {!!item.description && insertFormatting(item.description)}
            </div>
          </Linkify>
        </div>
      );
    }
    if (item.mediaAttachmentType === "video") {
      const viewVideo = () =>
        viewMedia(
          item.mediaAttachment,
          item.mediaAttachmentType,
          template.userId
        );
      messageLines = (
        <div>
          <div className={classes.videoMessageWrapper}>
            <img
              className={classes.photoMessage}
              src={`${process.env.REACT_APP_STATIC_URL}/${template.userId}/${item.mediaAttachment}.0000001.jpg`}
              onClick={viewVideo}
              alt=""
            />
            <PlayIcon className={classes.videoMessagePlay} />
          </div>
          <div className={classes.mediaDescription}>
            {!!item.description && insertFormatting(item.description)}
          </div>
        </div>
      );
    }
    if (item.messageType === "text" && !item.mediaAttachment) {
      var msg = item.message;
      msg = insertFormatting(msg);
      messageLines = (
        <Linkify
          componentDecorator={(href, text, key) => (
            <a href={href} key={key} target="_blank">
              {text}
            </a>
          )}
        >
          <div style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
            {deleted ? t("party.deletedPlaceholder") : msg}
          </div>
        </Linkify>
      );
    }
    if (item.messageType === "voice" || item.mediaAttachmentType === "audio")
      messageLines = (
        <div className={classes.voiceMessage}>
          <audio controls>
            <source
              src={
                item.messageType === "voice"
                  ? `${process.env.REACT_APP_STATIC_URL}/${template.userId}/${item.message}`
                  : `${process.env.REACT_APP_STATIC_URL}/${template.userId}/${item.mediaAttachment}`
              }
              type="audio/mpeg"
            />
          </audio>
        </div>
      );
    if (item.messageType === "photo" || item.mediaAttachmentType === "image") {
      const viewPhoto = () => {
        let i = photos.findIndex((p) => p.id === item.id);
        if (i !== -1) {
          setPhotoIndex(i);
          setLightboxOpen(true);
        }
      };
      messageLines = (
        <div>
          <div style={{ display: "table", margin: "0 auto" }}>
            <img
              className={classes.photoMessage}
              src={
                item.messageType === "photo"
                  ? `${process.env.REACT_APP_STATIC_URL}/${template.userId}/${item.message}`
                  : `${process.env.REACT_APP_STATIC_URL}/${template.userId}/${item.mediaAttachment}`
              }
              onClick={viewPhoto}
            />
          </div>
          <Linkify
            componentDecorator={(href, text, key) => (
              <a href={href} key={key} target="_blank">
                {text}
              </a>
            )}
          >
            <div className={classes.mediaDescription}>
              {!!item.description && insertFormatting(item.description)}
            </div>
          </Linkify>
        </div>
      );
    }

    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        className={classes.messageWrapper}
        id={item.id}
      >
        {selectMode && (
          <div className={classes.messageCheckbox}>
            <Checkbox
              checked={selectedIds.includes(item.id)}
              onChange={() => onMessageSelect(item)}
            />
          </div>
        )}
        <Card
          elevation={0}
          className={clsx(classes.chatMessage, {
            [classes.chatMessageMoving]: moving && item.id === messageToMove,
          })}
        >
          {!template.sharedBy && (
            <IconButton
              size="small"
              {...provided.dragHandleProps}
              style={{ marginTop: -10 }}
            >
              <DragHandle />
            </IconButton>
          )}
          {messageLines}
          <div className={classes.grow} />
          {!template.sharedBy && (
            <IconButton
              size="small"
              style={{ marginTop: -10 }}
              onClick={(event) => handleMenuOpen(event, item)}
            >
              <MoreHorizIcon />
            </IconButton>
          )}
        </Card>
      </div>
    );
  };

  const scrollToMessage = (messageId) => {
    const doScroll = () => {
      const messageItem = document.getElementById(messageId);
      const messagePosition =
        messageItem.offsetTop - messageList.current.offsetTop;
      messageList.current.scrollTo({
        top: messagePosition,
        behaviour: "smooth",
      });
    };
    doScroll();
  };

  const renderToc = (item) => {
    return (
      <div
        className={clsx([classes.tocHeading, classes[item.messageType]])}
        key={item.id}
      >
        <ChevronRightIcon />
        <a className={classes.tocLink} onClick={() => scrollToMessage(item.id)}>
          {item.message}
        </a>
      </div>
    );
  };

  if (!template) return <Redirect to="/templates" />;

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Container
        className={clsx(classes.screen, { [classes.screenHovered]: hovered })}
        onDragEnter={() => setHovered(true)}
      >
        <CssBaseline />
        {hovered && (
          <div
            {...getRootProps({
              className: clsx("dropzone", classes.dropzone, {
                [classes.dropzoneReject]: isDragReject,
              }),
              onDragLeave: () => setHovered(false),
            })}
          >
            <UploadIcon />
            <span>{t("general.uploadDrop")}</span>
            <input {...getInputProps()} />
          </div>
        )}
        <AppBar />
        {!moving && !selectMode && (
          <ActionBar
            title={template.title}
            titleAlwaysVisible={true}
            actions={[
              {
                id: "rename",
                label: isMobile ? null : t("templates.renameLabel"),
                icon: <EditIcon />,
                action: handleEdit,
              },
              {
                id: "delete",
                label: isMobile ? null : t("templates.deleteLabel"),
                icon: <DeleteIcon />,
                action: handleDelete,
                style: "destructive",
              },
              {
                id: "bulk-operations",
                icon: <MoreVertIcon />,
                action: (event) => handleGlobalChatMenuOpen(event),
                style: "inherit",
              },
            ].slice(template.sharedBy ? 1 : 0)}
            templateDetail={true}
          />
        )}
        {moving && (
          <ActionBar
            title={template.title}
            titleAlwaysVisible={true}
            actions={[
              {
                id: "cancel",
                label: isMobile ? null : t("general.cancel"),
                icon: <ClearIcon />,
                action: finishMoveMessage,
              },
            ]}
            templateDetail={true}
          />
        )}
        {bulkDelete && (
          <ActionBar
            title={template.title}
            titleAlwaysVisible={true}
            actions={[
              {
                id: "delete",
                label: isMobile ? null : t("templates.deleteLabel"),
                disabled: selectedIds.length === 0 ? true : false,
                icon: <DeleteIcon />,
                action: showRemoveMessageDialog,
                style: "destructive",
              },
              {
                id: "cancel",
                label: isMobile ? null : t("general.cancel"),
                icon: <ClearIcon />,
                action: finishMoveMessage,
              },
            ]}
            templateDetail={true}
          />
        )}
        {bulkForward && (
          <ActionBar
            title={template.title}
            titleAlwaysVisible={true}
            actions={[
              {
                id: "forward",
                disabled: selectedIds.length === 0 ? true : false,
                label: isMobile ? null : t("general.forward"),
                icon: <EditIcon />,
                action: showForwardMessagesDialog,
              },
              {
                id: "cancel",
                label: isMobile ? null : t("general.cancel"),
                icon: <ClearIcon />,
                action: finishMoveMessage,
              },
            ]}
            templateDetail={true}
          />
        )}
        <div style={{ display: "flex", width: "100vw" }}>
          <div className={classes.contentWrapper}>
            <div className={classes.chatView} ref={messageList}>
              <Droppable droppableId={templateId}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={classes.allMessages}
                  >
                    <div
                      className={clsx({
                        [classes.addBeforeActive]: !moving && !!addBefore,
                      })}
                      pointerEvents="none"
                      onClick={() => setAddBefore("")}
                    />
                    {messages && //MESSAGE INPUT
                      messages.map((message, index) => (
                        <div key={index.toString()}>
                          {moving &&
                            messages[index - 1]?.id !== messageToMove &&
                            message.id !== messageToMove && (
                              <div
                                className={classes.messageInsert}
                                onClick={() => moveMessage(message, false)}
                              >
                                <ArrowRightAltIcon />
                              </div>
                            )}
                          <div
                            className={clsx(classes.addInBetween, {
                              [classes.addInBetweenHover]:
                                !moving && !addBefore,
                              [classes.addInBetweenInactive]:
                                !moving &&
                                addBefore &&
                                addBefore !== message.id,
                              [classes.addInBetweenActive]:
                                !moving && addBefore === message.id,
                            })}
                            onClick={
                              moving
                                ? undefined
                                : () =>
                                    setAddBefore((addBefore) =>
                                      addBefore ? "" : message.id
                                    )
                            }
                          >
                            <div className={classes.addInBetweenButton}>
                              <AddIcon fontSize="small" />
                            </div>
                            <div className={classes.addInBetweenLine} />
                          </div>
                          <Draggable
                            key={message.id}
                            draggableId={
                              message.id ? message.id : message.message
                            }
                            index={index}
                            id={message.id}
                          >
                            {(provided, snapshot) =>
                              renderMessage(message, provided, snapshot)
                            }
                          </Draggable>
                          {moving &&
                            message.id !== messageToMove &&
                            index === messages.length - 1 && (
                              <div
                                className={classes.messageInsert}
                                onClick={() => moveMessage(message, true)}
                              >
                                <ArrowRightAltIcon />
                              </div>
                            )}
                        </div>
                      ))}
                  </div>
                )}
              </Droppable>
            </div>
            {showEmojis && (
              <div className={classes.emojiKeyboard}>
                <EmojiPicker
                  onEmojiClick={onEmojiClick}
                  groupNames={{
                    smileys_people: t("emojis.smileys_people"),
                    animals_nature: t("emojis.animals_nature"),
                    food_drink: t("emojis.food_drink"),
                    travel_places: t("emojis.travel_places"),
                    activities: t("emojis.activities"),
                    objects: t("emojis.objects"),
                    symbols: t("emojis.symbols"),
                    flags: t("emojis.flags"),
                    recently_used: t("emojis.recently_used"),
                  }}
                />
              </div>
            )}
            {!template.sharedBy && !moving && !bulkDelete && !bulkForward && (
              <ChatBar
                isManager={true}
                sending={sending}
                expired={false}
                editing={editing}
                autoFocus
                showEmojiKeyboard={showEmojiKeyboard}
                hideEmojiKeyboard={hideEmojiKeyboard}
                showMediaDialog={showMediaDialog}
                showHeadingDialog={showHeadingDialog}
                selectFiles={selectFiles}
                showVoiceDialog={showVoiceDialog}
                showTemplateImportDialog={showTemplateImportDialog}
                onEnter={handleMessageSubmit}
                onSendText={sendTextMessage}
                onFinishEditing={finishEditing}
                onCancelEditing={cancelEditing}
                ref={inputRef}
              />
            )}
          </div>
          <div className={classes.toc}>
            <div className={classes.cardTitle}>{t("party.toc")}</div>
            <div className={classes.cardContent}>{headings.map(renderToc)}</div>
          </div>
        </div>
        <UIMenu
          anchorEl={globalAnchorEl}
          keepMounted={true}
          open={globalChatMenuOpen}
          onClose={handleGlobalChatMenuClose}
        >
          <MenuItem onClick={handleBulkDelete}>
            {t("party.massDeleteMessageLabel")}
          </MenuItem>
          <MenuItem onClick={handleBulkForward}>
            {t("party.massForwardMessageLabel")}
          </MenuItem>
          {template.usesRank && (
            <MenuItem onClick={handleBulkMove}>
              {t("templates.moveMessages")}
            </MenuItem>
          )}
        </UIMenu>
        <UIMenu
          anchorEl={anchorEl}
          keepMounted={true}
          open={menuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={showRemoveMessageDialog}>
            {t("party.deleteMessageLabel")}
          </MenuItem>
          <MenuItem onClick={startMoveMessage}>
            {t("templates.moveMessageLabel")}
          </MenuItem>
          {selectedMessage.mediaAttachmentType !== "audio" &&
            selectedMessage.messageType !== "voice" && (
              <MenuItem onClick={startEditMessage}>
                {t("party.editMessageLabel")}
              </MenuItem>
            )}
        </UIMenu>
        <TemplateDialog ref={templateDialog} onSubmit={submitTemplateDialog} />
        <DeleteDialog
          ref={deleteDialog}
          type="template"
          onSubmit={submitDeleteDialog}
        />
        <TemplateImportDialog
          ref={templateImportDialog}
          templates={templates}
          templateId={templateId}
          setImportLoading={setImportLoading}
          setSending={setSending}
          setShowUpload={setShowUpload}
          setUploadDescription={setUploadDescription}
          scrollToBottom={scrollToBottom}
          beforeMessageId={addBefore}
        />
        {importLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: 2000,
            }}
          >
            <CircularProgress size={100} className={classes.importLoading} />
          </div>
        )}
        <VoiceDialog ref={voiceDialog} onSubmit={submitVoiceDialog} />
        <PhotoDialog
          ref={photoDialog}
          onSubmit={submitPhotoDialogChoice}
          onCancel={cancelPhotoPreview}
          selectedFiles={selectedFiles}
          libraryEnabled={true}
        />
        <RemoveMessageDialog
          ref={removeMessageDialog}
          onSubmit={submitRemoveMessageDialog}
          bulk={bulkDelete}
        />
        <ForwardMessagesDialog
          ref={forwardMessagesDialog}
          onMoveToLibrary={submitMoveToLibrary}
          onMoveToTemplate={submitMoveToTemplate}
          onMoveToChat={submitMoveToChat}
          isMedia={mediaSelected}
        />
        <MoveToDialog ref={copyToDialog} onSubmit={submitCopyToDialog} />
        <MoveToTemplateDialog
          ref={copyToTemplateDialog}
          onSubmit={submitCopyToTemplateDialog}
        />
        <MoveToChatDialog
          ref={copyToChatDialog}
          onSubmit={submitCopyToChatDialog}
        />
        <HeadingDialog ref={headingDialog} onSubmit={submitHeadingDialog} />
        {Array.isArray(sendingMedia) && sendingMedia.length > 0 && (
          <MediaPreview
            submitMediaDialog={submitMediaDialog}
            sendingMedia={sendingMedia}
            setSendingMedia={setSendingMedia}
            userId={session.userId}
          />
        )}
        <MediaDialog
          ref={mediaDialog}
          onSubmit={handleMediaDescriptions}
          media={media}
          userId={!!session ? session.userId : null}
        />
        {lightboxOpen && (
          <PhotoLightbox
            mainSrc={getPhotoSource(photoIndex)}
            nextSrc={getPhotoSource((photoIndex + 1) % photos.length)}
            prevSrc={getPhotoSource(
              (photoIndex + photos.length - 1) % photos.length
            )}
            onCloseRequest={() => setLightboxOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % photos.length)
            }
            reactModalStyle={{
              overlay: {
                zIndex: 1500,
              },
            }}
          />
        )}
        <Lightbox
          open={videoOpen}
          userId={session.userId}
          media={selectedVideo}
          onClose={handleVideoClose}
        />
        <Snackbar
          open={showUpload}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert severity={uploadProgress === 100 ? "success" : "info"}>
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              color="primary"
              className={classes.uploadProgress}
            />
            <Typography>{uploadDescription}</Typography>
          </Alert>
        </Snackbar>
      </Container>
    </DragDropContext>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    maxWidth: "100%",
    padding: 0,
  },
  screenHovered: {
    ["& *"]: {
      pointerEvents: "none",
    },
  },
  contentWrapper: {
    backgroundColor: "white",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: 147,
    height: (props) => props.windowHeight - 147,
    maxWidth: "100vw",
    [theme.breakpoints.up("sm")]: {
      marginTop: 155,
      height: (props) => props.windowHeight - 155,
    },
  },
  chatView: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    overflow: "auto",
    scrollBehavior: "smooth",
    padding: 10,
  },
  allMessages: {
    display: "block",
    paddingBottom: 10,
  },
  messageWrapper: {
    display: "flex",
  },
  headingMessage: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    paddingTop: 30,
    paddingBottom: 10,
    flexShrink: 0,
    fontWeight: "bold",
    backgroundColor: "white",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  },
  heading: {
    fontSize: "1.5em",
    borderColor: "rgba(160,160,160,0.6)",
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 0.5,
  },
  heading2: {
    fontSize: "1.4em",
  },
  heading3: {
    fontWeight: "500",
    fontSize: "1.3em",
    paddingTop: 20,
  },
  chatMessage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 10,
    padding: 5,
    paddingLeft: (props) => (props.isShared ? 12 : 2),
    paddingRight: 2,
    paddingTop: 15,
    minWidth: "20%",
    backgroundColor: "rgb(240,240,240)",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  },
  chatMessageMoving: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  voiceMessage: {
    maxWidth: (props) => props.windowWidth - 93,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "italic",
    paddingRight: 12,
    paddingTop: 5,
    paddingBottom: 5,
  },
  photoMessage: {
    maxWidth: "calc(100% + 10px)",
    maxHeight: 400,
    margin: -5,
    marginTop: 5,
    backgroundColor: "white",
    borderRadius: 3,
    cursor: "pointer",
  },
  pdfMessageWrapper: {
    display: "table",
    margin: "0 auto",
    width: "fit-content",
    flexDirection: "column",
    cursor: "pointer",
    paddingBottom: 5,
  },
  pdfTitleWrapper: {
    height: 50,
    margin: -5,
    marginBottom: -45,
    transform: "translateY(-46px)",
    backgroundColor: "rgba(0,0,0,0.5)",
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    width: "103.5%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
  },
  pdfTitle: {
    flex: 1,
    color: "white",
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  mediaDescription: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  messageInsert: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "rgb(240,240,240)",
    borderRadius: 4,
    borderStyle: "dashed",
    borderWidth: 2,
    borderColor: "rgba(160, 160, 160, 0.6)",
    color: "rgb(160, 160, 160)",
    height: 30,
    maxWidth: 700,
    marginTop: 5,
    marginBottom: -5,
    paddingLeft: 5,
    cursor: "pointer",
  },
  videoMessageWrapper: {
    display: "table",
    margin: "0 auto",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    paddingBottom: 5,
  },
  videoMessagePlay: {
    fontSize: 60,
    transform: "translateY(-50%)",
    [theme.breakpoints.up("sm")]: {
      transform: "translateY(-250%)",
    },
    marginLeft: "calc(-30px - 50%)",
    marginRight: -60,
    pointerEvents: "none",
    color: "white",
  },
  emojiKeyboard: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: -320 - theme.spacing(1),
    zIndex: 1500,
  },
  grow: {
    flexGrow: 1,
    minWidth: 10,
  },
  uploadProgress: {
    width: 300,
    height: 20,
    borderRadius: 5,
  },
  dropzone: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "dashed",
    alignItems: "center",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    opacity: "80%",
    zIndex: 10000,
    pointerEvents: "auto",
  },
  dropzoneReject: {
    backgroundColor: "rgb(255, 180, 180)",
    borderColor: "red",
  },
  importLoading: {
    position: "absolute",
    top: (props) => props.windowHeight / 2 - 50,
    left: (props) => props.windowWidth / 2 - 50,
    justifySelf: "center",
    alignSelf: "center",
    size: 100,
  },
  addInBetween: {
    position: "relative",
    zIndex: 1000,
    height: 20,
    marginTop: -2,
    marginBottom: -12,
    marginLeft: -5,
    display: "flex",
    alignItems: "center",
    opacity: 0,
    "-webkit-transition": "opacity 0.2s ease-in-out",
    "-moz-transition": "opacity 0.2s ease-in-out",
    "-ms-transition": "opacity 0.2s ease-in-out",
    "-o-transition": "opacity 0.2s ease-in-out",
    transition: "opacity 0.2s ease-in-out",
  },
  addInBetweenHover: {
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
  },
  addInBetweenInactive: {
    cursor: "pointer",
  },
  addInBetweenActive: {
    cursor: "pointer",
    opacity: 1,
  },
  addInBetweenButton: {
    backgroundColor: "#1E92CD",
    color: "white",
    height: 20,
    borderRadius: 3,
  },
  addInBetweenLine: {
    flex: 1,
    height: 2,
    backgroundColor: "#1E92CD",
  },
  addBeforeActive: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 70,
    backgroundColor: "rgba(0,0,0,0.4)",
    zIndex: 900,
    "-webkit-transition": "all 0.2s ease-in-out",
    "-moz-transition": "all 0.2s ease-in-out",
    "-ms-transition": "all 0.2s ease-in-out",
    "-o-transition": "all 0.2s ease-in-out",
    transition: "all 0.2s ease-in-out",
    cursor: "pointer",
  },
  /* Table of Contents */
  tocHeading: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    paddingLeft: 15,
  },
  heading: {
    maxWidth: "calc(100% - 15px)",
    fontWeight: "bold",
  },
  heading2: {
    maxWidth: "calc(100% - 30px)",
    marginLeft: 15,
    fontWeight: "bold",
    fontSize: "1.1em",
  },
  heading3: {
    maxWidth: "calc(100% - 45px)",
    marginLeft: 30,
    fontWeight: "500",
    fontSize: "1em",
  },
  tocLink: {
    flex: 1,
    textDecoration: "none",
    color: theme.palette.text.primary,
    paddingTop: 12,
    paddingBottom: 9,
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "1.2em",
    fontFamily: "Roboto, sans-serif",
  },
  toc: {
    display: "none",
    flexDirection: "column",
    borderLeft: "1px solid #D8D8D8",
    flex: 1,
    maxWidth: "calc(33.33vw - 20px)",
    marginBottom: 20,
    marginTop: 147,
    height: (props) => props.windowHeight - 147,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      marginTop: 155,
      height: (props) => props.windowHeight - 155,
    },
  },
  cardTitle: {
    height: 50,
    paddingLeft: 20,
    display: "flex",
    fontWeight: "bold",
    fontFamily: "Roboto, sans-serif",
    borderBottom: "1px solid #D8D8D8",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  cardContent: {
    flex: 1,
    overflow: "auto",
  },
}));

export default Templates;
