import {
  SELECT_PROFILE,
  SWITCH_COMPANY_MODE,
  SET_CALLBACK_LOCATION,
} from "../types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_PROFILE: {
      const { userId, profileId } = action.payload;
      const newState = {
        ...state,
        [userId]: {
          ...state[userId],
          selectedProfile: profileId,
        },
      };
      return newState;
    }

    case SWITCH_COMPANY_MODE: {
      const { userId, companyMode } = action.payload;
      const newState = {
        ...state,
        [userId]: {
          ...state[userId],
          companyMode,
        },
      };
      return newState;
    }

    case SET_CALLBACK_LOCATION: {
      const location = action.payload;
      const newState = {
        ...state,
        callbackLocation: location,
      };
      return newState;
    }

    default:
      return state;
  }
};
