import React from "react";
import { useActions } from "actions";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";

const TemplateMessages = ({
  socket,
  sending,
  inputRef,
  renderReply,
  partyId,
  userId,
}) => {
  const classes = useStyles();

  const {
    CloseTemplate,
    ChangeTemplatePostion,
    RemoveAppliedTemplate,
    HideTemplateMessage,
    EditTemplateMessage,
    SendMessage,
  } = useActions();

  const appliedTemplate = useSelector(
    (state) => state.appliedTemplates?.[partyId] || {}
  );
  const session = useSelector((state) => state.session);
  const me = useSelector((state) => state.me[partyId] || {});
  const templateMessages = useSelector((state) => {
    const appliedTemplate = state.appliedTemplates?.[partyId] || {};
    if (!appliedTemplate) return [];
    const messages = state.templateMessages[appliedTemplate.templateId] || [];
    if (!messages) return [];
    return (
      messages.filter(
        (m) => !appliedTemplate.hiddenMessages.find((id) => id === m.id)
      ) || []
    );
  });

  const incrementIndex = () => {
    if (appliedTemplate?.position < templateMessages.length)
      ChangeTemplatePostion(partyId, appliedTemplate.position + 1);
  };
  const decrementIndex = () => {
    if (appliedTemplate?.position > 0)
      ChangeTemplatePostion(partyId, appliedTemplate.position - 1);
  };

  const closeAppliedTemplate = async () => {
    CloseTemplate(partyId);
  };

  const handleTemplateMessage = async (msg) => {
    if (templateMessages.length === 1) {
      RemoveAppliedTemplate(partyId);
    } else {
      if (appliedTemplate?.position === templateMessages?.length - 1)
        decrementIndex();
      HideTemplateMessage(partyId, msg.id);
    }
    if (!msg?.messageType) return;

    const guestId = session?.userId === userId ? null : me.guestId;
    const fromTemplate = !(
      msg.messageType === "text" && !msg.mediaAttachmentType
    );
    const messageType = msg.messageType.startsWith("heading")
      ? msg.messageType
      : "text";
    const message =
      msg.messageType === "photo" || msg.messageType === "voice"
        ? ""
        : msg.message;

    let mediaAttachment = null;
    let mediaAttachmentType = null;
    if (msg.messageType === "photo" || msg.messageType === "voice")
      mediaAttachment = msg.message;
    if (msg.messageType === "photo") mediaAttachmentType = "image";
    if (msg.messageType === "voice") mediaAttachmentType = "voice";
    if (msg.messageType === "text" && msg.mediaAttachmentType) {
      mediaAttachment = msg.mediaAttachment;
      mediaAttachmentType = msg.mediaAttachmentType;
    }

    let mediaExtension = null;
    let contentType = null;
    if (msg.messageType === "text" && msg.mediaAttachmentType) {
      mediaExtension = msg.mediaExtension;
      contentType = undefined;
    }

    let { response, error } = await SendMessage(
      guestId,
      partyId,
      fromTemplate,
      undefined,
      me.guestId,
      messageType,
      message,
      mediaAttachment,
      mediaAttachmentType,
      mediaExtension,
      contentType,
      msg.sender,
      msg.description
    );

    if (error) return;
    socket.emit("sendMessage", {
      partyId: partyId,
      message: { messageInfo: response.data, senderName: me.firstName },
    });
  };

  const startEditTemplateMessage = async (msg) => {
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.setMessage(msg.message);
    }, 100);
    EditTemplateMessage(appliedTemplate.id, msg);
    if (appliedTemplate.position > templateMessages.length - 1)
      ChangeTemplatePostion(0);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.templateTogglerWrapper}>
        <div className={classes.templateTogglerAfter} />
        <div className={classes.templateClose}>
          <IconButton
            size="small"
            onClick={closeAppliedTemplate}
            className={classes.templateCloseButton}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.reply}>
        <IconButton
          onClick={decrementIndex}
          disabled={sending || appliedTemplate.position === 0}
          size="large">
          <SkipPreviousIcon />
        </IconButton>
        {!!templateMessages[appliedTemplate.position] &&
          renderReply(templateMessages[appliedTemplate.position], true, true)}
        {templateMessages[appliedTemplate.position]?.messageType === "text" &&
          !templateMessages[appliedTemplate.position].mediaAttachment && (
            <IconButton
              onClick={() =>
                startEditTemplateMessage(
                  templateMessages[appliedTemplate.position]
                )
              }
              disabled={sending}
              size="large">
              <EditIcon />
            </IconButton>
          )}
        <IconButton
          onClick={() =>
            handleTemplateMessage(templateMessages[appliedTemplate.position])
          }
          disabled={sending}
          size="large">
          <CheckIcon />
        </IconButton>
        <IconButton
          onClick={incrementIndex}
          disabled={
            sending || appliedTemplate?.position === templateMessages.length - 1
          }
          size="large">
          <SkipNextIcon />
        </IconButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  templateTogglerWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: -20,
  },
  templateTogglerAfter: {
    border: "solid",
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: "rgba(160, 160, 160, 0.6)",
    flex: 1,
  },
  templateToggler: {
    border: "solid",
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: "rgba(160, 160, 160, 0.6)",
    width: 60,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    display: "flex",
    justifyContent: "center",
    zIndex: 500,
    backgroundColor: "white",
  },
  templateClose: {
    border: "solid",
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderColor: "rgba(160, 160, 160, 0.6)",
    width: 40,
    borderTopLeftRadius: 5,
    display: "flex",
    justifyContent: "center",
    zIndex: 500,
    backgroundColor: "white",
  },
  templateCloseButton: {
    height: 20,
    width: 40,
    borderRadius: 5,
  },
  wrapper: {
    flexDirection: "row",
    borderColor: "black",
    borderWidth: 1,
  },
  reply: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    paddingLeft: 5,
  },
}));

export default TemplateMessages;
