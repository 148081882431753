import React from "react";
import clsx from "clsx";
import {
  Drawer,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

const Menu = (props) => {
  const classes = useStyles();
  const { open, items = [], banner } = props;

  const [updated, setUpdated] = React.useState(true);

  const { t } = useTranslation();
  const releaseNotes = useSelector((state) => state.releaseNotes);

  React.useEffect(() => {
    setUpdated(
      !releaseNotes.lastViewedVersion
        ? true
        : releaseNotes.lastViewedVersion < process.env.REACT_APP_VERSION
    );
  }, []);

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={banner ? classes.toolbarBanner : classes.toolbar}>
        <img
          src={require("assets/logo-prowin-messenger.svg")}
          className={classes.logo}
        />
        <IconButton onClick={props.onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {items.map((item) => (
          <ListItem
            button
            key={item.id}
            selected={props.selected === item.id}
            component={Link}
            to={item.link}
            {...((item.id === "media" || item.id === "templates") &&
              props.setPath && { onClick: () => props.setPath([]) })}
          >
            <ListItemIcon>
              {item.id === "releaseNotes" && updated ? (
                <NewReleasesIcon color="primary" />
              ) : (
                item.icon
              )}
            </ListItemIcon>
            <ListItemText primary={t(item.displayName)} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  iconGroup: {
    height: "100%",
    margin: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  toolbarBanner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    marginTop: 20,
  },
  logo: {
    width: "8em",
  },
}));

export default Menu;
