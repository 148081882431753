import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/nl";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const PartyDialog = (props, ref) => {
  const classes = useStyles();

  const profileState = useSelector((state) => state.profile);
  const [profiles, setProfiles] = React.useState([]);
  React.useEffect(() => {
    setProfiles(profileState.profiles || []);
  }, [profileState]);

  const { onSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const [mode, setMode] = React.useState("create");
  const [title, setTitle] = React.useState("");
  const [expiration, setExpiration] = React.useState(new Date());
  const [host, setHost] = React.useState("");
  const [start, setStart] = React.useState(new Date());
  const [manager, setManager] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [restrictedMessaging, setRestrictedMessaging] = React.useState(false);

  const clearStart = (e) => {
    e.stopPropagation();
    setStart(null);
  };
  const clearExpiration = (e) => {
    e.stopPropagation();
    setExpiration(null);
  };

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    const shortLang = i18n.language.split("-")[0];
    moment.locale(shortLang === "en" ? "en-gb" : shortLang);
  }, [i18n.language]);

  React.useImperativeHandle(ref, () => ({
    open: (
      title,
      expiration,
      start,
      host,
      manager,
      password,
      restrictedMessaging
    ) => {
      setOpen(true);
      setMode(title ? "edit" : "create");
      setTitle(title || "");
      setExpiration(
        expiration
          ? moment(expiration).toDate()
          : expiration === null
          ? null
          : moment().add(3, "days").toDate()
      );
      setStart(start ? moment(start).toDate() : null);
      setHost(host || "");
      setManager(manager);
      setPassword(password);
      setRestrictedMessaging(restrictedMessaging || false);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit(
      title,
      start,
      expiration,
      host,
      manager,
      password,
      restrictedMessaging
    );
  };

  return (
    <Dialog open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {mode === "create"
            ? t("parties.createTitle")
            : t("parties.editTitle")}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t("parties.partyType")}
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={classes.field}
            required
            placeholder={t("parties.partyTypePlaceholder")}
          />
          <DesktopDatePicker
            label={t("parties.startDate")}
            inputFormat={t("general.datePickerFormat")}
            value={start}
            onChange={setStart}
            renderInput={(params) => (
              <>
                <TextField className={classes.field} {...params} />
                <IconButton onClick={clearStart} size="small">
                  <ClearIcon />
                </IconButton>
              </>
            )}
          />
          <DesktopDatePicker
            label={t("parties.expirationDate")}
            inputFormat={t("general.datePickerFormat")}
            value={expiration}
            onChange={setExpiration}
            renderInput={(params) => (
              <>
                <TextField className={classes.field} {...params} />
                <IconButton onClick={clearExpiration} size="small">
                  <ClearIcon />
                </IconButton>
              </>
            )}
          />
          <TextField
            margin="dense"
            label={t("parties.hostName")}
            type="text"
            fullWidth
            value={host}
            onChange={(e) => setHost(e.target.value)}
            className={classes.field}
          />
          {profiles.length > 1 && (
            <FormControl className={classes.field} fullWidth>
              <InputLabel>{t("parties.manager")}</InputLabel>
              <Select
                required
                value={manager}
                onChange={(e) => setManager(e.target.value)}
              >
                <MenuItem value="">-</MenuItem>
                {profiles.map((p) => (
                  <MenuItem
                    key={p.id}
                    value={p.id}
                  >{`${p.firstName} ${p.lastName}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            margin="dense"
            label={t("parties.password")}
            type="text"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={classes.field}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={restrictedMessaging}
                onChange={(e) =>
                  setRestrictedMessaging(
                    restrictedMessaging === true ? false : true
                  )
                }
                size="medium"
                name="checkedB"
              />
            }
            label={t("parties.restrictedMessaging")}
          />
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("general.cancel")}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {mode === "create" ? t("general.create") : t("general.save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  field: {
    marginBottom: theme.spacing(2),
  },
  spacer: {
    height: theme.spacing(2),
  },
}));

export default React.forwardRef(PartyDialog);
