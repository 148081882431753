import {
  START_TEMPLATE,
  LOGOUT,
  CONTINUE_TEMPLATE,
  CHANGE_TEMPLATE_POSITION,
  HIDE_TEMPLATE_MESSAGE,
  REMOVE_APPLIED_TEMPLATE,
  CLOSE_TEMPLATE,
} from "../types";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case START_TEMPLATE: {
      const { partyId, templateId } = action.payload;
      return {
        ...state,
        [partyId]: {
          templateId,
          open: true,
          position: 0,
          hiddenMessages: [],
        },
      };
    }
    case CLOSE_TEMPLATE: {
      const { partyId } = action.payload;
      return {
        ...state,
        [partyId]: {
          ...(state[partyId] || {}),
          open: false,
        },
      };
    }
    case CONTINUE_TEMPLATE: {
      const { partyId, templateId } = action.payload;
      if (state[partyId]?.templateId !== templateId) return state;
      return {
        ...state,
        [partyId]: {
          ...(state[partyId] || {}),
          open: true,
        },
      };
    }

    case CHANGE_TEMPLATE_POSITION: {
      const { partyId, position } = action.payload;
      return {
        ...state,
        [partyId]: {
          ...(state[partyId] || {}),
          position,
        },
      };
    }

    case HIDE_TEMPLATE_MESSAGE: {
      const { partyId, messageId } = action.payload;
      return {
        ...state,
        [partyId]: {
          ...(state[partyId] || {}),
          hiddenMessages: [
            ...(state[partyId]?.hiddenMessages || []),
            messageId,
          ],
        },
      };
    }
    case REMOVE_APPLIED_TEMPLATE: {
      const { partyId } = action.payload;
      return {
        ...state,
        [partyId]: null,
      };
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
