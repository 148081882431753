import {
  GET_TEMPLATES,
  GET_TEMPLATE,
  EDIT_TEMPLATE,
  DELETE_TEMPLATE,
  LOGOUT,
} from "../types";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATES: {
      return action.payload;
    }

    case GET_TEMPLATE: {
      const template = action.payload;
      const index = state.findIndex((t) => t.id === template.id);
      if (index === -1) return [...state, template];
      const sharedBy = state[index].sharedBy;
      state[index] = template;
      state[index].sharedBy = sharedBy;
      return [...state];
    }

    case EDIT_TEMPLATE: {
      const { templateId, name } = action.payload;
      const template = state.find((t) => t.id === templateId);
      if (!template) return state;
      return [
        ...state.filter((t) => t.id !== template.id),
        {
          ...template,
          title: name,
        },
      ];
    }

    case DELETE_TEMPLATE: {
      const { templateId } = action.payload;
      return state.filter((t) => t.id !== templateId);
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
