import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Recorder } from "vmsg";
import { useTranslation } from "react-i18next";

const VoiceDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit } = props;
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState("waiting");
  const [mediaRecorder, setMediaRecorder] = React.useState();

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: async () => {
      setOpen(true);
      setState("waiting");
      try {
        const mediaRecorder = new Recorder({
          wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm",
          shimURL: "https://unpkg.com/wasm-polyfill.js@0.2.0/wasm-polyfill.js",
        });
        setMediaRecorder(mediaRecorder);
        await mediaRecorder.initAudio();
        await mediaRecorder.initWorker();
        mediaRecorder.startRecording();
        setState("recording");
      } catch (e) {
        setMediaRecorder();
        setOpen(false);
        alert(t("party.noVoiceSupport"));
      }
    },
  }));

  const handleClose = () => {
    if (mediaRecorder) mediaRecorder.close();
    setMediaRecorder();
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mediaRecorder) {
      const audioBlob = await mediaRecorder.stopRecording();
      setTimeout(mediaRecorder.close, 500);
      onSubmit(audioBlob);
    }
    setMediaRecorder();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("party.sendVoiceTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {state === "waiting"
              ? t("party.sendVoiceWaiting")
              : t("party.sendVoiceRecording")}
          </DialogContentText>
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("general.cancel")}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {t("general.send")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
}));

export default React.forwardRef(VoiceDialog);
