import { Button, InputBase } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/styles";
import React from "react";
import { useActions } from "actions";
import { useTranslation } from "react-i18next";

const GuestAccount = (props) => {
  const { verificationCodeSent, guestEmail } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const [email, setEmail] = React.useState("");
  const [isValid, setIsValid] = React.useState(false);
  const [isCodeSent, setIsCodeSent] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const { GuestLoginWithEmail } = useActions();
  const { t, i18n } = useTranslation();
  const lang = i18n.language.substring(0, 2);

  const validateEmail = (email) => {
    return String(email).match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  React.useEffect(() => {
    const validEmail = validateEmail(email);
    if (validEmail) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [email]);

  const handleGuestLoginWithEmail = async (e) => {
    e.preventDefault();
    const { error } = await GuestLoginWithEmail(email, lang);
    if (error) {
      const errorArray = Object.keys(error).map((key) => [
        Number(key),
        error[key],
      ]);
      const errorMessage = (errorArray[1][1]?.response)
        .split(":")[1]
        .replace("}", "")
        .replace(/"/g, "");
      setErrorMessage(errorMessage);
    } else {
      setIsCodeSent(true);
      verificationCodeSent();
    }
  };

  const checkEmailInput = (e) => {
    if (e.target.value.length === 0) {
      setErrorMessage("");
      setIsValid(false);
    }
    setEmail(e.target.value);
    guestEmail(email);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("enter in email pressed");
    }
  };
  return (
    <>
      <div className={classes.inputSendCode}>
        <InputBase
          onKeyDown={handleKeyDown}
          placeholder="E-mail *"
          value={email}
          onChange={checkEmailInput}
          style={{
            fontSize: "13px",
            color: "#000",
          }}
        />
      </div>
      {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
      <div className={classes.buttonSendCode}>
        <Button
          style={{
            textTransform: "none",
            background: !isValid ? "#aaa" : theme.palette.primary.main,
            color: "#FFF",
            boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
            padding: "4px 20px 4px 20px",
            marginBottom: 20,
            fontSize: 15,
            fontFamily: theme.typography.h1.fontFamily,
          }}
          disabled={!isValid ? true : false}
          onClick={handleGuestLoginWithEmail}
        >
          {t("login.sendCode")}
        </Button>
      </div>
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  inputSendCode: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    width: 350,
    height: 30,
    margin: "10px 20px 5px 20px",
    padding: "8px 5px 5px 20px",
  },
  buttonSendCode: {
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    maxWidth: 360,
    fontFamily: theme.typography.h1.fontFamily,
  },
  errorMessage: {
    fontSize: 15,
    color: theme.palette.error.main,
    margin: "10px 0 0 25px",
    width: "100%",
    maxWidth: 350,
  },
}));

export default GuestAccount;
