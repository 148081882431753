import { combineReducers } from "redux";
import appliedTemplates from "./appliedTemplates";
import banner from "./banner";
import banners from "./banners";
import enteredTimestamp from "./enteredTimestamp";
import joinedParties from "./joinedParties";
import me from "./me";
import media from "./media";
import mediaTrash from "./mediaTrash";
import parties from "./parties";
import profile from "./profile";
import releaseNotes from "./releaseNotes";
import session from "./session";
import settings from "./settings";
import templateMessages from "./templateMessages";
import templateTrash from "./templateTrash";
import templates from "./templates";

export default combineReducers({
  session,
  profile,
  media,
  parties,
  joinedParties,
  me,
  templates,
  appliedTemplates,
  releaseNotes,
  settings,
  banner,
  templateMessages,
  enteredTimestamp,
  templateTrash,
  mediaTrash,
  banners,
});
