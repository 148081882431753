import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useActions } from "actions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const OverwriteTemplateDialog = ({ partyId }, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ContinueTemplate, GetTemplate, StartTemplate, ApplyTemplate } =
    useActions();

  const [open, setOpen] = React.useState(false);
  const [templateId, setTemplateId] = React.useState();

  React.useImperativeHandle(ref, () => ({
    open: (templateId) => {
      setOpen(true);
      setTemplateId(templateId);
    },
  }));

  const handleClose = () => {
    setOpen(false);
    cancelOverWriteTemplateDialog(templateId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    submitOverwriteTemplateDialog(templateId);
  };

  const submitOverwriteTemplateDialog = async (templateId) => {
    await GetTemplate(templateId);
    ContinueTemplate(partyId, templateId);
  };

  const cancelOverWriteTemplateDialog = async (templateId) => {
    await GetTemplate(templateId);
    StartTemplate(partyId, templateId);
    ApplyTemplate(templateId);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("party.overwriteTemplateDialogTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("party.overwriteTemplateDialog")}
          </DialogContentText>
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("general.restart")}
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {t("general.continue")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
}));

export default React.forwardRef(OverwriteTemplateDialog);
