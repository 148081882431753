import {
  Alert,
  Button,
  Container,
  CssBaseline,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useActions } from "actions";
import { ActionBar, AppBar, Menu } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

const AcceptShare = (props) => {
  const {
    AcceptShare,
    AcceptSharePreflight,
    GetTemplates,
    SharedTemplateAddToFolder,
  } = useActions();
  const { shareToken } = useParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [info, setInfo] = React.useState();
  const [error, setError] = React.useState();

  const init = async () => {
    let { response, error } = await AcceptSharePreflight(shareToken);
    if (error) setError(t("templates.invalidShareLink"));
    else setInfo(response.data);
  };

  React.useEffect(() => {
    init();
    document.title = t("templates.addTemplate") + " - proWIN Messenger";
  }, []);

  const submit = async () => {
    let { error } = await AcceptShare(shareToken);
    if (error) {
      setError(t("general.tryAgainLater"));
      return;
    }
    await GetTemplates();
    history.push(`/templates`);
  };
  const cancel = () => {
    history.push(`/templates`);
  };

  return (
    <Container className={classes.screen}>
      <CssBaseline />
      <AppBar open={menuOpen} onOpen={() => setMenuOpen(true)} />
      <ActionBar title={t("templates.addTemplate")} open={menuOpen} />
      <Menu
        items={props.menuItems}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        selected="templates"
      />
      {!!info && !error && (
        <Paper className={classes.card}>
          <Typography variant="h1">
            {info.title}
            <span
              style={{ fontWeight: "normal", opacity: 0.6, fontSize: "0.8em" }}
            >
              &nbsp;{t("templates.sharedBy")} {info.name}
            </span>
          </Typography>
          <Typography variant="body1" style={{ color: "#333", marginTop: 5 }}>
            {t("templates.acceptTemplateShare")}
          </Typography>
          <div className={classes.buttonWrapper}>
            <Button color="inherit" onClick={cancel}>
              {t("general.cancel")}
            </Button>
            <Button variant="contained" color="primary" onClick={submit}>
              {t("templates.addTemplate")}
            </Button>
          </div>
        </Paper>
      )}
      {!!error && <Alert severity="error">{error}</Alert>}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    maxWidth: "100%",
    marginTop: 130,
    [theme.breakpoints.down("sm")]: {
      marginTop: 120,
      paddingRight: 20,
    },
  },
  card: {
    padding: theme.spacing(2),
    maxWidth: 650,
  },
  buttonWrapper: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default AcceptShare;
