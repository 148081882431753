import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import guestVideo from "assets/guestVideo.mp4";
import phone from "assets/phone.png";
import React from "react";
import { isAndroid, isIOS, mobileVendor } from "react-device-detect";
import { useTranslation } from "react-i18next";

const MobilePopUp = ({ setAppDeclined }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const openStore = () => {
    if (isIOS) {
      window.open(
        "https://apps.apple.com/bs/app/prowin-messenger/id1553868389",
        "_blank"
      );
    }
    if (isAndroid) {
      if (mobileVendor.toLowerCase() === "huawei") {
        window.open("https://appgallery.huawei.com/#/app/C104422751", "_blank");
      } else {
        window.open(
          "https://play.google.com/store/apps/details?id=net.prowin.messenger",
          "_blank"
        );
      }
    }
  };

  return (
    <div className={classes.screen}>
      <div className={classes.headline}>
        <div className={classes.headlineLine}>
          {t("general.useAppHeadline1")}
        </div>
        <div className={classes.headlineLine}>
          {t("general.useAppHeadline2")}
          <span className={classes.headlineBackground}>
            {t("general.useAppHeadline3")}
          </span>
        </div>
        <div className={classes.headlineLine}>
          <span className={classes.headlineBackground}>
            {t("general.useAppHeadline4")}
          </span>
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={openStore}
          style={{ marginBottom: 10 }}
        >
          {t("general.useApp")}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => {
            setAppDeclined(true);
          }}
        >
          {t("general.continueInBrowser")}
        </Button>
      </div>
      <div className={classes.videoWrapper}>
        <video
          src={guestVideo}
          className={classes.video}
          alt="Chat"
          poster={phone}
          controls
        />
        <div className={classes.howItWorks}>
          {t("general.howItWorks")}
          <br />
          <img src="/Arrow.png" className={classes.arrow} />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    height: "100vh",
    maxHeight: "-webkit-fill-available",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: "url(/Background.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  continueButton: {
    alignSelf: "flex-end",
    fontSize: 14,
    fontWeight: "400",
    marginTop: 20,
    marginRight: 10,
    color: "white",
    textTransform: "none",
    borderWidth: 0,
  },
  headline: {
    fontSize: 24,
    color: "white",
    alignSelf: "flex-start",
    textAlign: "left",
    fontWeight: "bold",
    lineHeight: 1.3,
    marginLeft: 20,
  },
  headlineBackground: {
    color: "#0081C8",
    backgroundColor: "white",
    paddingLeft: 2,
    paddingRight: 2,
  },
  headlineLine: {
    paddingLeft: 2,
    paddingRight: 2,
  },
  videoWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  video: {
    maxHeight: "calc(100vh - 300px)",
    maxWidth: "60%",
    marginLeft: 10,
  },
  howItWorks: {
    color: "white",
    textAlign: "center",
    align: "center",
    marginLeft: 10,
    marginRight: 10,
    fontFamily: "'Indie Flower', cursive",
    fontWeight: "bold",
  },
  arrow: {
    marginTop: 10,
    width: 60,
  },
  button: {
    backgroundColor: "white",
    color: "#333",
    textTransform: "none",
    fontSize: 18,
    marginBottom: 30,
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    marginBottom: 20,
  },
}));

export default MobilePopUp;
