import { HANDLE_VIEW_RELEASE_NOTES, LOGOUT } from "../types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_VIEW_RELEASE_NOTES: {
      const version = action.payload;
      return {
        lastViewedVersion: version,
      };
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
