import {
  GET_PARTIES,
  ADD_PARTY,
  UPDATE_PARTY,
  REMOVE_PARTY,
  LOGOUT,
} from "../types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTIES: {
      let parties = action.payload;
      const newState = {};
      for (const party of parties) {
        newState[party.id] = party;
      }
      return newState;
    }

    case UPDATE_PARTY:
    case ADD_PARTY: {
      let party = action.payload;
      return {
        ...state,
        [party.id]: {
          ...(state[party.id] || {}),
          party,
        },
      };
    }

    case REMOVE_PARTY: {
      delete state[action.payload];
      return JSON.parse(JSON.stringify(state));
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
