import { TEMPLATE_TRASH, RESTORE_TEMPLATE, LOGOUT } from "../types";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case TEMPLATE_TRASH: {
      return action.payload;
    }

    case RESTORE_TEMPLATE: {
      const { templateId } = action.payload;
      return state.filter((t) => t.id !== templateId);
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
