import React from "react";
import { AppBar, Toolbar, Button, Menu, MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ArrowIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";

const AppBarWrapper = (props) => {
  const classes = useStyles();
  const { name, onChangeName, leaveRoom, isManager, expired } = props;

  const { t, i18n } = useTranslation();

  const langButton = React.useRef();
  const [langOpen, setLangOpen] = React.useState(false);
  const lang = i18n.language.substring(0, 2);
  const selectLang = async (lang) => {
    await i18n.changeLanguage(lang);
    setLangOpen(false);
  };

  const button = React.useRef();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleChangeName = () => {
    setMenuOpen(false);
    onChangeName();
  };

  const handleLeave = () => {
    leaveRoom();
  };

  const handleDashboard = () => {
    setMenuOpen(false);
    window.open("/parties", "_blank");
  };

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <img
            src={require("assets/logo-prowin-messenger-white.svg")}
            className={classes.logo}
          />
          <div className={classes.grow} />
          <Button
            color="inherit"
            onClick={() => setLangOpen(!langOpen)}
            ref={langButton}
            className={classes.langButton}
          >
            {lang === "en"
              ? "🇬🇧"
              : lang === "nl"
              ? "🇳🇱"
              : lang === "fr"
              ? "🇫🇷"
              : lang === "it"
              ? "🇮🇹"
              : "🇩🇪"}
          </Button>
          <Button
            color="inherit"
            onClick={() => setMenuOpen(!menuOpen)}
            ref={button}
            endIcon={<ArrowIcon />}
            className={classes.button}
            disabled={expired}
          >
            {name}
          </Button>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={langButton.current}
        keepMounted={true}
        open={langOpen}
        onClose={() => setLangOpen(false)}
      >
        <MenuItem onClick={() => selectLang("de")}>Deutsch 🇩🇪</MenuItem>
        <MenuItem onClick={() => selectLang("en")}>English 🇬🇧</MenuItem>
        <MenuItem onClick={() => selectLang("nl")}>Nederlands 🇳🇱</MenuItem>
        <MenuItem onClick={() => selectLang("fr")}>Français 🇫🇷</MenuItem>
        <MenuItem onClick={() => selectLang("it")}>Italiano 🇮🇹</MenuItem>
      </Menu>
      <Menu
        anchorEl={button.current}
        keepMounted={true}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {isManager && (
          <MenuItem onClick={handleDashboard}>
            {t("party.returnToDashboard")}
          </MenuItem>
        )}
        {!isManager && (
          <MenuItem onClick={handleChangeName}>
            {t("party.changeName")}
          </MenuItem>
        )}
        {!isManager && (
          <MenuItem onClick={handleLeave}>{t("party.leave")}</MenuItem>
        )}
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 999,
  },
  grow: {
    width: 0,
    flexGrow: 1,
  },
  logo: {
    width: "8em",
  },
  langButton: {
    paddingTop: 11,
    fontSize: "1.5rem",
    lineHeight: "1rem",
  },
  button: {
    textTransform: "none",
    fontWeight: "bold",
  },
}));

export default AppBarWrapper;
