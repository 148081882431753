import React from "react";
import { makeStyles } from "@mui/styles";
import { MediaCard, Lightbox } from "components";
import _ from "lodash";

const MediaList = ({
  media,
  session,
  removeMedia,
  editMedia,
  downloadMedia,
  moveTo,
  copyTo,
  drag,
  dragEnd,
  convertMedia,
  isTrash,
  restoreMedia,
}) => {
  const classes = useStyles();
  const [selected, select] = React.useState();
  const [multiSelected, setMultiSelected] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (m) => {
    if (m.mediaType !== "image" && m.mediaType !== "video") {
      window.open(
        `${process.env.REACT_APP_STATIC_URL}/${session.userId}/${m.id}`,
        "_blank"
      );
      return;
    }
    select(m);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const multiIds = () => {
    return multiSelected.map((item) => item.id);
  };

  const switchMultiSelect = (m) => {
    if (multiIds().includes(m.id)) {
      setMultiSelected(multiSelected.filter((item) => item.id !== m.id));
    } else {
      setMultiSelected([...multiSelected, m]);
    }
  };

  const handleDownload = (e, m) => {
    if (multiIds().includes(m.id)) {
      multiSelected.forEach((item) => downloadMedia(e, item));
      setMultiSelected([]);
    } else {
      downloadMedia(e, m);
    }
  };

  const handleRemove = (e, m) => {
    if (multiIds().includes(m.id)) {
      removeMedia(e, multiSelected);
      setMultiSelected([]);
    } else {
      removeMedia(e, m);
    }
  };

  const handleMove = (m) => {
    if (multiIds().includes(m.id)) {
      moveTo(multiIds());
      setMultiSelected([]);
    } else {
      moveTo(m.id);
    }
  };

  const handleCopy = (m) => {
    if (multiIds().includes(m.id)) {
      copyTo(multiIds());
      setMultiSelected([]);
    } else {
      copyTo(m.id);
    }
  };

  const handleDragEnd = (m) => {
    dragEnd();
    if (multiIds().includes(m.id)) setMultiSelected([]);
  };

  const sortedMedia = media.sort((a, b) =>
    a.title.localeCompare(
      b.title,
      navigator.languages[0] || navigator.language,
      {
        numeric: true,
        ignorePunctuation: true,
      }
    )
  );

  return (
    <div className={classes.list}>
      {!isTrash &&
        sortedMedia.map((m) => (
          <MediaCard
            key={m.id}
            title={m.title}
            type={m.mediaType}
            userId={session.userId}
            mediaId={m.id}
            conversionStatus={m.conversionStatus}
            onView={() => handleOpen(m)}
            onDownload={(e) => handleDownload(e, m)}
            onEdit={(e) => editMedia(e, m)}
            onRemove={(e) => handleRemove(e, m)}
            onDrag={(e) => drag(e, multiIds())}
            onDragEnd={() => handleDragEnd(m)}
            onMoveTo={() => handleMove(m)}
            onConvert={(e) => convertMedia(e, m)}
            onMultiSelect={() => switchMultiSelect(m)}
            selected={multiIds().includes(m.id)}
            isTrash={isTrash}
            onRestore={() => restoreMedia(m.id)}
            onCopyTo={() => handleCopy(m)}
          />
        ))}
      {isTrash &&
        media.map((m) => (
          <MediaCard
            key={m.id}
            title={m.title}
            type={m.mediaType}
            userId={session.userId}
            mediaId={m.id}
            conversionStatus={m.conversionStatus}
            onView={() => handleOpen(m)}
            onDownload={(e) => handleDownload(e, m)}
            onEdit={(e) => editMedia(e, m)}
            onRemove={(e) => handleRemove(e, m)}
            onDrag={(e) => drag(e, multiIds())}
            onDragEnd={() => handleDragEnd(m)}
            onMoveTo={() => handleMove(m)}
            onConvert={(e) => convertMedia(e, m)}
            onMultiSelect={() => switchMultiSelect(m)}
            selected={multiIds().includes(m.id)}
            isTrash={isTrash}
            onRestore={() => restoreMedia(m.id)}
            onCopyTo={() => handleCopy(m)}
          />
        ))}
      <Lightbox
        open={open}
        userId={session.userId}
        media={selected}
        onClose={handleClose}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flexWrap: "wrap",
    paddingBottom: 10,
  },
}));

export default MediaList;
