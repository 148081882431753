import { Button, Container, CssBaseline, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import logo from "assets/logo-prowin-messenger.svg";
import Axios from "axios";
import React, { useState } from "react";

const DeleteAccountPage = () => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSending(true);
    await Axios.post(
      `${process.env.REACT_APP_API_URL}/moderation/delete-account`,
      { email }
    );
    setSuccess(true);
    setSending(false);
  };

  if (success)
    return (
      <div>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <Container className={classes.container}>
            <div className={classes.header}>
              <img src={logo} alt="Prowin Messenger" height={60} />
              <div style={{ flex: 1 }} />
              <Button
                onClick={() => (window.location.href = "/")}
                variant="contained"
                color="primary"
              >
                Zur Startseite
              </Button>
            </div>

            <h1>Löschanfrage abgesendet</h1>
            <p className={classes.sectionText}>
              Wir haben deine Anfrage erhalten und löschen deinen Account und
              alle deine persönlichen Daten innerhalb von 48h.
            </p>
          </Container>
        </Container>
      </div>
    );

  return (
    <div>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Container className={classes.container}>
          <div className={classes.header}>
            <img src={logo} alt="Prowin Messenger" height={60} />
            <div style={{ flex: 1 }} />
            <Button
              onClick={() => (window.location.href = "/")}
              variant="contained"
              color="primary"
            >
              Zur Startseite
            </Button>
          </div>

          <h1>Konto löschen</h1>
          <p className={classes.sectionText}>
            Du möchtest dein Konto im proWIN-Messenger und alle damit
            verknüpften persönlichen Daten gerne löschen?
          </p>
          <p className={classes.sectionText}>
            Kein Problem, das geht ganz einfach!
          </p>
          <p className={classes.sectionText}>
            Teile uns dazu einfach deine E-Mail-Adresse mit, mit der dein Konto
            verknüpft ist. Anschließend löschen wir den Account und alle deine
            persönlichen Daten innerhalb von 48h.
          </p>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              type="email"
              label="Deine E-Mail-Adresse"
              fullWidth
              style={{ maxWidth: 500 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="error"
              disabled={sending}
            >
              {sending ? "Anfrage versendet..." : "Konto löschen"}
            </Button>
          </form>
        </Container>
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 800,
  },
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  sectionText: {
    variant: "body1",
    marginTop: 5,
    textAlign: "center",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 15,
    marginTop: 20,
  },
}));

export default DeleteAccountPage;
