const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const subscribeUser = async () => {
  if (!"serviceWorker" in navigator) return;
  try {
    const registration = await navigator.serviceWorker.ready;
    if (!registration.pushManager) {
      console.log("Push manager unavailable.");
      return;
    }
    const subscription = await registration.pushManager.getSubscription();
    if (subscription) {
      console.log("Existing subscription detected.", subscription);
      return subscription;
    }
    console.log("No subscription detected, make a request.");
    try {
      const newSubscription = await registration.pushManager.subscribe({
        applicationServerKey: urlBase64ToUint8Array(
          process.env.REACT_APP_PUBLIC_VAPID_KEY
        ),
        userVisibleOnly: true,
      });
      console.log("New subscription added.");
      return newSubscription;
    } catch (e) {
      if (Notification.permission !== "granted")
        console.log("Permission was not granted.");
      else
        console.error("An error ocurred during the subscription process.", e);
      return;
    }
  } catch (e) {
    console.error("An error ocurred during Service Worker registration.", e);
    return;
  }
};
