import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";

const CreateBannerGroupDialog = (props, ref) => {
  const classes = useStyles();

  const { onSubmit } = props;
  const [open, setOpen] = useState(false);
  const [lang, setLang] = useState("");

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
      setLang("");
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit(lang);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Neue Sprache/Region hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Verwende zweistellige ISO-Codes für die Sprache bzw. Region (z.B. de
            für Deutsch).
            <br />
            Möchtest du nur die Sprache angeben, gib lediglich den Sprachcode
            ein.
            <br />
            Möchtest du nur die Region angeben, gib einen Bindestrich, gefolgt
            von dem Regionscode ein (z.B. -ch für die Schweiz).
            <br />
            Möchtest du Sprache und Region angeben, gib beide Codes mit
            Bindestrich dazwischen ein (z.B. fr-lu für französisch in
            Luxemburg).
          </DialogContentText>
          <TextField
            fullWidth
            autoFocus
            label="Sprache/Region"
            value={lang}
            onChange={(e) => setLang(e.target.value)}
            style={{ marginTop: 10 }}
          />
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Abbrechen
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Erstellen
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
}));

export default React.forwardRef(CreateBannerGroupDialog);
