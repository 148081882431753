import React from "react";
import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  InputBase,
  IconButton,
  Backdrop,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import PhotoIcon from "@mui/icons-material/Photo";
import HeaderIcon from "@mui/icons-material/Toc";
import MediaIcon from "@mui/icons-material/VideoLibrary";
import MicIcon from "@mui/icons-material/Mic";
import CloseIcon from "@mui/icons-material/Close";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import ProfileIcon from "@mui/icons-material/AssignmentInd";
import "react-image-lightbox/style.css";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { useTranslation } from "react-i18next";
import { checkText } from "smile2emoji";

const ChatBar = (
  {
    isManager,
    sending,
    expired,
    editing,
    autoFocus,
    showEmojiKeyboard,
    hideEmojiKeyboard,
    showMediaDialog,
    showHeadingDialog,
    selectFiles,
    showVoiceDialog,
    showProfileDialog,
    showTemplateImportDialog,
    onEnter,
    onSendText,
    onFinishEditing,
    onCancelEditing,
  },
  ref
) => {
  const classes = useStyles({ isManager });
  const { t } = useTranslation();

  const [message, setMessage] = React.useState("");
  const handleMessageChange = (e) => {
    setMessage(checkText(e.target.value));
  };

  const [speedDialOpen, setSpeedDialOpen] = React.useState(false);
  const toggleSpeedDial = () => {
    hideEmojiKeyboard();
    setSpeedDialOpen(!speedDialOpen);
  };
  const handleSpeedDial = (action) => {
    setSpeedDialOpen(false);
    action();
  };

  const inputRef = React.useRef();

  React.useImperativeHandle(ref, () => ({
    setMessage: (message) => {
      setMessage(message);
    },
    focus: () => inputRef?.current?.focus?.(),
    setSelectionRange: (...args) =>
      inputRef?.current?.setSelectionRange?.(...args),
  }));

  return (
    <div className={classes.chatBar}>
      <Backdrop
        open={speedDialOpen}
        className={classes.backdrop}
        onClick={toggleSpeedDial}
      />
      <SpeedDial
        ariaLabel=""
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClick={toggleSpeedDial}
        open={speedDialOpen}
        size="small"
        disabled={sending || expired}
      >
        <SpeedDialAction
          disabled={sending || expired}
          icon={<PhotoIcon />}
          tooltipTitle={t("party.sendPhotoTitle")}
          onClick={() => handleSpeedDial(selectFiles)}
        />
        {isManager && (
          <SpeedDialAction
            disabled={sending || expired}
            icon={<HeaderIcon />}
            tooltipTitle={t("party.sendHeadingTitle")}
            onClick={() => handleSpeedDial(showHeadingDialog)}
          />
        )}
        {isManager && !!showTemplateImportDialog && (
          <SpeedDialAction
            disabled={sending || expired}
            icon={<ViewCompactIcon />}
            tooltipTitle={t("party.templateImportTitle")}
            onClick={() => handleSpeedDial(showTemplateImportDialog)}
          />
        )}
        {isManager && (
          <SpeedDialAction
            disabled={sending || expired}
            icon={<MediaIcon />}
            tooltipTitle={t("party.sendMediaTitle")}
            onClick={() => handleSpeedDial(showMediaDialog)}
          />
        )}
        {isManager && !!showProfileDialog && (
          <SpeedDialAction
            disabled={sending || expired}
            icon={<ProfileIcon />}
            tooltipTitle={t("party.sendProfileTitle")}
            onClick={() => handleSpeedDial(showProfileDialog)}
          />
        )}
      </SpeedDial>
      <InputBase
        id="chatInput"
        inputRef={inputRef}
        variant="filled"
        classes={{
          root: classes.chatField,
          input: classes.chatFieldInput,
        }}
        multiline
        fullWidth
        maxRows={8}
        value={message}
        onChange={handleMessageChange}
        disabled={sending}
        placeholder={t("party.writeMessage")}
        onKeyPress={(e) => onEnter(e, message)}
        autoFocus={autoFocus}
      />
      <IconButton
        disabled={sending || expired}
        onClick={showEmojiKeyboard}
        className={classes.emojiButton}
        size="small"
      >
        <EmojiEmotionsIcon />
      </IconButton>
      {!editing && !message && (
        <IconButton
          disabled={sending || expired}
          onClick={showVoiceDialog}
          className={classes.sendButton}
        >
          <MicIcon />
        </IconButton>
      )}
      {!editing && !!message && (
        <IconButton
          onClick={() => onSendText(message)}
          disabled={!message || sending || expired}
          className={classes.sendButton}
        >
          <SendIcon />
        </IconButton>
      )}
      {editing && (
        <IconButton
          onClick={onCancelEditing}
          disabled={sending || expired}
          className={classes.sendButton}
        >
          <CloseIcon />
        </IconButton>
      )}
      {editing && (
        <IconButton
          onClick={() => onFinishEditing(message)}
          disabled={!message || sending || expired}
          className={classes.sendButton}
        >
          <CheckIcon />
        </IconButton>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  chatBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: 10,
    borderTop: "1px solid #D8D8D8",
    height: 70,
  },
  chatField: {
    backgroundColor: "#EBEBEB",
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRadius: theme.spacing(3),
  },
  chatFieldInput: {
    lineHeight: "1.5em",
    fontSize: "1em",
  },
  emojiButton: {
    marginLeft: theme.spacing(-4.5),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  sendButton: {
    marginBottom: theme.spacing(0.5),
  },
  backdrop: {
    zIndex: 1000,
  },
  speedDial: {
    marginTop: (props) =>
      theme.spacing(-7) * (props.isManager ? 4 : 1) - theme.spacing(2),
    "& .MuiSpeedDial-fab": {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    marginLeft: theme.spacing(-1),
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
    },
  },
}));

export default React.forwardRef(ChatBar);
