const preventSimultaneousPlayback = () => {
  document.addEventListener(
    "play",
    function (e) {
      const audios = document.getElementsByTagName("audio");
      for (var i = 0, len = audios.length; i < len; i++) {
        if (audios[i] !== e.target) {
          audios[i].pause();
        }
      }
    },
    true
  );
};

export default preventSimultaneousPlayback;
