import React from "react";
import { FolderCard, TrashCard } from "components";
import { makeStyles } from "@mui/styles";
import _ from "lodash";

const FolderList = ({
  media,
  session,
  editMedia,
  removeMedia,
  drop,
  openFolder,
  moveTo,
  drag,
  dragEnd,
  openTrash,
  isTrash,
  restoreMedia,
}) => {
  const classes = useStyles();

  const sortedMedia = media.sort((a, b) =>
    a.title.localeCompare(
      b.title,
      navigator.languages[0] || navigator.language,
      {
        numeric: true,
        ignorePunctuation: true,
      }
    )
  );

  return (
    <div className={classes.list}>
      {openTrash && (
        <TrashCard
          key={"trash"}
          type={"folder"}
          userId={session.userId}
          onView={openTrash}
        />
      )}
      {!isTrash &&
        sortedMedia.map((m) => (
          <FolderCard
            key={m.id}
            title={m.title}
            type={m.mediaType}
            userId={session.userId}
            mediaId={m.id}
            onView={() => openFolder(m)}
            onEdit={(e) => editMedia(e, m)}
            onRemove={(e) => removeMedia(e, m)}
            onDrop={(e) => drop(e, m.id)}
            onDrag={drag}
            onDragEnd={dragEnd}
            onMoveTo={() => moveTo(m.id)}
            isTrash={isTrash}
            onRestore={() => restoreMedia(m.id)}
          />
        ))}
      {isTrash &&
        media.map((m) => (
          <FolderCard
            key={m.id}
            title={m.title}
            type={m.mediaType}
            userId={session.userId}
            mediaId={m.id}
            onView={() => openFolder(m)}
            onEdit={(e) => editMedia(e, m)}
            onRemove={(e) => removeMedia(e, m)}
            onDrop={(e) => drop(e, m.id)}
            onDrag={drag}
            onDragEnd={dragEnd}
            onMoveTo={() => moveTo(m.id)}
            isTrash={isTrash}
            onRestore={() => restoreMedia(m.id)}
          />
        ))}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flexWrap: "wrap",
    paddingBottom: 10,
  },
}));

export default FolderList;
