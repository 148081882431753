import {
  CREATE_BANNER_GROUP,
  GET_BANNER_GROUPS,
  UPDATE_BANNER_GROUP,
} from "../types";

const initialState = {
  groups: [],
  authorized: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNER_GROUPS: {
      if (action.payload === null) return initialState;
      return {
        groups: action.payload,
        authorized: true,
      };
    }

    case CREATE_BANNER_GROUP: {
      return {
        groups: [...state.groups, action.payload],
        authorized: true,
      };
    }

    case UPDATE_BANNER_GROUP: {
      const updatedGroup = action.payload;
      const index = state.groups.findIndex(
        (group) =>
          group.language === updatedGroup.language &&
          group.type === updatedGroup.type
      );
      if (index === -1) return state;
      const newGroups = [...state.groups];
      newGroups[index].banners = updatedGroup.banners;
      return {
        groups: newGroups,
        authorized: true,
      };
    }

    default:
      return state;
  }
};
