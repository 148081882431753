import { useTheme } from "@mui/styles";
import Amplify from "aws-amplify";
import React, { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  AcceptShare,
  AddVIP,
  Callback,
  DeleteAccount,
  FAQEntry,
  Impress,
  LandingPage,
  LoginPage,
  NotFound,
  Party,
  Privacy,
  Profile,
  TemplateDetails,
} from "screens";
import { useActions } from "src/redux/actions";
import { routes as allRoutes, bannerRoutes } from "./allRoutes";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "eu-central-1",
    userPoolId: "eu-central-1_EnzFuLCAo",
    userPoolWebClientId: "74nqom1k7fc8o193lcld7uf15f",
  },
});

const Router = (props) => {
  const session = useSelector((state) => state.session);
  const { authorized: isBannerUser } = useSelector((state) => state.banners);
  const theme = useTheme();

  const { GetBannerGroups } = useActions();
  useEffect(() => {
    GetBannerGroups();
  }, []);

  const routes = !isBannerUser ? allRoutes : bannerRoutes;
  const { t, i18n } = useTranslation();
  document.documentElement.setAttribute("lang", i18n.language.substring(0, 2));

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={t("general.dismissCookieNotice")}
        style={{
          background: theme.palette.primary.main,
          fontFamily: "Roboto, sans-serif",
          zIndex: 1500,
        }}
        buttonStyle={{
          background: "transparent",
          fontFamily: "Roboto, sans-serif",
          fontWeight: "500",
          color: "white",
          fontSize: "13px",
        }}
      >
        {t("general.cookieNotice")}
      </CookieConsent>
      <Switch>
        <Route exact path="/delete-account">
          <DeleteAccount />
        </Route>
        <Route exact path="/privacy">
          <Privacy />
        </Route>
        <Route exact path="/impress">
          <Impress />
        </Route>
        {/* <Route exact path="/login">
          <LoginPage />
        </Route> */}
        <Route exact path="/callback">
          <Callback />
        </Route>
        {/* {!!session.secretKey && (
          <Route exact path="/claim">
            <Claim />
          </Route>
        )} */}
        {!!session.accessToken && (
          <Route exact path="/">
            <Redirect to={routes[0].path} />
          </Route>
        )}
        <Route exact path="/addTemplate/:shareToken">
          {!!session.accessToken && <AcceptShare menuItems={routes} />}
          {!session.accessToken && <LoginPage />}
        </Route>
        <Route exact path="/addVIP/:id">
          <AddVIP />
        </Route>
        <Route exact path="/faq/entry/:slug">
          {!!session.accessToken && <FAQEntry menuItems={routes} />}
          {!session.accessToken && <LoginPage />}
        </Route>
        {!!session.accessToken && (
          <Route exact path="/templates/:templateId">
            <TemplateDetails menuItems={routes} />
          </Route>
        )}
        {!!session.accessToken && (
          <Route exact path="/profiles/:profileId">
            <Profile menuItems={routes} />
          </Route>
        )}
        {/* {!!session.secretKey && (
          <Route exact path="/guest-profile/:profileId">
            <GuestProfile menuItems={menuRoutes} />
          </Route>
        )} */}
        {routes.map((r) => (
          <Route path={r.path} key={r.id}>
            {!!session.accessToken ? (
              <r.screen menuItems={routes} />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
        ))}
        <Route exact path="/de">
          <LandingPage lang="de" />
        </Route>
        <Route exact path="/en">
          <LandingPage lang="en" />
        </Route>
        <Route exact path="/fr">
          <LandingPage lang="fr" />
        </Route>
        <Route exact path="/it">
          <LandingPage lang="it" />
        </Route>
        <Route exact path="/nl">
          <LandingPage lang="nl" />
        </Route>
        <Route exact path="/:partyId">
          <Party />
        </Route>
        {!session.accessToken && (
          <Route path="*">
            <LandingPage />
          </Route>
        )}
        <Route path="*">
          <NotFound menuItems={routes} />
        </Route>
      </Switch>
    </>
  );
};

export default Router;
