import React from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';

const LanguageButton = (props) => {
  const classes = useStyles();
  const langButton = React.useRef();
  const [langOpen, setLangOpen] = React.useState(false);

  return (
    <IconButton
      color="inherit"
      onClick={() => setLangOpen(!langOpen)}
      ref={langButton}
      size="large">
      {props.lang === "en"
        ? "🇬🇧"
        : props.lang === "nl"
        ? "🇳🇱"
        : props.lang === "fr"
        ? "🇫🇷"
        : "🇩🇪"}
      <Menu
        anchorEl={langButton.current}
        keepMounted={true}
        open={langOpen}
        onClose={() => setLangOpen(false)}
      >
        <Link className={classes.languageLink} to="/de">
          <MenuItem>Deutsch 🇩🇪</MenuItem>
        </Link>
        <Link className={classes.languageLink} to="/en">
          <MenuItem>English 🇬🇧</MenuItem>
        </Link>
        <Link className={classes.languageLink} to="/nl">
          <MenuItem> Nederlands 🇳🇱</MenuItem>
        </Link>
        <Link className={classes.languageLink} to="/fr">
          <MenuItem>Français 🇫🇷</MenuItem>
        </Link>
      </Menu>
    </IconButton>
  );
};

const useStyles = makeStyles((theme) => ({
  languageLink: {
    textDecoration: "none",
    color: "black",
  },
}));

export default LanguageButton;
