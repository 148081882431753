import React from "react";
import { useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { LoginComponent } from "src/components";

const LoginPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.wrapper}>
      {!matches && <div className={classes.img}></div>}
      <div className={classes.loginComponent}>
        <LoginComponent />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    height: "100vh",
    backgroundColor: "#F3F9FC",
    flex: 1,
  },
  loginComponent: {
    flex: 1,
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    height: "100%",
    flex: 1,
    backgroundImage: "url(prowinImg.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

export default LoginPage;
