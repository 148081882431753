import PartyIcon from "@mui/icons-material/Event";
import HelpIcon from "@mui/icons-material/Help";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PersonIcon from "@mui/icons-material/Person";
import MediaIcon from "@mui/icons-material/VideoLibrary";
import BannerIcon from "@mui/icons-material/ViewCarousel";
import WidgetsIcon from "@mui/icons-material/Widgets";
import React from "react";
import {
  Banners,
  FAQ,
  Media,
  Parties,
  ProfileList,
  ReleaseNotes,
  Templates,
} from "screens";

export const routes = [
  {
    id: "media",
    screen: (props) => <Media {...props} />,
    path: "/media",
    link: "/media",
    displayName: "media.library",
    icon: <MediaIcon />,
  },
  {
    id: "parties",
    screen: (props) => <Parties {...props} />,
    path: "/parties",
    link: "/parties",
    displayName: "parties.title",
    icon: <PartyIcon />,
  },
  {
    id: "templates",
    screen: (props) => <Templates {...props} />,
    path: "/templates",
    link: "/templates",
    displayName: "templates.title",
    icon: <WidgetsIcon />,
  },
  {
    id: "profiles",
    screen: (props) => <ProfileList {...props} />,
    path: "/profiles",
    link: "/profiles",
    displayName: "profiles.title",
    icon: <PersonIcon />,
  },
  {
    id: "releaseNotes",
    screen: (props) => <ReleaseNotes {...props} />,
    path: "/releaseNotes",
    link: "/releaseNotes",
    displayName: "Release Notes",
    icon: <NewReleasesIcon />,
  },
  {
    id: "faq",
    screen: (props) => <FAQ {...props} />,
    path: ["/faq/topic/:slug", "/faq"],
    link: "/faq",
    displayName: "faq.menuLabel",
    icon: <HelpIcon />,
  },
];

export const bannerRoutes = [
  ...routes,
  {
    id: "banners",
    screen: (props) => <Banners {...props} />,
    path: "/banners",
    link: "/banners",
    displayName: "App-Banner",
    icon: <BannerIcon />,
  },
];

export const guestRoutes = [
  {
    id: "parties",
    screen: (props) => <Parties {...props} />,
    path: "/parties",
    link: "/parties",
    displayName: "parties.title",
    icon: <PartyIcon />,
  },
  {
    id: "faq",
    screen: (props) => <FAQ {...props} />,
    path: ["/faq/topic/:slug", "/faq"],
    link: "/faq",
    displayName: "faq.menuLabel",
    icon: <HelpIcon />,
  },
];
