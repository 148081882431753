import React from "react";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Typography,
  ButtonGroup,
  Button,
  IconButton,
} from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const ActionBar = ({
  open,
  tabs = [],
  activeTab,
  onTabChange,
  path,
  media = [],
  actions = [],
  title,
  titleAlwaysVisible,
  currentView,
  switchView = () => {},
  newFolder,
  setNewPath,
  drop,
  templateDetail,
  banner,
  sortBy,
  setSortBy,
  pathHomeLabel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [activePathHover, setActivePathHover] = React.useState();

  const handleNewFolder = () => {
    newFolder();
  };

  const handleSetPath = (id) => {
    setNewPath(id);
  };

  const handleDrop = (e, p) => {
    drop(e, p);
    setActivePathHover();
  };

  const history = useHistory();
  const handleReturn = () => {
    history.push("/templates");
  };

  const renderAction = (action) => {
    if (!action.label)
      return (
        <Button
          onClick={action.action}
          key={action.id}
          disabled={action.disabled}
          className={clsx({
            [classes.destructiveButton]: action.style === "destructive",
          })}
          style={{ color: action.style === "inherit" && "inherit" }}
        >
          {action.icon}
        </Button>
      );
    return (
      <Button
        key={action.id}
        startIcon={action.icon}
        onClick={action.action}
        disabled={action.disabled}
        className={clsx(classes.button, {
          [classes.destructiveButton]: action.style === "destructive",
        })}
        style={{ color: action.style === "inherit" && "inherit" }}
      >
        {action.label}
      </Button>
    );
  };

  return (
    <AppBar
      elevation={0}
      className={clsx(banner ? classes.appBarBanner : classes.appBar, {
        [classes.appBarShift]: open,
        [classes.appBarNoShift]: open === undefined,
      })}
      color="inherit"
    >
      <Toolbar
        variant="dense"
        style={{ padding: 0, display: "flex", flexDirection: "column" }}
      >
        <div className={classes.header}>
          <Typography
            className={clsx(classes.title, {
              [classes.titleAlwaysVisible]: titleAlwaysVisible,
            })}
            variant="h1"
            noWrap
          >
            {title}
          </Typography>
          <div className={classes.grow} />
          <ButtonGroup color="secondary" variant="contained" size="small">
            {actions.map(renderAction)}
          </ButtonGroup>
        </div>
        {tabs.length === 0 ? null : (
          <div className={classes.subHeader}>
            <div className={classes.subSubHeader1}>
              <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={onTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabs.map((tab, index) => (
                  <Tab
                    className={classes.tabOverride}
                    key={index}
                    label={tab.label}
                    disabled={tab.disabled}
                  />
                ))}
              </Tabs>
            </div>
            {sortBy && (
              <div>
                <Button onClick={() => setSortBy("date")}>
                  <DateRangeIcon
                    color={sortBy === "date" ? "primary" : "inherit"}
                  />
                </Button>
                <Button onClick={() => setSortBy("abc")}>
                  <SortByAlphaIcon
                    color={sortBy === "abc" ? "primary" : "inherit"}
                  />
                </Button>
              </div>
            )}
          </div>
        )}
        {path && (
          <div className={classes.subHeader}>
            <Button
              size="small"
              classes={{
                root: clsx(classes.pathButton, {
                  [classes.pathButtonActive]: activePathHover === "home",
                }),
                label: classes.pathButtonLabel,
              }}
              onDrop={(e) => handleDrop(e, "home")}
              onClick={() => handleSetPath("home")}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={() => setActivePathHover("home")}
              onDragLeave={() => setActivePathHover()}
            >
              {pathHomeLabel}
            </Button>
            {path.length > 0 && (
              <ChevronRight
                fontSize="small"
                className={classes.pathSeparator}
              />
            )}
            {path.length > 2 && (
              <>
                <Button
                  size="small"
                  classes={{
                    root: clsx(classes.pathButton, {
                      [classes.pathButtonActive]: activePathHover === "home",
                    }),
                    label: classes.pathButtonLabel,
                  }}
                  disabled
                >
                  ...
                </Button>
                <ChevronRight
                  fontSize="small"
                  className={classes.pathSeparator}
                />
              </>
            )}
            {path.slice(-2).map((p, index) => (
              <React.Fragment key={p}>
                <Button
                  size="small"
                  classes={{
                    root: clsx(classes.pathButton, {
                      [classes.pathButtonActive]: activePathHover === p,
                    }),
                    label: classes.pathButtonLabel,
                  }}
                  onDrop={p === "trash" ? () => {} : (e) => handleDrop(e, p)}
                  onClick={p === "trash" ? () => {} : () => handleSetPath(p)}
                  onDragOver={
                    p === "trash" ? () => {} : (e) => e.preventDefault()
                  }
                  onDragEnter={
                    p === "trash" ? () => {} : () => setActivePathHover(p)
                  }
                  onDragLeave={
                    p === "trash" ? () => {} : () => setActivePathHover()
                  }
                >
                  {p === "trash"
                    ? t("templates.trash")
                    : media.find((m) => m.id === p)?.title}
                </Button>
                {index < path.slice(-2).length - 1 && (
                  <ChevronRight
                    fontSize="small"
                    className={classes.pathSeparator}
                  />
                )}
              </React.Fragment>
            ))}
            <div className={classes.grow} />
            {!!newFolder && (
              <IconButton onClick={handleNewFolder} size="large">
                <CreateNewFolderIcon />
              </IconButton>
            )}
            {false && (
              <IconButton onClick={switchView} size="large">
                {currentView ? <ViewModuleIcon /> : <ViewListIcon />}
              </IconButton>
            )}
          </div>
        )}
        {templateDetail && (
          <div className={classes.subHeader}>
            <Button
              size="small"
              classes={{
                root: classes.pathButton,
                label: classes.pathButtonLabel,
              }}
              onClick={handleReturn}
              startIcon={<ChevronLeft />}
            >
              {t("templates.return")}
            </Button>
          </div>
        )}
        <div className={classes.grow} />
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "none",
    borderBottom: "1px solid #D8D8D8",
    marginTop: 56,
    marginBottom: 10,
    marginLeft: theme.spacing(7) + 1,
    width: `calc(100% - calc(${theme.spacing(7)} + 1px))`,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      marginTop: 48,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 64,
      marginLeft: theme.spacing(9) + 1,
      width: `calc(100% - calc(${theme.spacing(9)} + 1px))`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarBanner: {
    boxShadow: "none",
    borderBottom: "1px solid #D8D8D8",
    marginTop: 76,
    marginBottom: 10,
    marginLeft: theme.spacing(7) + 1,
    width: `calc(100% - calc(${theme.spacing(7)} + 1px))`,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      marginTop: 48,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 84,
      marginLeft: theme.spacing(9) + 1,
      width: `calc(100% - calc(${theme.spacing(9)} + 1px))`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: 240,
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarNoShift: {
    marginLeft: 0,
    width: "100%",
  },
  grow: {
    width: 0,
    flexGrow: 1,
  },
  path: {
    cursor: "pointer",
    color: "rgba(0, 0, 0, 0.54)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.14)",
    },
  },
  header: {
    display: "flex",
    flex: 1,
    width: "100%",
    alignItems: "center",
    paddingTop: 10,
    paddingRight: 15,
    paddingLeft: 25,
  },
  subHeader: {
    display: "flex",
    flex: 1,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 10,
  },
  subHeader1: {
    display: "flex",
    flex: 1,
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingRight: 45,
  },
  ul: {
    paddingLeft: 1,
  },
  button: {
    whiteSpace: "nowrap",
  },
  destructiveButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  titleAlwaysVisible: {
    display: "block",
  },
  pathButton: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "0.9rem",
    color: "#333",
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
    minWidth: 30,
    "& span": {
      pointerEvents: "none",
    },
    justifyContent: "flex-start",
    margin: `${theme.spacing(1)} 0`,
  },
  pathButtonActive: {
    backgroundColor: "#ddd",
  },
  pathButtonLabel: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  pathSeparator: {
    color: "#999",
    marginLeft: -5,
    marginRight: -5,
  },
}));

export default ActionBar;
