import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  LinearProgress,
  Typography,
  TextField,
} from "@mui/material";
import { Alert } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import { useActions } from "actions";

const ImportDialog = (props, ref) => {
  const classes = useStyles();

  const {
    ImportStart,
    ImportFile,
    ImportMedia,
    ImportMessage,
    ImportParty,
  } = useActions();

  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState("pending");
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  const [description, setDescription] = React.useState("");

  const { t } = useTranslation();

  React.useImperativeHandle(ref, () => ({
    open: async () => {
      setOpen(true);
      setState("pending");
    },
  }));

  const handleClose = () => {
    if (state === "pending") setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state !== "pending") return;
    if (!email || !password) {
      return;
    }
    setLoading(true);
    let token;
    try {
      let response = await Auth.signIn(email, password);
      if (response.challengeName === "NEW_PASSWORD_REQUIRED")
        throw new Error("Password change required.");
      let session = await Auth.currentSession();
      token = session.idToken.jwtToken;
    } catch {
      setError(t("import.invalidCredentials"));
      setLoading(false);
      return;
    }
    setState("running");
    setDescription(t("import.starting"));
    const { response } = await ImportStart(token);
    const { files, media, messages, parties } = response.data;
    const total = [...files, ...media, ...messages, ...parties].length + 1;
    let i, j, k, l;
    setDescription(t("import.copyFiles"));
    for (i = 0; i < files.length; i++) {
      setProgress((100 / total) * i);
      await ImportFile(files[i]);
    }
    setDescription(t("import.copyMedia"));
    for (j = 0; j < media.length; j++) {
      setProgress((100 / total) * (i + j));
      await ImportMedia(media[j]);
    }
    setDescription(t("import.copyMessages"));
    for (k = 0; k < messages.length; k++) {
      setProgress((100 / total) * (i + j + k));
      await ImportMessage(messages[k].id, messages[k].partyId);
    }
    setDescription(t("import.copyParties"));
    for (l = 0; l < parties.length; l++) {
      setProgress((100 / total) * (i + j + k + l));
      await ImportParty(parties[l]);
    }
    setProgress(100);
    setDescription(t("import.done"));
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setOpen(false);
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("import.title")}</DialogTitle>
        <DialogContent>
          {state === "pending" && (
            <>
              <DialogContentText style={{ whiteSpace: "pre-wrap" }}>
                {t("import.description")}
              </DialogContentText>
              <TextField
                variant="outlined"
                fullWidth
                required
                id="email"
                name="email"
                label={t("import.email")}
                autoComplete="email"
                autoFocus
                placeholder="max@mustermann.de"
                className={classes.inputField}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                disabled={loading}
              />
              <TextField
                variant="outlined"
                fullWidth
                required
                id="password"
                name="password"
                type="password"
                label={t("import.password")}
                autoComplete="current-password"
                className={classes.inputField}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                disabled={loading}
              />
              {error === "" ? null : (
                <Alert severity="error" onClose={() => setError("")}>
                  {error}
                </Alert>
              )}
            </>
          )}
          {state !== "pending" && (
            <Alert severity={state === "done" ? "success" : "info"}>
              <LinearProgress
                variant="determinate"
                value={progress}
                color="primary"
                className={classes.progressBar}
              />
              <Typography>{description}</Typography>
            </Alert>
          )}
        </DialogContent>
        <div className={classes.spacer} />
        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            disabled={loading || state !== "pending"}
          >
            {t("general.cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading || state !== "pending"}
          >
            {t("import.start")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
  inputField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  progressBar: {
    width: 300,
    height: 20,
    borderRadius: 5,
  },
}));

export default React.forwardRef(ImportDialog);
