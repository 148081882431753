import React from "react";
import {
  Container,
  CssBaseline,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  TextField,
  IconButton,
} from "@mui/material";
import { AppBar, ActionBar, Menu } from "components";
import { makeStyles } from "@mui/styles";
import EntryIcon from "@mui/icons-material/MenuBook";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation, Trans } from "react-i18next";
import useSWR from "swr";
import { Redirect, useHistory, useParams } from "react-router-dom";

const FAQ = (props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const { t, i18n } = useTranslation();

  const { slug } = useParams();
  const history = useHistory();

  const { data: categoryData, error: categoryError } = useSWR(
    !slug
      ? null
      : `/categories?slug=${slug}&_locale=${i18n.language.substr(0, 2)}`
  );
  const [category] = categoryData || [];
  React.useEffect(() => {
    document.title = `${
      !category?.name ? t("faq.title") : category.name
    } - proWIN Messenger`;
  }, [category]);

  const { data: categories } = useSWR(
    !slug
      ? `/categories?kategorie_null=true&_locale=${i18n.language.substr(0, 2)}`
      : `/categories?kategorie=${category?.id}&_locale=${i18n.language.substr(
          0,
          2
        )}`
  );
  const { data: entries } = useSWR(
    !slug
      ? `/entries?kategorie_null=true&_locale=${i18n.language.substr(0, 2)}`
      : `/entries?kategorie=${category?.id}&_locale=${i18n.language.substr(
          0,
          2
        )}`
  );

  const [query, setQuery] = React.useState("");
  const { data: searchResults } = useSWR(
    !query
      ? null
      : `/content-search?_q=${query}&_locale=${i18n.language.substr(0, 2)}`
  );

  const renderCategory = (category) => {
    if (category.sichtbarkeit === "Kunden") return;
    if (category.plattform === "App") return;
    return (
      <Card
        key={category.id}
        className={classes.categoryCard}
        onClick={() => history.push(`/faq/topic/${category.slug}`)}
      >
        <CardContent>
          <Typography variant="body1" className={classes.strong}>
            {category.name}
          </Typography>
          <Typography variant="caption">{category.beschreibung}</Typography>
        </CardContent>
      </Card>
    );
  };

  const renderEntry = (entry) => {
    if (entry.sichtbarkeit === "Kunden") return;
    if (entry.plattform === "App") return;
    return (
      <Card
        key={entry.id}
        className={classes.entryCard}
        onClick={() => history.push(`/faq/entry/${entry.slug}`)}
      >
        <CardContent>
          <Typography variant="body1" className={classes.strong}>
            <EntryIcon
              fontSize="small"
              color="action"
              className={classes.entryIcon}
            />
            {entry.titel}
          </Typography>
          <Typography variant="caption">{entry.zusammenfassung}</Typography>
        </CardContent>
      </Card>
    );
  };

  if (
    (!!slug && !!categoryData && !categoryError && !category) ||
    !!categoryError
  ) {
    return <Redirect to="/faq" />;
  }

  return (
    <>
      <Container className={classes.screen}>
        <CssBaseline />
        <AppBar open={menuOpen} onOpen={() => setMenuOpen(true)} />
        <ActionBar title={category?.name || t("faq.title")} open={menuOpen} />
        <Menu
          items={props.menuItems}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          selected="faq"
        />
        <main className={classes.main}>
          {!!slug && !categoryData && !categoryError && (
            <div className={classes.loadingWrapper}>
              <CircularProgress />
            </div>
          )}
          {!slug && <p className={classes.intro}>{t("faq.intro")}</p>}
          {!slug && (
            <TextField
              variant="outlined"
              fullWidth
              className={classes.searchInput}
              placeholder={t("faq.searchPlaceholder")}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              InputProps={{
                endAdornment: !!query && (
                  <IconButton onClick={() => setQuery("")} size="small">
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          )}
          {!!category?.beschreibung && (
            <p className={classes.intro}>{category.beschreibung}</p>
          )}
          {!query && (
            <>
              <div className={classes.categoryWrapper}>
                {categories?.map(renderCategory)}
              </div>
              <div className={classes.entryWrapper}>
                {entries?.map(renderEntry)}
              </div>
            </>
          )}
          {!!searchResults && (
            <>
              <div className={classes.categoryWrapper}>
                {searchResults["application::category.category"]?.map(
                  renderCategory
                )}
              </div>
              <div className={classes.entryWrapper}>
                {searchResults["application::entry.entry"]?.map(renderEntry)}
              </div>
              <p className={classes.intro}>
                <Trans i18nKey="faq.notAnswered">
                  <a href="mailto:messenger@prowin.net"></a>
                </Trans>
              </p>
            </>
          )}
        </main>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    maxWidth: "100%",
  },
  loadingWrapper: {
    marginLeft: 20,
    marginTop: 30,
  },
  main: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    maxWidth: "100%",
    marginTop: 115,
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
    },
  },
  intro: {
    whiteSpace: "pre-wrap",
  },
  categoryCard: {
    cursor: "pointer",
    margin: 5,
    minWidth: 150,
    maxWidth: 250,
  },
  entryCard: {
    cursor: "pointer",
    margin: 5,
    flex: 1,
  },
  entryIcon: {
    marginRight: 5,
    transform: "translateY(3.5px)",
  },
  strong: {
    fontWeight: "500",
  },
  searchInput: {
    maxWidth: 500,
    marginBottom: 20,
  },
  categoryWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  entryWrapper: {
    width: "100%",
    maxWidth: 700,
    display: "flex",
    flexDirection: "column",
  },
}));

export default FAQ;
