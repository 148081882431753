import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import de from "./de.json";
import en from "./en.json";
import nl from "./nl.json";
import fr from "./fr.json";
import it from "./it.json";
import moment from "moment";

const resources = {
  de,
  en,
  nl,
  fr,
  it,
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ["de", "en", "nl", "fr", "it"],
    nonExplicitSupportedLngs: true,
    fallbackLng: "de",

    keySeparator: ".",

    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
  });

export default i18n;
