import React from "react";
import {
  Container,
  CssBaseline,
  Typography,
  Button,
  IconButton,
  Snackbar,
} from "@mui/material";
import { AppBar, ActionBar, Menu, DeleteDialog, PartyDialog } from "components";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useActions } from "actions";
import PartyList from "./list";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { io } from "socket.io-client";
import { Link } from "react-router-dom";

const sortABC = (a, b) => {
  if (a.title === undefined) {
    a.title = "";
  }
  return a.title.localeCompare(
    b.title,
    navigator.languages[0] || navigator.language,
    {
      numeric: true,
      ignorePunctuation: true,
    }
  );
};

const sortAscending = (a, b) => {
  if (!a.start && !b.start) return 0;
  if (!a.start) return 1;
  if (!b.start) return -1;

  const day_difference = moment(a.start).diff(b.start, "days");
  if (day_difference < 0) return -1;
  if (day_difference > 0) return 1;
  return 0;
};
const sortDescending = (a, b) => {
  return sortAscending(b, a);
};

const Parties = (props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [sortBy, setSortBy] = React.useState("date");
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const { t } = useTranslation();

  const { GetParties, AddParty, UpdateParty, RemoveParty, GetGuest } =
    useActions();

  const guestId = useSelector((state) => state.session.id);

  React.useEffect(() => {
    GetParties();
    // checkClaimedGuestAccount();
    document.title = `${t("parties.title")} - proWIN Messenger`;
  }, []);

  const checkClaimedGuestAccount = async () => {
    const { response } = await GetGuest(guestId);
    if (!response?.data.accountStatus) {
      setOpenSnackBar(true);
    } else if (response?.data.accountStatus) {
      setOpenSnackBar(false);
    }
  };

  const parties = useSelector((state) => state.parties);
  const upcomingPartiesABC = Object.values(parties)
    .filter((p) => moment().isBefore(p.expiration) || p.expiration === null)
    .sort(sortABC);
  const expiredPartiesABC = Object.values(parties)
    .filter((p) => !moment().isBefore(p.expiration) && p.expiration !== null)
    .sort(sortABC);
  const upcomingParties = Object.values(upcomingPartiesABC)
    .filter((p) => moment().isBefore(p.expiration) || p.expiration === null)
    .sort(sortAscending);
  const expiredParties = Object.values(expiredPartiesABC)
    .filter((p) => !moment().isBefore(p.expiration) && p.expiration !== null)
    .sort(sortDescending);

  const profileState = useSelector((state) => state.profile);

  const [profiles, setProfiles] = React.useState([]);
  React.useEffect(() => {
    setProfiles(profileState.profiles || []);
  }, [profileState]);

  const partyDialog = React.useRef();
  const [editItem, setEditItem] = React.useState();
  const handleAdd = () => {
    setEditItem();
    partyDialog.current.open();
  };
  const handleEdit = (e, p) => {
    e.stopPropagation();
    setEditItem(p);
    partyDialog.current.open(
      p.title,
      p.expiration,
      p.start,
      p.host,
      p.manager,
      p.password,
      p.restrictedMessaging
    );
  };
  const submitPartyDialog = async (
    title,
    start,
    expiration,
    host,
    manager,
    password,
    restrictedMessaging
  ) => {
    if (editItem) {
      await UpdateParty(
        editItem.id,
        title,
        start,
        expiration,
        host,
        manager,
        password,
        restrictedMessaging
      );

      const partyId = editItem.shortId;
      let socket = io(process.env.REACT_APP_API_URL, {
        transports: ["websocket"],
        reconnection: true, // whether to reconnect automatically
        reconnectionAttempts: Infinity, // number of reconnection attempts before giving up
        reconnectionDelay: 1000, // how long to initially wait before attempting a new reconnection
        reconnectionDelayMax: 5000, // maximum amount of time to wait between reconnection attempts. Each attempt increases the reconnection delay by 2x along with a randomization factor
        randomizationFactor: 0.5,
      });
      socket.emit("updateRestriction", {
        partyId,
        restrictedMessaging,
      });
      socket.off();
    } else {
      await AddParty(
        title,
        start,
        expiration,
        host,
        manager,
        password,
        restrictedMessaging
      );
    }
    await GetParties();
  };
  const deleteDialog = React.useRef();
  const [deleteItem, setDeleteItem] = React.useState();
  const handleRemove = (e, p) => {
    e.stopPropagation();
    setDeleteItem(p);
    deleteDialog.current.open();
  };
  const submitRemove = async () => {
    await RemoveParty(deleteItem.id);
    let socket = io(process.env.REACT_APP_API_URL, {
      transports: ["websocket"],
      reconnection: true, // whether to reconnect automatically
      reconnectionAttempts: Infinity, // number of reconnection attempts before giving up
      reconnectionDelay: 1000, // how long to initially wait before attempting a new reconnection
      reconnectionDelayMax: 5000, // maximum amount of time to wait between reconnection attempts. Each attempt increases the reconnection delay by 2x along with a randomization factor
      randomizationFactor: 0.5,
    });
    socket.emit("deleteParty", {
      partyId: deleteItem.shortId,
    });
    socket.off();
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const accountClaimAction = (
    <React.Fragment>
      <Link style={{ textDecoration: "none" }} to="/claim">
        <Button
          style={{
            backgroundColor: "#1E92CD",
            color: "#F3F9FC",
            padding: "3px 20px 0 20px",
            fontSize: 13,
            marginBottom: 10,
            fontFamily: "Roboto",
          }}
          color="secondary"
          size="small"
        >
          {t("login.claimBtn")}
        </Button>
      </Link>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackBar}
      >
        <CloseIcon fontSize="small" style={{ margin: "10px 10px 20px 10px" }} />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Container className={classes.screen}>
        <CssBaseline />
        <AppBar open={menuOpen} onOpen={() => setMenuOpen(true)} />
        <ActionBar
          title={t("parties.title")}
          open={menuOpen}
          tabs={[
            {
              label: t("parties.active"),
            },
            {
              label: t("parties.expired"),
            },
          ]}
          activeTab={activeTab}
          onTabChange={(e, val) => setActiveTab(val)}
          actions={[
            {
              id: "add",
              label: t("parties.newParty"),
              icon: <AddIcon />,
              action: handleAdd,
            },
          ]}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        <Menu
          items={props.menuItems}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          selected="parties"
        />
        <main className={classes.main}>
          <PartyList
            active={activeTab === 0}
            parties={sortBy === "abc" ? upcomingPartiesABC : upcomingParties}
            profiles={profiles}
            onEdit={handleEdit}
            onRemove={handleRemove}
          />
          <PartyList
            active={activeTab === 1}
            parties={sortBy === "abc" ? expiredPartiesABC : expiredParties}
            profiles={profiles}
            onEdit={handleEdit}
            onRemove={handleRemove}
          />
        </main>
        <PartyDialog ref={partyDialog} onSubmit={submitPartyDialog} />
        <DeleteDialog ref={deleteDialog} type="party" onSubmit={submitRemove} />
        <Snackbar
          className={classes.snackbarClaim}
          style={{
            fontFamily: "Roboto",
            opacity: "0.9",
          }}
          open={openSnackBar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          message={t("login.claimAccount")}
          action={accountClaimAction}
        />
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    maxWidth: "100%",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    maxWidth: "100%",
    marginTop: 180,
    [theme.breakpoints.down("sm")]: {
      marginTop: 150,
      paddingRight: 20,
    },
  },

  snackbarClaim: {
    maxWidth: 400,
    borderRadius: 50,
    fontSize: 20,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default Parties;
