import React from "react";
import { useRef, useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  Container,
  CssBaseline,
  Typography,
  Grid,
  Button,
  Paper,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import AnimatedNumber from "animated-number-react";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import applede from "assets/appstore_de.svg";
import appleen from "assets/appstore_en.svg";
import applenl from "assets/appstore_nl.svg";
import applefr from "assets/appstore_fr.svg";
import googlede from "assets/google_de.png";
import googleen from "assets/google_en.png";
import googlenl from "assets/google_nl.png";
import googlefr from "assets/google_fr.png";
import huaweien from "assets/huawei_en.png";
import huaweifr from "assets/huawei_fr.png";
import guestVideo from "assets/guestVideo.mp4";
import phone from "assets/phone.png";
import LandingPageHeader from "./header";
import LandingPageFooter from "./footer";
import media from "assets/media_library2.png";
import { useActions } from "actions";

const LandingPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { SetCallbackLocation } = useActions();
  const ref = useRef();

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  const appStore = {
    de: applede,
    en: appleen,
    fr: applefr,
    nl: applenl,
  };
  const playStore = {
    de: googlede,
    en: googleen,
    fr: googlefr,
    nl: googlenl,
  };
  const appGallery = {
    de: huaweien,
    en: huaweien,
    fr: huaweifr,
    nl: huaweien,
  };

  React.useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);

  const [numbersVisible, setNumbersVisible] = useState(false);
  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      setNumbersVisible(true);
    }
  });

  let intendedLang = props.lang;
  const userLang = navigator.language.substring(0, 2);
  if (!intendedLang) intendedLang = userLang;
  if (intendedLang !== i18n.language.substring(0, 2)) {
    i18n.changeLanguage(intendedLang);
  }

  const lang = i18n.language.substring(0, 2).toLowerCase();

  const formatThousands = (value) => {
    return Math.ceil(value / 1000) + "K";
  };
  const formatMillions = (value) => {
    return Math.ceil(value / 1000000) + "M";
  };

  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));

  const handleSubmit = () => {
    SetCallbackLocation(location.pathname);
    const params = {
      client_id:
        "f1eb7a3f-0cce-4e7b-b971-c86986eef92b_890ygdla7xs8sc84g0s4g8gsg8gwsck4ocw0s8ko0g0sow8o",
      redirect_uri: process.env.REACT_APP_REDIRECT_URL,
      response_type: "code",
      scope: "openid",
    };
    const paramsString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    window.location.href = `https://accounts.prowin.net/oauth2/authorize?${paramsString}`;
  };

  return (
    <div>
      <Container component="main" maxWidth="lg">
        <CssBaseline />

        <Container className={classes.container}>
          <LandingPageHeader handleSubmit={handleSubmit} lang={lang} />

          <Grid container className={classes.section}>
            <Grid item md={5} xs={12}>
              <Typography variant="h4">
                {t("landingpage.generalTitle")}
              </Typography>
              <Typography className={classes.sectionText}>
                {t("landingpage.generalContent")}
              </Typography>

              <ActionButton
                onClick={handleSubmit}
                text={t("landingpage.startNow")}
              />
            </Grid>
            {!sm && !xs && (
              <Grid item md={5} xs={12}>
                <Paper elevation={3}>
                  <img src={media} style={{ width: "100%" }} alt="Media" />
                </Paper>
              </Grid>
            )}
          </Grid>

          <Grid container className={classes.section}>
            <Grid item md={3} xs={6}>
              <img src={phone} style={{ width: "80%" }} alt="App" />
            </Grid>
            <Grid item md={5} xs={12}>
              <Typography variant="h4">{t("landingpage.appTitle")}</Typography>
              <Typography className={classes.sectionText}>
                {t("landingpage.appContent")}
              </Typography>

              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <a href="https://apps.apple.com/bs/app/prowin-messenger/id1553868389">
                    <img
                      src={appStore[lang]}
                      alt={t("landingpage.appStore")}
                      style={{ width: "80%" }}
                    />
                  </a>
                </Grid>
                <Grid item xs={4}>
                  <a href="https://play.google.com/store/apps/details?id=net.prowin.messenger">
                    <img
                      src={playStore[lang]}
                      alt={t("landingpage.playStore")}
                      style={{ margin: "-6%", width: "100%" }}
                    />
                  </a>
                </Grid>
                <Grid item xs={4}>
                  <a href="https://appgallery.huawei.com/#/app/C104422751">
                    <img
                      src={appGallery[lang]}
                      alt={t("landingpage.playStore")}
                      style={{ margin: "0%", width: "100%" }}
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.section}>
            <Grid item md={5} xs={12}>
              <Typography variant="h4">
                {t("landingpage.joinPartyTitle")}
              </Typography>
              <Typography className={classes.sectionText}>
                {t("landingpage.joinPartyContent")}
              </Typography>
              <a
                href="https://prowin.net/de/vertriebspartner-finden"
                className={classes.link}
              >
                <ActionButton text={t("landingpage.notInvitedYet")} />
              </a>
            </Grid>

            <Grid item md={3} xs={12} style={{ marginTop: xs && "50px" }}>
              <video
                src={guestVideo}
                style={{ width: "100%" }}
                alt="Chat"
                poster={phone}
                controls
              />
            </Grid>
          </Grid>
        </Container>
      </Container>

      <div className={classes.gradientBackground}>
        <Container maxWidth="lg">
          <Grid container className={classes.numberSection} ref={ref}>
            <Grid item md={2} xs={12}>
              <Typography variant="h2" gutterBottom align="center">
                <AnimatedNumber
                  value={numbersVisible ? 10000 : 0}
                  formatValue={formatThousands}
                  duration={1500}
                />
              </Typography>
              <Typography variant="h6" gutterBottom align="center">
                {t("landingpage.noPeopleUsingMessenger")}
              </Typography>
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography variant="h2" gutterBottom align="center">
                <AnimatedNumber
                  value={numbersVisible ? 30000 : 0}
                  formatValue={formatThousands}
                  duration={1500}
                />
              </Typography>
              <Typography variant="h6" gutterBottom align="center">
                {t("landingpage.noChatPartiesDone")}
              </Typography>
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography variant="h2" gutterBottom align="center">
                <AnimatedNumber
                  value={numbersVisible ? 4000000 : 0}
                  formatValue={formatMillions}
                  duration={1500}
                />
              </Typography>
              <Typography variant="h6" gutterBottom align="center">
                {t("landingpage.noMessagesSent")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <LandingPageFooter />
    </div>
  );
};

const ActionButton = (props) => {
  return (
    <Button
      href="/login"
      variant="contained"
      color="primary"
      style={{ marginTop: 10 }}
    >
      {props.text}
      <TrendingFlatIcon />
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
  },
  gradientBackground: {
    background: theme.palette.primary.main,
    width: "100%",
    overflow: "auto",
    marginTop: 200,
    color: "white",
  },
  container: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  section: {
    justifyContent: "space-evenly",
    alignItems: "center",
    marginTop: 200,
  },
  numberSection: {
    justifyContent: "space-evenly",
    alignItems: "center",
    marginTop: 50,
    marginBottom: 50,
  },
  sectionText: {
    variant: "body1",
    marginTop: 5,
  },
}));

export default LandingPage;
