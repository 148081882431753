import React from "react";
import { Button, InputBase, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useActions } from "actions";
import { Redirect, Link } from "react-router-dom";
import { routes } from "./../../router/allRoutes";

const CodeVerification = (props) => {
  const { guestEmail, verificationCallback, from } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = React.useState("");
  const [redirect, setRedirect] = React.useState(false);
  const { VerifyGuestLogin, VerifyAccountClaim } = useActions();

  const handleSubmitVerification = async (e) => {
    e.preventDefault();
    if (from === "login") {
      const { response, error } = await VerifyGuestLogin(
        guestEmail,
        verificationCode
      );
      if (error) console.log(error);
      if (!error) {
        setRedirect(true);
      }
    } else if (from === "claim") {
      const { response, error } = await VerifyAccountClaim(verificationCode);
      if (error) console.log(error);
      if (!error) {
        setRedirect(true);
      }
    }
  };

  return (
    <div className={classes.wrapper}>
      {!matches && <div className={classes.img}></div>}
      <div className={classes.loginComponent}>
        <p className={classes.title}>{t("login.insertCode")}</p>

        <div className={classes.inputSendCode}>
          <InputBase
            placeholder="Verification code *"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            style={{
              fontSize: "13px",
              color: "#000",
              width: 350,
            }}
          />
        </div>
        <div className={classes.buttonSendCode}>
          {/* <Link> */}
          <Button
            style={{
              margin: 0,
              padding: 0,
              fontSize: 10,
              textTransform: "none",
              fontFamily: "Roboto",
            }}
            disableRipple
            onClick={verificationCallback}
          >
            {t("login.back")}
          </Button>
          {/* </Link> */}
          <Button
            style={{
              textTransform: "none",
              fontFamily: "Roboto",
              background: "rgba(30, 146, 205, 100)",
              color: "#FFF",
              boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
              padding: "5px 20px",
            }}
            onClick={handleSubmitVerification}
          >
            {t("login.verify")}
          </Button>
          {redirect && <Redirect to={routes[1].path} />}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flex: 1,
    height: "100vh",
    backgroundColor: "#F3F9FC",
    overflow: "hidden",
  },
  loginComponent: {
    flex: 1,
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  img: {
    height: "100%",
    flex: 1,
    backgroundImage: "url(prowinImg.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  inputSendCode: {
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0 0.5px 1px 0 rgba(160,160,160,0.4)",
    height: 30,
    margin: "10px 20px",
    padding: 5,
  },
  buttonSendCode: {
    marginTop: 10,
    width: "100%",
    maxWidth: 350,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  title: {
    color: "#333E48",
    margin: 0,
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: 350,
  },
}));

export default CodeVerification;
