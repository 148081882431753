import React from "react";
import {
  Card,
  CardMedia,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PlayIcon from "@mui/icons-material/PlayCircleFilled";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoIcon from "@mui/icons-material/Photo";
import VideoIcon from "@mui/icons-material/Movie";
import AudioIcon from "@mui/icons-material/Audiotrack";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BorderColor } from "@mui/icons-material";
import { useActions } from "src/redux/actions";

const MediaCard = ({
  title,
  type = "image",
  mediaId,
  userId,
  onView,
  onEdit,
  onDownload,
  onRemove,
  onDrag,
  onDragEnd,
  onMoveTo,
  onConvert,
  onMultiSelect,
  selected,
  conversionStatus,
  isTrash,
  onRestore,
  onCopyTo,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ type });
  const { GetConversionStatus } = useActions();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState();
  const [status, setStatus] = React.useState(conversionStatus);
  const handleMenuOpen = (event, message) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };
  const handleMenuClose = () => {
    setAnchorEl();
    setMenuOpen(false);
  };
  const handleDownload = (e) => {
    setMenuOpen(false);
    onDownload(e);
  };
  const handleEdit = (e) => {
    setMenuOpen(false);
    onEdit(e);
  };
  const handleMoveTo = () => {
    setMenuOpen(false);
    onMoveTo();
  };
  const handleCopyTo = () => {
    setMenuOpen(false);
    onCopyTo();
  };
  const handleDelete = (e) => {
    setMenuOpen(false);
    onRemove(e);
  };
  const handleConvert = (e) => {
    setMenuOpen(false);
    onConvert(e);
  };

  const [dragged, setDragged] = React.useState(false);
  const handleDragStart = (e) => {
    setDragged(true);
    onDrag(e);
  };
  const handleDragEnd = (e) => {
    setDragged(false);
    onDragEnd(e);
  };
  const handleRestore = (e) => {
    setMenuOpen(false);
    onRestore(e);
  };

  const handleClick = (event) => {
    // ctrl key for Windows - command key for Mac
    if (event.ctrlKey || event.metaKey) {
      onMultiSelect();
    } else {
      onView();
    }
  };

  const getConversionStatus = async () => {
    if (status === "PROGRESSING") {
      const { response, error } = await GetConversionStatus(mediaId);
      if (error) console.log(error);
      setStatus(response?.data?.conversionStatus);
      if (response?.data?.conversionStatus === "PROGRESSING")
        setTimeout(getConversionStatus, 1000);
    }
  };

  getConversionStatus();
  return (
    <>
      <Card
        className={clsx(classes.card, {
          [classes.cardActive]: dragged,
          [classes.cardChecked]: selected,
        })}
        draggable={isTrash ? "false" : "true"}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={status === "PROGRESSING" ? null : handleClick}
        id={mediaId}
      >
        <CardMedia
          image={
            status === "PROGRESSING"
              ? null
              : type === "image"
              ? `${process.env.REACT_APP_STATIC_URL}/${userId}/${mediaId}`
              : type === "video"
              ? `${process.env.REACT_APP_STATIC_URL}/${userId}/${mediaId}.0000001.jpg`
              : type === "application"
              ? `${process.env.REACT_APP_STATIC_URL}/${userId}/${mediaId}.jpg`
              : null
          }
          className={classes.cardMedia}
        >
          {type === "video" && status !== "PROGRESSING" && (
            <PlayIcon style={{ fontSize: 60, marginBottom: 80 }} />
          )}
          {type === "audio" && status !== "PROGRESSING" && (
            <GraphicEqIcon style={{ fontSize: 60, marginBottom: 80 }} />
          )}
          {status === "PROGRESSING" && (
            <CircularProgress style={{ fontSize: 60, marginBottom: 80 }} />
          )}
        </CardMedia>
        <div className={classes.cardActions}>
          <Typography className={classes.title}>
            {status !== "PROGRESSING" && type === "image" && (
              <PhotoIcon style={{ marginBottom: -6 }} />
            )}
            {status !== "PROGRESSING" && type === "video" && (
              <VideoIcon style={{ marginBottom: -6 }} />
            )}
            {status !== "PROGRESSING" && type === "audio" && (
              <AudioIcon style={{ marginBottom: -6 }} />
            )}
            {status !== "PROGRESSING" && type === "application" && (
              <PdfIcon style={{ marginBottom: -6 }} />
            )}
            &nbsp;{status === "PROGRESSING" && t("media.converting")}
            {title}
          </Typography>
          {status !== "PROGRESSING" && (
            <IconButton
              className={classes.cardButton}
              onClick={handleMenuOpen}
              size="large"
            >
              <MoreHorizIcon />
            </IconButton>
          )}
        </div>
      </Card>
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        {!isTrash && onDownload && (
          <MenuItem onClick={handleDownload}>
            {t("media.downloadLabel")}
          </MenuItem>
        )}
        {!isTrash && !!onEdit && (
          <MenuItem onClick={handleEdit}>{t("media.editLabel")}</MenuItem>
        )}
        {!isTrash && !!onMoveTo && (
          <MenuItem onClick={handleMoveTo}>{t("media.moveToLabel")}</MenuItem>
        )}
        {!isTrash && !!onCopyTo && (
          <MenuItem onClick={handleCopyTo}>{t("media.copyToLabel")}</MenuItem>
        )}
        {!isTrash && !!onRemove && (
          <MenuItem onClick={handleDelete}>{t("media.deleteLabel")}</MenuItem>
        )}
        {!isTrash && !!onConvert && type === "application" && (
          <MenuItem onClick={handleConvert}>
            {t("media.generateThumbnail")}
          </MenuItem>
        )}
        {isTrash && (
          <MenuItem onClick={handleRestore}>{t("templates.restore")}</MenuItem>
        )}
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    height: 150,
    width: 250,
    cursor: "pointer",
    marginLeft: 10,
    marginTop: 10,
    maxWidth: "calc(100% - 20px)",
  },
  cardChecked: {
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderadius: "0.5px",
  },
  cardActive: {
    backgroundColor: "red",
  },
  cardMedia: {
    height: 200,
    backgroundColor: "rgb(218,218,218)",
    backgroundSize: "cover",
    backgroundPositionY: (props) =>
      props.type === "application" ? "top" : "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
  cardActions: {
    height: 50,
    marginTop: -100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
    paddingLeft: 10,
  },
  title: {
    flex: 1,
    color: "white",
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardButton: {
    marginLeft: 0,
    color: "white",
  },
}));

export default MediaCard;
