import React from "react";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { makeStyles } from "@mui/styles";
import FolderIcon from "@mui/icons-material/Folder";
import { useTranslation } from "react-i18next";
import { useActions } from "actions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputBase,
  alpha,
} from "@mui/material";
import clsx from "clsx";
import _ from "lodash";
import SearchIcon from "@mui/icons-material/Search";

const TemplateImportDialog = (props, ref) => {
  const {
    templates,
    templateId,
    setImportLoading,
    setSending,
    setShowUpload,
    setUploadDescription,
    scrollToBottom,
    beforeMessageId,
  } = props;

  const [path, setPath] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [selectedId, setSelectedId] = React.useState("");
  const [visibleFolders, setVisibleFolders] = React.useState([]);
  const [visibleFiles, setVisibleFiles] = React.useState([]);

  React.useEffect(() => {
    getTemplatesInPath();
  }, [path]);

  const getTemplatesInPath = () => {
    if (!path || path.length === 0) {
      setVisibleFolders(templates.filter((m) => m.isFolder && !m.path));
      setVisibleFiles(
        templates.filter((m) => !m.isFolder && !m.path && m.id !== templateId)
      );
    } else {
      setVisibleFolders(
        templates.filter((m) => m.isFolder && m.path === path.join("/"))
      );
      setVisibleFiles(
        templates.filter(
          (m) => !m.isFolder && m.path === path.join("/") && m.id !== templateId
        )
      );
    }
  };

  const handleSetPath = (folderId) => {
    if (folderId === "") {
      setPath([]);
      return;
    }
    const folder = templates.find((m) => m.id === folderId);
    setPath([...(folder.path ? folder.path.split("/") : []), folder.id]);
  };

  const renderFolder = (folder) => {
    return (
      <div
        className={classes.folder}
        key={folder.id}
        onClick={() => handleSetPath(folder.id)}
      >
        <FolderIcon className={classes.folderIcon} />
        <div className={classes.folderName}>{folder.title}</div>
      </div>
    );
  };

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const classes = useStyles({ windowWidth });
  const { t } = useTranslation();

  const { SendFullTemplateToTemplate, GetTemplate } = useActions();

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    importTemplate();
  };

  const [searchValue, setSearchValue] = React.useState("");
  const applySearch = (item) => {
    const searchVal = searchValue.trim().toLowerCase();
    if (!searchVal) return true;
    const terms = searchVal.split(" ");
    let result = true;
    for (const term of terms) {
      const searchTerm = new RegExp("^(.*)" + term + "(.*)$");
      result = result && searchTerm.test(item.title.toLowerCase());
    }
    return result;
  };

  const importTemplate = async () => {
    setSending(true);
    setImportLoading(true);
    let { response, error } = await SendFullTemplateToTemplate(
      templateId,
      selectedId,
      beforeMessageId
    );
    if (error) {
      setShowUpload(true);
      setUploadDescription(t("import.error"));
      setTimeout(() => {
        setShowUpload(false);
        setUploadDescription("");
      }, 4000);
    }
    await GetTemplate(templateId);
    setImportLoading(false);
    setSending(false);
    scrollToBottom();
  };

  const renderTemplate = (template) => {
    return (
      <div
        className={clsx(classes.media, {
          [classes.selected]: selectedId === template.id,
        })}
        key={template.id}
        onClick={() => setSelectedId(template.id)}
      >
        <div>{template.title}</div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("party.templateImportTitle")}:</DialogTitle>
        <DialogContent>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              classes={{
                root: classes.searchField,
                input: classes.searchInput,
              }}
              inputProps={{ "aria-label": "search" }}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          {!searchValue.trim() && (
            <div className={classes.breadcrumbs}>
              <Button
                size="small"
                classes={{
                  root: classes.pathButton,
                  label: classes.pathButtonLabel,
                }}
                onClick={() => handleSetPath("")}
              >
                {t("templates.homeTitle")}
              </Button>
              {path.length > 0 && (
                <ChevronRight
                  fontSize="small"
                  className={classes.pathSeparator}
                />
              )}
              {path.length > 2 && (
                <>
                  <Button
                    size="small"
                    classes={{
                      root: classes.pathButton,
                      label: classes.pathButtonLabel,
                    }}
                    disabled
                  >
                    ...
                  </Button>
                  <ChevronRight
                    fontSize="small"
                    className={classes.pathSeparator}
                  />
                </>
              )}
              {path.slice(-2).map((p, index) => (
                <React.Fragment key={p}>
                  <Button
                    size="small"
                    classes={{
                      root: classes.pathButton,
                      label: classes.pathButtonLabel,
                    }}
                    onClick={() => handleSetPath(p)}
                  >
                    {templates.find((m) => m.id === p).title}
                  </Button>
                  {index < path.slice(-2).length - 1 && (
                    <ChevronRight
                      fontSize="small"
                      className={classes.pathSeparator}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
          <div className={classes.mediaArea}>
            {!searchValue.trim() &&
              _.sortBy(visibleFolders, ["title"]).map(renderFolder)}
            {!searchValue.trim() &&
              _.sortBy(visibleFiles, ["title"]).map(renderTemplate)}
            {!!searchValue.trim() &&
              _.sortBy(templates.filter(applySearch), ["title"]).map(
                renderTemplate
              )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            {t("general.cancel")}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!selectedId}
          >
            {t("import.menuLabel")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: theme.spacing(1),
    maxHeight: `calc(100% - ${theme.spacing(2)})`,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha("rgb(180, 180, 180)", 0.15),
    "&:hover": {
      backgroundColor: alpha("rgb(180, 180, 180)", 0.25),
    },
    width: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  searchIcon: {
    height: "100%",
    width: theme.spacing(5),
    pointerEvents: "none",
    display: "flex",
    justifyContent: "center",
  },
  searchField: {
    color: "inherit",
  },
  searchInput: {
    padding: theme.spacing(1, 1, 1, 0),
    width: "100%",
  },
  mediaArea: {
    maxHeight: "calc(100vh - 240px)",
    overflowY: "auto",
    width: 400,
    maxWidth: (props) => props.windowWidth - theme.spacing(4),
    marginLeft: -theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
    },
  },
  media: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    width: 400,
    maxWidth: (props) => props.windowWidth - theme.spacing(4),
    backgroundColor: "#f8f8f8",
    borderRadius: 5,
    marginBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  selected: {
    backgroundColor: "#ddd",
    "&:hover": {
      backgroundColor: "#ddd",
    },
  },
  folderIcon: {
    color: "#666",
  },
  thumbnail: {
    width: 60,
    height: 60,
    backgroundSize: "cover",
    borderRadius: 3,
    marginRight: 5,
  },
  folderName: {
    flex: 1,
    fontSize: 17,
    textJustify: "baseline",
    paddingLeft: 5,
    marginBottom: -3,
    color: "#444",
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  breadcrumbs: {
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderColor: "rgba(160, 160, 160, 0.6)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  folder: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    width: 400,
    maxWidth: (props) => props.windowWidth - theme.spacing(4),
    backgroundColor: "#f8f8f8",
    borderRadius: 5,
    marginBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  pathButtonLabel: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  pathSeparator: {
    color: "#999",
    marginTop: 5,
    marginLeft: -5,
    marginRight: -5,
  },
  pathButton: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "0.9rem",
    color: "#333",
    paddingBottom: 3,
    paddingLeft: 8,
    paddingRight: 8,
    minWidth: 30,
    "& span": {
      pointerEvents: "none",
    },
    justifyContent: "flex-start",
  },
}));

export default React.forwardRef(TemplateImportDialog);
