import { TIMESTAMP_CHAT, LOGOUT } from "../types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case TIMESTAMP_CHAT: {
      const { partyId, timestamp } = action.payload;
      const changeState = { ...state };
      changeState[partyId] = timestamp;
      return changeState;
    }

    case LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
