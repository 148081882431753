import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  IconButton,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const TemplateTable = ({
  profiles = [],
  selectedProfile,
  searchValue = "",
  onEdit = () => {},
  onDelete = () => {},
  onSelect = () => {},
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const headCells = [
    { id: "firstName", label: t("profiles.firstName") },
    { id: "lastName", label: t("profiles.lastName") },
    { id: "actions", label: t("profiles.actions"), sortDisabled: true },
  ];
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("firstName");

  const createSortHandler = (headCellId) => () => {
    if (orderBy === headCellId) {
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setOrderBy(headCellId);
      setOrder("asc");
    }
  };

  const applySearch = (row) => {
    const searchString = `${row.firstName}${row.firstName}`
      .toLowerCase()
      .replace(/\s+/g, "");
    const searchVal = searchValue.trim().toLowerCase();
    if (!searchVal) return true;
    const terms = searchVal.split(" ");
    let result = true;
    for (const term of terms) {
      const searchTerm = new RegExp("^(.*)" + term + "(.*)$");
      result = result && searchTerm.test(searchString);
    }
    return result;
  };
  const handleClick = (profileId) => {
    onEdit(profileId);
  };
  const handleEdit = (e, profileId) => {
    e.stopPropagation();
    onEdit(profileId);
  };
  const handleDelete = (e, profileId) => {
    e.stopPropagation();
    onDelete(profileId);
  };
  const handleSelect = (e, profileId) => {
    e.stopPropagation();
    onSelect(profileId);
  };

  const renderRow = (row) => (
    <TableRow
      key={row.id}
      onClick={() => handleClick(row.id)}
      style={{ cursor: "pointer" }}
    >
      <TableCell className={row.id === selectedProfile && classes.bold}>
        {row.firstName}
      </TableCell>
      <TableCell className={row.id === selectedProfile && classes.bold}>
        {row.lastName}
      </TableCell>
      <TableCell>
        <IconButton onClick={(e) => handleEdit(e, row.id)} size="large">
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={(e) => handleDelete(e, row.id)}
          disabled={row.id === selectedProfile}
          size="large">
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={(e) => handleSelect(e, row.id)}
          disabled={row.id === selectedProfile}
          size="large">
          <CheckIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.sortDisabled ? (
                  headCell.label
                ) : (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(profiles, getSorting(order, orderBy))
            .filter(applySearch)
            .map((row) => renderRow(row))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  bold: {
    fontWeight: "bold",
  },
}));

export default TemplateTable;
