import { DISMISS_BANNER } from "../types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case DISMISS_BANNER: {
      console.log("DISMISS");
      return {
        bannerDismissed: true,
      };
    }

    default:
      return state;
  }
};
