import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Typography, Grid, Container, Divider } from "@mui/material";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

const LandingPageFooter = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.footer}>
      <div style={{ minHeight: 60 }}></div>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-evenly">
          <Grid item md={4} xs={5}>
            <Typography variant="body1">
              &copy;
              <Trans i18nKey="login.copyright">
                <a
                  href="https://www.prowin.net"
                  target="_blank"
                  className={classes.footerLink}
                ></a>
              </Trans>
            </Typography>
          </Grid>

          <Grid item md={4} xs={5}>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Typography variant="body1" align="right">
                  <Link
                    to="/impress"
                    target="_blank"
                    className={classes.footerLink}
                  >
                    <Typography variant="body1">
                      {t("login.impress")}
                    </Typography>
                  </Link>
                </Typography>
              </Grid>

              <Grid item>
                <Divider orientation="vertical" />
              </Grid>

              <Grid item>
                <Typography variant="body1" align="right">
                  <Link
                    to="/privacy"
                    target="_blank"
                    className={classes.footerLink}
                  >
                    <Typography variant="body1">
                      {t("login.datasec")}
                    </Typography>
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div style={{ minHeight: 10 }}></div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  footerGrid: {
    textAlign: "left",
    marginLeft: "10%",
  },
  footerLink: {
    textDecoration: "none",
    color: "black",
  },
  copyright: {
    marginTop: 20,
    fontSize: "1em",
    fontWeight: "600",
    textAlign: "center",
  },
  impress: {
    fontSize: "0.9em",
    textAlign: "center",
  },
  footer: {},
}));

export default LandingPageFooter;
