import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import moment from "moment";

const PartyCard = ({
  title,
  host,
  partyDate,
  expiration,
  manager,
  unseenMessage,
  onView,
  onEdit,
  onRemove,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card className={classes.card} onClick={onView}>
      {unseenMessage && (
        <FiberManualRecordIcon className={classes.unseenMessage} />
      )}
      <CardContent className={classes.cardContent}>
        <Typography variant="h5" component="h2" className={classes.title}>
          {title}
        </Typography>
        {!!host && (
          <Typography variant="h5" component="h2" className={classes.title}>
            {host}
          </Typography>
        )}
        {!!partyDate && (
          <Typography variant="p" className={classes.p}>
            {t("parties.partyDate", { date: moment(partyDate).toDate() })}
          </Typography>
        )}
        {!!expiration && (
          <Typography variant="p" className={classes.p}>
            {moment().isBefore(expiration)
              ? t("parties.expiringAt", { date: moment(expiration).toDate() })
              : t("parties.expiredAt", { date: moment(expiration).toDate() })}
          </Typography>
        )}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Typography className={classes.manager}>
          {manager ? `${manager.firstName} ${manager.lastName}` : ""}
        </Typography>
        <IconButton className={classes.cardButton} onClick={onEdit} size="large">
          <EditIcon />
        </IconButton>
        <IconButton className={classes.cardButton} onClick={onRemove} size="large">
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  unseenMessage: {
    position: "absolute",
    marginLeft: 275,
    marginTop: 110,
    color: theme.palette.primary.main,
  },
  card: {
    height: 200,
    width: 300,
    cursor: "pointer",
    margin: 10,
  },
  cardContent: {
    height: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "rgba(51,51,51)",
    paddingTop: 25,
  },
  cardActions: {
    transform: "translateY(-100%)",
    backgroundColor: "rgba(0,0,0,0.3)",
    display: "flex",
    paddingLeft: 20,
  },
  manager: {
    flex: 1,
    color: "white",
    whiteSpace: "pre",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardButton: {
    color: "white",
  },
  title: {
    color: "rgb(51,51,51)",
  },
  p: {
    color: "rgb(116,116,116)",
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
}));

export default PartyCard;
