import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Ellipsis from "react-lines-ellipsis";

const SelectProfileDialog = (props, ref) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const classes = useStyles({ windowWidth });

  const { onSubmit, profiles = [] } = props;
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();

  const [selected, select] = React.useState();

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    onSubmit(selected);
  };

  const renderProfile = (profile) => {
    return (
      <div
        className={clsx(classes.profile, {
          [classes.selected]: profile.id === selected,
        })}
        key={profile.id}
        onClick={() => select(profile.id)}
      >
        <div className={classes.profileName}>
          <Ellipsis
            lines={1}
            text={`${profile.firstName} ${profile.lastName}`}
            basedOn="letters"
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t("profiles.selectProfileDialog")}</DialogTitle>
        <DialogContent>
          <div className={classes.list}>{profiles.map(renderProfile)}</div>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!selected}
          >
            {t("general.select")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: theme.spacing(1),
    maxHeight: `calc(100% - ${theme.spacing(2)})`,
  },
  list: {
    maxHeight: "calc(100vh - 205px)",
    overflowY: "auto",
    width: 400,
    maxWidth: (props) => props.windowWidth - theme.spacing(4),
    marginLeft: -theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
    },
  },
  profile: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    width: 400,
    maxWidth: (props) => props.windowWidth - theme.spacing(4),
    backgroundColor: "#f8f8f8",
    borderRadius: 5,
    marginBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
  },
  selected: {
    backgroundColor: "#ddd",
    "&:hover": {
      backgroundColor: "#ddd",
    },
  },
  profileName: {
    flex: 1,
  },
}));

export default React.forwardRef(SelectProfileDialog);
