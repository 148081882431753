import React from "react";
import { Container, CssBaseline, CircularProgress } from "@mui/material";
import { AppBar, ActionBar, Menu } from "components";
import { makeStyles } from "@mui/styles";
import { useTranslation, Trans } from "react-i18next";
import useSWR from "swr";
import { Redirect, useParams } from "react-router-dom";

const FAQEntry = (props) => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const { t, i18n } = useTranslation();

  const { slug } = useParams();

  const { data: entryData, error: entryError } = useSWR(
    `/entries?slug=${slug}&_locale=${i18n.language.substr(0, 2)}`
  );
  const [entry] = entryData || [];
  React.useEffect(() => {
    document.title = `${entry?.titel || ""} - proWIN Messenger`;
  }, [entry]);

  if ((!!entryData && !entryError && !entry) || !!entryError) {
    return <Redirect to="/faq" />;
  }

  return (
    <>
      <Container className={classes.screen}>
        <CssBaseline />
        <AppBar open={menuOpen} onOpen={() => setMenuOpen(true)} />
        <ActionBar title={entry?.titel || ""} open={menuOpen} />
        <Menu
          items={props.menuItems}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          selected="faq"
        />
        <main className={classes.main}>
          {!entryData && !entryError && (
            <div className={classes.loadingWrapper}>
              <CircularProgress />
            </div>
          )}
          {!!entry?.zusammenfassung && (
            <p className={classes.intro}>{entry.zusammenfassung}</p>
          )}
          {!!entry?.abschnitte && (
            <ul className={classes.tableOfContents}>
              <span className={classes.strong}>{t("faq.toc")}:</span>
              {entry.abschnitte.map((abschnitt) => (
                <li>
                  <a href={`#${abschnitt.id}`}>{abschnitt.titel}</a>
                </li>
              ))}
            </ul>
          )}
          {!!entry?.abschnitte &&
            entry.abschnitte.map((abschnitt) => (
              <React.Fragment key={abschnitt.id}>
                <h2 className={classes.heading} id={abschnitt.id}>
                  {abschnitt.titel}
                </h2>
                {!!abschnitt.inhalt && (
                  <div
                    dangerouslySetInnerHTML={{ __html: abschnitt.inhalt }}
                    className={classes.content}
                  />
                )}
                {!!abschnitt.video && (
                  <video className={classes.video} controls>
                    <source
                      src={abschnitt.video.url}
                      type={abschnitt.video.mime}
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
              </React.Fragment>
            ))}
          <p className={classes.intro}>
            <Trans i18nKey="faq.notAnswered">
              <a href="mailto:messenger@prowin.net"></a>
            </Trans>
          </p>
        </main>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  screen: {
    display: "flex",
    maxWidth: "100%",
    backgroundColor: "white",
  },
  loadingWrapper: {
    marginLeft: 20,
    marginTop: 30,
  },
  main: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    maxWidth: "100%",
    marginTop: 115,
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
    },
  },
  intro: {
    whiteSpace: "pre-wrap",
  },
  tableOfContents: {
    backgroundColor: "rgb(240,240,240)",
    padding: 15,
    paddingRight: 25,
    listStyleType: "none",
    "& li": {
      padding: 3,
      "& a": {
        textDecoration: "none",
        color: "rgb(80,80,80)",
      },
    },
  },
  heading: {
    marginBottom: 0,
  },
  content: {
    maxWidth: "100%",
    "& img": {
      maxHeight: 500,
      maxWidth: "100%",
    },
    "&& img": {
      maxHeight: 500,
      maxWidth: "100%",
    },
  },
  video: {
    width: "100%",
    maxWidth: 700,
    height: "auto",
  },
  strong: {
    fontWeight: "500",
  },
}));

export default FAQEntry;
